import React from 'react';

// components
import CustomSelect from '../../../components/elements/forms/CustomSelect';

// constants
import { AiFillEdit } from 'react-icons/ai';

// @types
import { IDataType, ISavingSingleEmail } from '../../../store/types/misc';

type IUpdateSelect = {
  items: IDataType[];
  email: string;
  title: string;
  name: { name: string; id: string } | null;
  placeholder: string;
  onUpdate: (data: ISavingSingleEmail) => void;
};

const UpdateSelect: React.FC<IUpdateSelect> = ({
  title,
  email,
  name,
  items,
  placeholder,
  onUpdate
}) => {
  const [data, dataSet] = React.useState<ISavingSingleEmail>({
    name: '',
    client_slug: ''
  });
  const [show, showSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    if (email) {
      const country = items.find((item) => item.id === name?.id);
      dataSet((prev) => ({ ...prev, email, name: country?.id || '' }));
    }
  }, [email, items, name]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
      <label className="text-[#A4A4A4]">{title}</label>
      <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
        {show ? (
          <CustomSelect
            items={items}
            placeholder={placeholder}
            selected={data.name}
            status=""
            name="name"
            onSelect={(_name, value) => {
              onUpdate({ ...data, name: value.toString() });
              showSet(false);
            }}
          />
        ) : (
          <div className="flex items-center justify-between w-full px-4">
            <p className="text-[#707070]">{name?.name || ''}</p>
            <AiFillEdit onClick={() => showSet(true)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateSelect;
