import React from 'react';

// components
import LineGraph from '@/components/ui/graph/LineGraph';

// hooks
import { useAppSelector } from '@/store/hooks';

const CandidateStatistic = () => {
  const statistics = useAppSelector(({ candidate }) => candidate.statistics);
  return (
    <div className="w-[49%] h-[40vh]">
      <LineGraph data={[{ id: 'Candidate', data: statistics }]} />
    </div>
  );
};

export default CandidateStatistic;
