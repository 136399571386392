import React from 'react';

// location router
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

// constants
import crest from '../../assets/images/badge/crest.png';

// components
import { Layout, Menu } from 'antd';
import { ItemType } from './items';
import { items } from './items';

const { Sider } = Layout;

const SideBar: React.FunctionComponent = () => {
	const { pathname } = useLocation();
	return (
		<Sider
			breakpoint="lg"
			collapsedWidth="0"
			onBreakpoint={(_broken) => null}
			onCollapse={(_collapsed, _type) => null}
			// style={{height: "100vh"}}
			className="site-layout-background font-abel"
		>
			<div className=" w-full flex items-center justify-center pt-[20px] bg-[#243677]">
				<img src={crest} alt="25th and Staffing Crest" className="w-[113px]" />
			</div>
			<div className="text-center pb-6 bg-[#243677]">
				<p className="text-white text-[35px]">AMS</p>
			</div>
			<Menu
				mode="inline"
				defaultOpenKeys={[pathname]}
				defaultSelectedKeys={[pathname]}
				style={{
					height: '100%',
					borderRight: 2,
					backgroundColor: '#243677',
					color: 'white'
				}}
			>
				{items.map(
					(item: ItemType): React.ReactNode => (
						<Menu.Item
							icon={item.icon}
							key={item.key}
							className="my-5 font-abel"
						>
							<Link to={item.link}>{item.label}</Link>
						</Menu.Item>
					)
				)}
			</Menu>
		</Sider>
	);
};

export default SideBar;

// xms.25thandstaffing.com
