import React from 'react';

// Login
import { ILogin, ILognError } from '../../../store/types/auth';

// state
import { authUser } from '../../../service/api/auth';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';

const useLogin = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [data, dataSet] = React.useState<ILogin>({
		email: '',
		password: ''
	});
	const [error, errorSet] = React.useState<ILognError>({
		email: '',
		password: ''
	});

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		errorSet((prev) => ({ ...prev, [name]: '' }));
		dataSet((prev) => ({ ...prev, [name]: value }));
	};

	// const onValidate = () => boolean;
	const onValidate = () => {
		let err: boolean = false;
		if (!data.email) {
			err = true;
			errorSet((prev) => ({ ...prev, email: 'warning' }));
		}
		if (!data.password) {
			err = true;
			errorSet((prev) => ({ ...prev, password: 'warning' }));
		}
		if (err) {
			return false;
		}
		return true;
	};

	const onSubmitHandler = async () => {
		if (onValidate()) {
			const response = await authUser(data, dispatch);
			if (response) {
				navigate('/');
			}
		}
		return;
	};

	return { data, error, onChangeHandler, onSubmitHandler };
};

export default useLogin;
