import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setExperiences,
  createExperience,
  updateExperience,
  setLoading,
  setUpdate
} from '@/store/slices/experience';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_experiences = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-experiences');

    dispatch(setExperiences(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setExperiences([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_experience = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-experience', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createExperience(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_experience = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-experience', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateExperience(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
