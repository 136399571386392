import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  soft_skills: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  soft_skills: [],
  update: false,
  loading: false
};

export const soft_skill = createSlice({
  name: 'soft_skill',
  initialState,
  reducers: {
    setSoftSkill: (state, action: PayloadAction<IDataType[]>) => {
      state.soft_skills = action.payload;
    },
    createSoftSkill: (state, action: PayloadAction<IDataType>) => {
      state.soft_skills = [action.payload, ...state.soft_skills];
    },
    updateSoftSkill: (state, action: PayloadAction<IDataType>) => {
      state.soft_skills = state.soft_skills.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setSoftSkill,
  createSoftSkill,
  updateSoftSkill,
  setLoading,
  setUpdate
} = soft_skill.actions;

export default soft_skill.reducer;
