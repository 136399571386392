import React from 'react';

// types and state
import {
  create_blue_roles,
  get_blue_roles,
  update_blue_roles
} from '@/service/api/blue_role';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { ErrorWarning } from '@/store/types/misc';

// components
import Items from '../misc/Items';
import Create from '../misc/Create';
import HeaderTitle from '../misc/HeaderTitle';
import Loading from '../misc/Loading';

type IDataSet = {
  name: string;
  id: string;
};
type IErrorDataSet = {
  name: ErrorWarning;
  id: string;
};

const BlueRole = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(({ blue_role }) => blue_role.roles);
  const loading = useAppSelector(({ blue_role }) => blue_role.loading);
  const update = useAppSelector(({ blue_role }) => blue_role.update);
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IDataSet>({
    id: '',
    name: ''
  });
  const [error, errorSet] = React.useState<IErrorDataSet>({
    id: '',
    name: ''
  });

  const onLoad = React.useCallback(() => {
    get_blue_roles(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <section className="w-[32%] h-[50vh] my-4  bg-[white] shadow-sm mx-[4px] border px-3 py-3 rounded-lg overflow-hidden overflow-y-auto">
      <HeaderTitle
        title="BlueRole [R]"
        onClick={() => showSet(true)}
        loading={update}
      />
      <ul>
        {loading ? (
          <Loading />
        ) : (
          roles.map((item) => (
            <Items
              key={item.id}
              item={item}
              onEdit={(res) => {
                dataSet({ id: res.id?.toString(), name: res.name });
                showSet(true);
              }}
            />
          ))
        )}
      </ul>
      <Create
        name="name"
        value={data.name}
        err={error.name}
        onChange={(e) => {
          const { name, value } = e.target;
          dataSet((prev) => ({ ...prev, [name]: value }));
          errorSet((prev) => ({ ...prev, [name]: '' }));
        }}
        placeholder="Blue Role"
        visible={show}
        handleCancel={() => {
          showSet(false);
          dataSet({ id: '', name: '' });
        }}
        handleOk={() => {
          if (data.id && data.name) {
            update_blue_roles(dispatch, data);
          } else if (data.name && !data.id) {
            create_blue_roles(dispatch, data);
          }
          showSet(false);
          dataSet({ id: '', name: '' });
        }}
      />
    </section>
  );
};

export default BlueRole;
