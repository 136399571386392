import React from 'react';
import {
  IEditOrderTitle,
  IEditOrderTitleError
} from '../../../store/types/order';
import { useAppSelector } from '../../../store/hooks';

const useEditOrderData = () => {
  const order = useAppSelector(({ order }) => order.order);
  const [data, dataSet] = React.useState<IEditOrderTitle>({
    order_type_id: '',
    position: '',
    expertise_level_id: '',
    job_type_id: '',
    state_id: '',
    country_id: '',
    slug: ''
  });
  const [err, errSet] = React.useState<IEditOrderTitleError>({
    order_type_id: '',
    position: '',
    expertise_level_id: '',
    job_type_id: '',
    state_id: '',
    country_id: ''
  });
  const [fill, fillSet] = React.useState<IEditOrderTitle>({
    order_type_id: '',
    position: '',
    expertise_level_id: '',
    job_type_id: '',
    state_id: '',
    country_id: ''
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev: any) => ({ ...prev, [name]: value }));
  };

  type IOnsubmitEvent = () => boolean;
  const onSubmitEvent: IOnsubmitEvent = () => {
    let validate: boolean = false;
    if (!data.position) {
      validate = true;
      errSet((prev: any) => ({ ...prev, position: 'warning' }));
    }
    if (!data.order_type_id) {
      validate = true;
      errSet((prev: any) => ({ ...prev, order_type: 'warning' }));
    }
    if (!data.job_type_id) {
      validate = true;
      errSet((prev: any) => ({ ...prev, job_type_id: 'warning' }));
    }
    if (!data.expertise_level_id) {
      validate = true;
      errSet((prev: any) => ({ ...prev, expertise_level_id: 'warning' }));
    }
    if (!data.country_id) {
      validate = true;
      errSet((prev: any) => ({ ...prev, country_id: 'warning' }));
    }
    if (!data.state_id) {
      validate = true;
      errSet((prev: any) => ({ ...prev, state_id: 'warning' }));
    }
    if (validate) {
      return false;
    }
    return true;
  };

  const onLoad = React.useCallback(() => {
    dataSet((prev) => ({
      ...prev,
      order_type_id: order?.order_type_id?.toString() || '',
      position: order?.position?.id?.toString() || '',
      expertise_level_id: order?.expertise_level?.id?.toString() || '',
      job_type_id: order?.job_type?.id?.toString() || '',
      state_id: order?.state?.id?.toString() || '',
      country_id: order?.state?.country?.id?.toString() || '',
      slug: order?.slug
    }));
    fillSet((prev) => ({ ...prev, position: order?.position?.name || '' }));
  }, [
    order?.expertise_level?.id,
    order?.job_type?.id,
    order?.order_type_id,
    order?.position?.id,
    order?.position?.name,
    order?.slug,
    order?.state?.country?.id,
    order?.state?.id
  ]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    err,
    fill,
    fillSet,
    dataSet,
    errSet,
    onChangeHandler,
    onSubmitEvent
  };
};

export default useEditOrderData;
