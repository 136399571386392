import React from 'react';

// types and state
import { get_normal_date_year } from '@/service/formatter/date';
import { IWorkExperience } from '@/store/types/candidate';
import { CiSquarePlus } from 'react-icons/ci';
import UpdateExperience from './UpdateExperience';
import { useAppSelector } from '@/store/hooks';

const Experience: React.FC = () => {
  const experience = useAppSelector(({ experience }) => experience.experiences);
  const [show, showSet] = React.useState<boolean>(false);
  const [exp, expSet] = React.useState<IWorkExperience | null>(null);

  return (
    <div className="w-[32%] px-1">
      <div className="text-center text-[15px] font-bold pb-5 flex items-center justify-center">
        Work Experience
        <CiSquarePlus
          className="mx-1 cursor-pointer"
          onClick={() => showSet(!show)}
          size={25}
        />
      </div>
      {experience.map((item, i) => (
        <div
          key={i + 1}
          className="bg-[#F5F5F5] px-5 py-3 rounded-md shadow-md my-2"
        >
          <div className="flex justify-between">
            <p className="font-bold capitalize">{item?.company?.name}</p>
            <p
              onClick={() => {
                expSet(item);
                showSet(true);
              }}
              className="cursor-pointer"
            >
              Edit
            </p>
          </div>
          <p className="my-[3px] capitalize">{item?.position?.name}</p>
          <p className="my-[3px] capitalize">{item?.description}</p>
          <p className="my-[3px]">
            {`${
              item?.start_date
                ? get_normal_date_year(item.start_date)
                : 'Current'
            } -
            ${
              item?.end_date ? get_normal_date_year(item.end_date) : 'Current'
            }`}
          </p>
        </div>
      ))}
      <UpdateExperience
        title="Work Experience"
        open={show}
        experirence={exp}
        onSubmit={() => {
          showSet(false);
          expSet(null);
        }}
        onCancel={() => {
          showSet(false);
          expSet(null);
        }}
      />
    </div>
  );
};

export default Experience;
