import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INavButton, INavbar } from '../types/navbar';

// Define the initial state using that type
const initialState: INavbar = {
	search: '',
	title: '',
	button: false,
	button_name: '',
	action: false
};

export const navbar = createSlice({
	name: 'navbar',
	initialState,
	reducers: {
		setSearch: (state, action: PayloadAction<string>) => {
			state.search = action.payload;
		},
		setTitle: (state, action: PayloadAction<string>) => {
			state.title = action.payload;
		},
		setNavButton: (state, action: PayloadAction<INavButton>) => {
			state.button = action.payload.button;
			state.button_name = action.payload.button_name;
		},
		setButtonAction: (state) => {
			state.action = true;
		},
		setButtonActionFalse: (state) => {
			state.action = false;
		}
	}
});

export const {
	setSearch,
	setTitle,
	setNavButton,
	setButtonAction,
	setButtonActionFalse
} = navbar.actions;

export default navbar.reducer;
