import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '@/components/elements/forms/ButtonComponent2';
import CheckBox from '@/components/elements/forms/CheckBox';
import DateInput from '@/components/elements/forms/DatePicker';
import CustomAutoComplete from '@/components/elements/forms/CustomAutoComplete';

// state and types
import {
  IEducation,
  IEducationCustomData,
  IEducationData,
  IEducationDataError
} from '@/store/types/candidate';
import { get_college, create_college } from '@/service/api/college';
import { save_education, update_education } from '@/service/api/education';
import { get_degree, create_degree } from '@/service/api/degree';
import { get_course, create_course } from '@/service/api/course';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router-dom';

type IUpdateEducation = {
  open: boolean;
  title: string;
  education: IEducation | null;
  onSubmit: () => void;
  onCancel: () => void;
};

const UpdateEducation: React.FC<IUpdateEducation> = ({
  title,
  open,
  education,
  onSubmit,
  onCancel
}) => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const loading = useAppSelector(({ education }) => education.loading);
  const colleges = useAppSelector(({ college }) => college.colleges);
  const degrees = useAppSelector(({ degree }) => degree.degrees);
  const courses = useAppSelector(({ course }) => course.courses);
  const college_loading = useAppSelector(({ college }) => college.loading);
  const degree_loading = useAppSelector(({ degree }) => degree.loading);
  const courses_loading = useAppSelector(({ course }) => course.loading);
  const [fill, fillSet] = React.useState<IEducationCustomData>({
    college: '',
    degree: '',
    graduation: null,
    highest: false,
    id: '',
    major: '',
    current: false
  });
  const [data, dataSet] = React.useState<IEducationData>({
    college_id: '',
    degree_id: '',
    graduation: null,
    highest: false,
    id: '',
    major_id: '',
    current: false,
    candidate_number: ''
  });
  const [error, errorSet] = React.useState<IEducationDataError>({
    college_id: '',
    degree: '',
    graduation: '',
    highest: '',
    id: '',
    major: '',
    current: ''
  });

  const clear_field = () => {
    dataSet({
      college_id: '',
      degree_id: '',
      graduation: null,
      highest: false,
      id: '',
      major_id: '',
      current: false,
      candidate_number: ''
    });
    fillSet({
      college: '',
      degree: '',
      graduation: null,
      highest: false,
      id: '',
      major: '',
      current: false
    });
    errorSet({
      college_id: '',
      degree: '',
      graduation: '',
      highest: '',
      id: '',
      major: '',
      current: ''
    });
    onSubmit();
  };

  const onLoad = React.useCallback(() => {
    get_college(dispatch);
    get_degree(dispatch);
    get_course(dispatch);
  }, [dispatch]);

  const onData = React.useCallback(() => {
    if (slug) dataSet((prev) => ({ ...prev, candidate_number: slug }));
    if (education && education?.id) {
      dataSet((prev) => ({
        ...prev,
        college_id: education.college?.id?.toString(),
        degree_id: education?.degree?.id?.toString(),
        graduation: null,
        highest: education?.highest ? true : false,
        id: education?.id?.toString(),
        major_id: education?.major?.id?.toString(),
        current: false
      }));
      fillSet((prev) => ({
        ...prev,
        college: education?.college?.name,
        degree: education?.degree?.name,
        graduation: null,
        major: education?.major?.name,
        current: false
      }));
    }
  }, [education, slug]);

  React.useEffect(() => {
    onLoad();
    onData();
  }, [onData, onLoad]);

  return (
    <Modal
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      centered={true}
      footer={null}
      closable={false}
      cancelText={false}
    >
      <>
        <div className="bg-[#F5F5F5] py-4 px-3 rounded-md">
          <CustomAutoComplete
            items={colleges}
            container="my-2"
            placeholder="Enter College | University"
            value={fill.college}
            loading={college_loading}
            onChange={(e) => {
              const { value } = e.target;
              fillSet((prev) => ({ ...prev, college: value }));
            }}
            onSelect={(res) => {
              fillSet((prev) => ({ ...prev, college: res.name }));
              errorSet((prev) => ({ ...prev, college_id: '' }));
              dataSet((prev) => ({ ...prev, college_id: res.id?.toString() }));
            }}
            onCreate={async (res: string) =>
              create_college(dispatch, { name: res })
            }
            status={error.college_id}
          />

          <CustomAutoComplete
            items={courses}
            loading={courses_loading}
            container="my-2"
            placeholder="Major/Field of study"
            value={fill.major}
            onCreate={async (res) => create_course(dispatch, { name: res })}
            status={error.college_id}
            onChange={(e) => {
              const { value } = e.target;
              fillSet((prev) => ({ ...prev, major: value }));
            }}
            onSelect={(res) => {
              fillSet((prev) => ({ ...prev, major: res.name }));
              errorSet((prev) => ({ ...prev, major: '' }));
              dataSet((prev) => ({ ...prev, major_id: res.id?.toString() }));
            }}
          />

          <CustomAutoComplete
            items={degrees}
            container="my-2"
            placeholder="Degree & Major"
            loading={degree_loading}
            value={fill.degree}
            onChange={(e) => {
              const { value } = e.target;
              fillSet((prev) => ({ ...prev, degree: value }));
            }}
            onSelect={(res) => {
              fillSet((prev) => ({ ...prev, degree: res.name }));
              errorSet((prev) => ({ ...prev, degree_id: '' }));
              dataSet((prev) => ({ ...prev, degree_id: res.id?.toString() }));
            }}
            onCreate={async (res) => create_degree(dispatch, { name: res })}
            status={error.college_id}
          />

          {!data.current && (
            <div className="flex justify-between my-2">
              <DateInput
                placeholder="Graduation"
                container="w-full"
                value={data.graduation}
                status={error.graduation}
                onSelect={(e) => {
                  dataSet((prev) => ({ ...prev, graduation: e }));
                  errorSet((prev) => ({ ...prev, graduation: '' }));
                }}
              />
            </div>
          )}
          <CheckBox
            name="highest"
            id="highest"
            checked={data.highest}
            onChange={() => {
              dataSet((prev) => ({ ...prev, highest: !data.highest }));
            }}
            title="Highest Level of Education"
          />
          <div className="my-1">
            <CheckBox
              title="Still running"
              name="currently"
              id="currently"
              checked={data.current}
              onChange={() => {
                dataSet((prev) => ({
                  ...prev,
                  current: !data.current,
                  graduation: ''
                }));
              }}
            />
          </div>
        </div>

        <div className="w-full flex justify-end mt-5">
          <div className="w-[40%] mx-1">
            <ButtonComponent
              title="Save"
              onHandler={async () => {
                if (!data.id!) {
                  const response = await save_education(dispatch, data);
                  if (response) clear_field();
                } else {
                  const response = await update_education(dispatch, data);
                  if (response) clear_field();
                }
              }}
              backgroundColor="#243677"
              textColor="white"
              active={true}
              loading={loading}
            />
          </div>
          <div className="w-[40%]">
            <ButtonComponent
              title="Cancel"
              onHandler={() => {
                clear_field();
                onCancel();
              }}
              backgroundColor="red"
              textColor="white"
              active={true}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

export default UpdateEducation;
