import { configureStore } from '@reduxjs/toolkit';

// environment
import env from '../config/env';

// store
import auth from './slices/auth';
import order from './slices/order';
import misc from './slices/misc';
import candidate from './slices/candidate';
import loading from './slices/loading';
import complain from './slices/complain_and_contact';
import report from './slices/report';
// import industry from './slices/company_industry';
import industry from './slices/industry';
import report_role from './slices/report_role';
import message from './slices/message';
import client from './slices/client';
import user from './slices/user';
import task from './slices/task';
import navbar from './slices/navbar';
import schedule from './slices/schedule';
import mailer from './slices/mailer';
import role from './slices/role';
import college from './slices/college';
import degree from './slices/degree';
import course from './slices/course';
import company from './slices/company';
// import experience from './slices/work_experience';
import education from './slices/education';
import blue_order from './slices/blue_order';
import gender from './slices/gender';
import communication from './slices/communication';
import experience from './slices/experience';
import qualification from './slices/qualification';
import blue_role from './slices/blue_role';
import job_type from './slices/job_type';
import soft_skill from './slices/soft_skill';
import technical_skill from './slices/technical_skill';
import payment_type from './slices/payment_type';
import currency from './slices/currency';
import culture from './slices/culture';

export const store = configureStore({
  reducer: {
    auth,
    order,
    task,
    candidate,
    report_role,
    misc,
    loading,
    complain,
    client,
    report,
    industry,
    message,
    user,
    gender,
    navbar,
    schedule,
    mailer,
    role,
    college,
    degree,
    course,
    company,
    experience,
    education,
    blue_order,
    communication,
    qualification,
    blue_role,
    job_type,
    soft_skill,
    technical_skill,
    payment_type,
    currency,
    culture
  },
  devTools: env.enviroment === 'production' ? false : true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
