import React from 'react';

// components
import { Spin } from 'antd';

type IButtonComponent = {
  title: string;
  loading?: boolean;
  disabled?: boolean;
  onHandler: () => void;
  backgroundColor?: string;
  textColor?: string;
  active?: boolean;
};

const ButtonComponent: React.FC<IButtonComponent> = ({
  title,
  loading,
  backgroundColor,
  active,
  textColor,
  onHandler
}) => {
  return (
    <button
      style={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : active
          ? '#243677'
          : '#ffffff',
        color: active && textColor ? textColor : '#243677'
      }}
      className={`w-full font-[800] font-abel py-3 rounded-md text-[15px]`}
      onClick={() => {
        if (active && !loading) onHandler();
      }}
    >
      {loading && <Spin className="mr-4" />}
      {title}
    </button>
  );
};

export default ButtonComponent;
