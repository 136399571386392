import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';
import TextInput from '@/components/elements/forms/TextInput';

// constants and service
import { validateEmail } from '@/service/validator/validate';

// types
import { create_admin } from '@/service/api/user';
import { ErrorWarning } from '@/store/types/misc';
import { useAppDispatch } from '@/store/hooks';

type ICreateUser = {
	visible: boolean;
	onDone: () => void;
};

const CreateUser: React.FC<ICreateUser> = ({ visible, onDone }) => {
	const dispatch = useAppDispatch();
	const [data, dataSet] = React.useState<string>('');
	const [err, errSet] = React.useState<ErrorWarning>('');

	return (
		<Modal
			open={visible}
			closeIcon={false}
			footer={null}
			width="50%"
			closable={false}
			centered={true}
		>
			<div className="">
				<div className="flex items-center justify-between w-full">
					<p className="my-4 font-abel font-bold text-[20px]">Create User</p>
					<div className="w-[15%]">
						<ButtonComponent
							title="Cancel"
							backgroundColor="#E59285"
							active={true}
							onHandler={() => {
								onDone();
								dataSet('');
							}}
						/>
					</div>
				</div>

				<div className="mt-4 mb-4 w-full ">
					<TextInput
						name="email"
						placeholder="Enter Email"
						status={err}
						value={data}
						onChange={(e) => {
							dataSet(e.target.value);
							errSet('');
						}}
					/>
				</div>

				<div className="mt-8 w-full">
					<div className="w-full">
						<ButtonComponent
							active={true}
							title="Submit"
							loading={false}
							onHandler={() => {
								if (!data && !validateEmail(data)) return errSet('warning');
								create_admin(dispatch, { email: data });
								dataSet('');
								onDone();
							}}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default CreateUser;
