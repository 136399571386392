import React from 'react';

// state and types
import {
  get_my_permission,
  get_my_roles,
  get_permissions,
  get_roles
} from '@/service/api/user';
import { ISetRolePermission } from '@/store/types/user';
import { useAppSelector, useAppDispatch } from '@/store/hooks';

// components
import RadioSelect from './RadioSelect';

type IRolesPermission = {
  admin_number: string;
  onAddRole: (role_id: string) => void;
  onAddPermission: (res: ISetRolePermission) => void;
};

const RolesPermission: React.FC<IRolesPermission> = ({
  admin_number,
  onAddRole,
  onAddPermission
}) => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(({ user }) => user.roles);
  const admin_roles = useAppSelector(({ user }) => user.admin_roles);
  const admin_permission = useAppSelector(({ user }) => user.admin_permission);
  const permissions = useAppSelector(({ user }) => user.permission);
  const [selected, selectedSet] = React.useState<string>('');
  const [adminRoleId, adminRoleIdSet] = React.useState<string>('');

  const onSelected = (id: string) => {
    if (id)
      return admin_roles.find((item) => item?.role?.id?.toString() === id);
    return false;
  };

  const onLoad = React.useCallback(() => {
    if (admin_number) {
      get_roles(dispatch);
      get_permissions(dispatch);
      get_my_roles(dispatch, { admin_number });
    }
  }, [admin_number, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <div className="w-[20%]">
        <h3 className="text-[18px] text-[#567BFF]">Roles</h3>
        <div className="w-full">
          {roles.map((role, _) => (
            <RadioSelect
              key={_ + 1}
              name={role.name}
              checked={selected === role.id?.toString() ? true : false}
              onClick={(_e) => {
                if (selected === role.id?.toString()) {
                  selectedSet('');
                  adminRoleIdSet('');
                } else {
                  const check = onSelected(role.id?.toString());
                  if (check) {
                    adminRoleIdSet(check?.id?.toString());
                    selectedSet(role.id?.toString());
                    get_my_permission(dispatch, { id: check.id?.toString() });
                  } else onAddRole(role.id?.toString());
                }
              }}
            />
          ))}
        </div>
      </div>

      {/* Permission */}
      <div className="w-[20%]">
        <h3 className="text-[17px] text-[#567BFF]">Permission</h3>
        <div>
          {selected &&
            permissions.map((permission, _) => (
              <RadioSelect
                key={_ + 1}
                name={permission.name}
                checked={
                  admin_permission.find(
                    (item) =>
                      item?.permission?.id?.toString() ===
                      permission?.id?.toString()
                  )
                    ? true
                    : false
                }
                onClick={(_e) => {
                  const exist = admin_permission.find(
                    (item) =>
                      item?.permission_id?.toString() ===
                      permission?.id?.toString()
                  );

                  if (!exist)
                    onAddPermission({
                      admin_role_id: adminRoleId,
                      permission_id: permission.id?.toString()
                    });
                }}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default RolesPermission;
