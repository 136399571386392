import React from 'react';

// constants and icons
import {AiFillEdit} from 'react-icons/ai'

type IUpdateInput = {
	title: string;
	name: string;
	onUpdate: (data: { name: string }) => void;
};

const UpdateInput: React.FC<IUpdateInput> = ({ title, name, onUpdate }) => {
	const [data, dataSet] = React.useState<string>('');
	const [show, showSet] = React.useState<boolean>(false);

	const onLoad = React.useCallback(() => {
		dataSet(name);
	}, [name]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
			<label className="text-[#A4A4A4]">{title}</label>
			<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
				{show ? (
					<input
						name="name"
						value={data}
						className="mx-3 w-full outline-none px-2"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							dataSet(e.target.value)
						}
						onBlur={() => {
							showSet(false);
							onUpdate({ name: data });
						}}
					/>
				) : (
					<div className="flex items-center justify-between w-full px-4">
						<p className="text-[#707070]">{data}</p>
						<AiFillEdit size={20}  onClick={() => showSet(true)} />
					</div>
				)}
			</div>
		</div>
	);
};

export default UpdateInput;
