// import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { setOrders, setOrder, setLoading } from '@/store/slices/blue_order';
import {} from '@/store/types/candidate';

// notification
// import { openNotification } from '@/components/alerts/notify';

export const get_blue_orders = async (dispatch: Function, page: number = 1) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-blue-order?page=${page}`);

    dispatch(setOrders(response.data));
  } catch (err) {
    // dispatch(setOrders([]));
  }
  dispatch(setLoading());
};

export const get_blue_order = async (dispatch: Function, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-blue-order/${id}`);

    dispatch(setOrder(response.data));
  } catch (err) {}
  dispatch(setLoading());
};
