import React from 'react';

// components
import MessageBody from './MessageBody';
import { Spin } from 'antd';

// constants
import { BsBroadcastPin } from 'react-icons/bs';

// types and services
import { broadcast_candidates } from '@/service/api/mailer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { IMailerCandidate } from '@/store/types/mailer';

const BroadCastCandidate = () => {
  const dispatch = useAppDispatch();
  const loader = useAppSelector(({ mailer }) => mailer.bc_loader);
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IMailerCandidate>({
    message: '',
    subject: ''
  });

  return (
    <div className="cursor-pointer w-[49%] rounded-sm shadow-sm border border-[#243677] text-center">
      <div
        className="text-[15px] border py-8 px-3 flex justify-center items-center"
        onClick={() => showSet(true)}
      >
        <BsBroadcastPin size={25} className="mr-2" />
        Candidate Broadcast Message
        {loader && <Spin size="default" className="ml-4" />}
      </div>
      <MessageBody
        title="BroadCast Candidate"
        data={data}
        visible={show}
        onMessageChange={{
          mChange: (e) => dataSet((prev) => ({ ...prev, message: e })),
          sChange: (e) =>
            dataSet((prev) => ({ ...prev, subject: e.target.value }))
        }}
        onCancel={() => {
          showSet(false);
          dataSet({ message: '', subject: '' });
        }}
        onDone={() => {
          showSet(false);
          if (data.message && data.subject) {
            broadcast_candidates(dispatch, data);
            dataSet({ message: '', subject: '' });
          }
        }}
      />
    </div>
  );
};

export default BroadCastCandidate;
