import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// state
import {
  setGenderReport,
  setLoadingUpdate,
  setReport,
  setReportStats,
  setReportStatus,
  setReports,
  setStatistics,
  setTotalReports
} from '../../store/slices/report';
import {
  IAddCandidateReport,
  IDeleteOrderReport,
  IGetPaginated,
  IGetReportFunc,
  IGetReportUpdateFunc,
  ISTOREUPDATEFunc,
  ISaveReportOrder,
  ISearchPaginated,
  IUpdateCandidateHeirarchy,
  IUpdateReportName,
  IUpdateReportStatus
} from '../../store/types/report';
import { setMessage, setToast } from '../../store/slices/message';

export const get_total_reports = async (dispatch: any) => {
  try {
    const response = await axiosInstance.get('/get-number-of-reports');

    dispatch(setTotalReports(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_reports: IGetPaginated = async (dispatch, page = '1') => {
  try {
    const response = await axiosInstance.get(`/get-reports?page=${page}`);

    dispatch(setReports(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

type IStat = (dispatch: Function) => void;
export const report_stats: IStat = async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/report-statistics`);

    dispatch(setStatistics(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const update_report_status: IGetPaginated = async (dispatch, id) => {
  dispatch(setLoadingUpdate());
  try {
    await axiosInstance.get(`/change-report-status/${id}`);
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoadingUpdate());
};

export const report_completed = async (
  dispatch: Function,
  data: IUpdateReportStatus
) => {
  dispatch(setLoadingUpdate());
  try {
    await axiosInstance.post(`/report-completed`, data);

    dispatch(setReportStatus(data.status));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoadingUpdate());
};

export const get_report: IGetReportFunc = async (dispatch, id) => {
  try {
    const response = await axiosInstance.get(`/get-report/${id}`);

    dispatch(setReport(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const search_reports: ISearchPaginated = async (
  dispatch,
  search,
  page = '1'
) => {
  try {
    const response = await axiosInstance.get(
      `/search-report/${search}?page=${page}`
    );

    dispatch(setReports(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_report_statistics: IGetReportFunc = async (dispatch, id) => {
  try {
    const response = await axiosInstance.get(`/get-report-statistics/${id}`);

    dispatch(setReportStats(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_report_gender: IGetReportFunc = async (dispatch, slug) => {
  try {
    const response = await axiosInstance.get(
      `/get-gender-category-total/${slug}`
    );

    dispatch(setGenderReport(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const store_report: ISTOREUPDATEFunc = async (
  dispatch,
  formData,
  navigate
) => {
  dispatch(setLoadingUpdate());
  try {
    const response = await axiosInstance.post('/create-report', formData);

    navigate(`/add-candidate-report/${response.data}`);
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoadingUpdate());
};

export const update_report: ISTOREUPDATEFunc = async (dispatch, formData) => {
  try {
    const response = await axiosInstance.post('/update-report', formData);

    dispatch(
      setMessage({
        type: 'success',
        title: 'Update Created',
        message: response.data?.message
      })
    );
  } catch (err) {
    if (isAxiosError(err)) {
      dispatch(
        setMessage({
          type: 'success',
          title: 'Update Created',
          message: err?.response?.data?.message
        })
      );
    }
  }
};

export const save_report_order: ISaveReportOrder = async (dispatch, data) => {
  try {
    const response = await axiosInstance.post('/create-order-report', data);

    const { message } = response.data;
    dispatch(setToast({ message, type: 'success' }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const save_report_role: ISaveReportOrder = async (dispatch, data) => {
  try {
    const response = await axiosInstance.post('/create-report-role', data);

    const { message } = response.data;
    dispatch(setToast({ message, type: 'success' }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const delete_report_order: IDeleteOrderReport = async (
  dispatch,
  formData
) => {
  try {
    const response = await axiosInstance.post('/delete-report-order', formData);

    const { message } = response.data;
    dispatch(setToast({ message, type: 'success' }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const get_report_client_candidate: IAddCandidateReport = async (
  dispatch,
  formData
) => {
  try {
    const response = await axiosInstance.post(
      '/get-report-role-candidate',
      formData
    );

    const { message } = response.data;
    dispatch(setToast({ message, type: 'success' }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const add_candidate_report_order: IAddCandidateReport = async (
  dispatch,
  formData
) => {
  try {
    const response = await axiosInstance.post(
      '/create-report-role-candidate',
      formData
    );

    const { message } = response.data;
    dispatch(setToast({ message, type: 'success' }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_name: IUpdateReportName = async (dispatch, data) => {
  try {
    const response = await axiosInstance.post('/update-report-name', data);

    const { message } = response?.data;
    // dispatch()
    dispatch(setToast({ message, type: 'success' }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_role_candidate_heirarchy: IUpdateCandidateHeirarchy =
  async (dispatch, data) => {
    try {
      const response = await axiosInstance.post(
        '/update-report-role-candidate-heirarchy',
        data
      );

      const { message } = response?.data;
      dispatch(setToast({ message, type: 'success' }));
    } catch (err) {
      if (isAxiosError(err)) {
        const { message } = err?.response?.data;
        dispatch(setToast({ type: 'error', message }));
      }
    }
  };

export const remove_candidate_report: IAddCandidateReport = async (
  dispatch,
  formData
) => {
  try {
    const response = await axiosInstance.post(
      '/delete-report-role-candidate',
      formData
    );

    const { message } = response.data;
    dispatch(setToast({ message, type: 'success' }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ message, type: 'error' }));
    }
  }
};

export const update_report_purpose: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-purpose', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_overview: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-overview', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_summary: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-summary', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_sourcing: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-sourcing', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_limitation: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-limitation', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_comment: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-comment', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_candidate_note: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-candidates-note', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_candidate_characteristics: IGetReportUpdateFunc =
  async (dispatch, data) => {
    try {
      const response = await axiosInstance.post(
        '/update-candidates-characteristics',
        data
      );

      const { message } = response.data;
      dispatch(setToast({ type: 'success', message }));
    } catch (err) {
      if (isAxiosError(err)) {
        const { message } = err?.response?.data;
        dispatch(setToast({ type: 'error', message }));
      }
    }
  };

export const update_report_results: IGetReportUpdateFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post('/update-results', data);

    const { message } = response.data;
    dispatch(setToast({ type: 'success', message }));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setToast({ type: 'error', message }));
    }
  }
};

export const update_report_candidate_disqualified: IGetReportUpdateFunc =
  async (dispatch, data) => {
    try {
      const response = await axiosInstance.post('/update-disqualified', data);

      const { message } = response.data;
      dispatch(setToast({ type: 'success', message }));
    } catch (err) {
      if (isAxiosError(err)) {
        const { message } = err?.response?.data;
        dispatch(setToast({ type: 'error', message }));
      }
    }
  };
