import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  courses: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  courses: [],
  update: false,
  loading: false
};

export const course = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourse: (state, action: PayloadAction<[]>) => {
      state.courses = action.payload;
    },
    updateCourse: (state, action: PayloadAction<IDataType>) => {
      state.courses = [...state.courses, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const { setCourse, updateCourse, setLoading, setUpdate } =
  course.actions;

export default course.reducer;
