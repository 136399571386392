import React from 'react';

// icons
import { RxCaretDown, RxCaretUp } from 'react-icons/rx';

type ICollapse = {
	title: string;
	icon: React.ReactNode;
	children: React.ReactNode;
};

const Collapse: React.FC<ICollapse> = ({ title, icon, children }) => {
	const [show, showSet] = React.useState(false);
	return (
		<div>
			<div
				onClick={() => showSet(!show)}
				className="flex items-center justify-between py-4 border px-2 cursor-pointer rounded-sm"
			>
				<div className="flex">
					<div className="mx-3">
						<div className="bg-[#C3D0FF] p-1 rounded-full">{icon}</div>
					</div>
					<div className="mx-3 text-[#243677]">{title}</div>
				</div>

				<div>{show ? <RxCaretUp size={23} /> : <RxCaretDown size={23} />}</div>
			</div>
			{show && (
				<div className="py-4 border-b border-x px-2 rounded-sm ">
					{children}
				</div>
			)}
		</div>
	);
};

export default Collapse;
