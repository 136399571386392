import { Spin } from 'antd';
import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

type IHeaderTitle = {
  title: string;
  loading?: boolean;
  onClick: () => void;
};

const HeaderTitle: React.FC<IHeaderTitle> = ({ title, loading, onClick }) => {
  return (
    <div className="flex items-center justify-between border-b pb-3">
      <h3 className="font-bold">{title}</h3>
      {loading ? (
        <Spin />
      ) : (
        <AiOutlinePlus size={20} className="cursor-pointer" onClick={onClick} />
      )}
    </div>
  );
};

export default HeaderTitle;
