import React from 'react';
// import { FaArrowUp } from 'react-icons/fa';

// services and state
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { get_total_clients } from '../../../service/api/client';
import { get_total_industry } from '../../../service/api/company_industry';

const StatsClients: React.FC = () => {
	const dispatch = useAppDispatch();
	const total_clients = useAppSelector(({ client }) => client.total_clients);
	const total_industry = useAppSelector(({ industry }) => industry.total);
	const onLoad = React.useCallback(() => {
		get_total_clients(dispatch);
		get_total_industry(dispatch);
	}, [dispatch]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="flex justify-evenly flex-wrap mt-2 mb-5">
			<div className="text-left bg-white px-6 pt-4 pb-4 md:w-[31%] lg:w-[31%] w-[98%]">
				<p className="md:text-[22px] text-[16px] mb-3">
					Total number of clients
				</p>
				<p className="md:text-[60px] md:leading-10 leading-6 text-[30px] mb-0">
					{total_clients}
				</p>
				<div className="text-[green] flex items-center justify-end">
					{/* <FaArrowUp size={15} color="green" /> */}
					{/* <p className="text-[20px]">+20%</p> */}
				</div>
			</div>
			<div className="text-left bg-white px-6 pt-4 pb-4 md:w-[31%] lg:w-[31%] w-[98%]">
				<p className="md:text-[22px] text-[16px] mb-3">
					Total number of industries
				</p>
				<p className="md:text-[60px] md:leading-10 leading-6 text-[30px] mb-0">
					{total_industry}
				</p>
				<div className="text-[green] flex items-center justify-end">
					{/* <FaArrowUp size={15} color="green" /> */}
					{/* <p className="text-[20px]">+20%</p> */}
				</div>
			</div>
			<div className="text-left px-6 pt-4 pb-4 md:w-[31%] lg:w-[31%] w-[98%]"></div>
		</div>
	);
};

export default StatsClients;
