import React from 'react';

type ITabsItem = {
	label: string | React.ReactNode;
	key: number;
	children: string | React.ReactNode;
};

type ITabs = {
	items: ITabsItem[];
	defaultTab: number;
	onChange?: () => void;
};

const Tab: React.FC<ITabs> = ({ items, defaultTab, onChange }) => {
	const [active, activeSet] = React.useState<number>(defaultTab);

	return (
		<div className="w-full">
			<div className="flex w-full justify-between items-center h-[50px]">
				{items.map((item) => (
					<div
						key={item.key}
						onClick={() => {
							activeSet(item.key);
							onChange?.();
						}}
						className={`w-full flex justify-center items-center hover:bg-[#d9d9d9] h-full cursor-pointer ${
							active === item.key
								? 'bg-[#d9d9d9] border-b-2 border-[#243677]'
								: 'bg-[white]'
						}`}
					>
						{item.label}
					</div>
				))}
			</div>

			{items.length &&
				items?.map((item) => (
					<div
						id={item.key?.toString()}
						key={item.key}
						className={`w-full h-full ${
							active === item.key ? 'block' : 'hidden'
						}`}
					>
						{item.children}
					</div>
				))}
		</div>
	);
};

export default Tab;
