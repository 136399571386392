import React from 'react';

// components
import { sorted_countries } from '../../../service/constants/countries';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

const Region = () => {
	const onHandleSubmit = () => {
		console.log(JSON.stringify(sorted_countries));
		// const sorted_countries.map(item => )
	};

	return (
		<div className='px-10'>
			<ButtonComponent onHandler={onHandleSubmit} title="Populate Country and State" />
		</div>
	);
};

export default Region;
