import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setQualifications,
  createQualification,
  updateQualification,
  setLoading,
  setUpdate
} from '@/store/slices/qualification';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_qualifications = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-qualifications');

    dispatch(setQualifications(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setQualifications([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_qualification = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-qualification', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createQualification(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_qualification = async (
  dispatch: Function,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-qualification', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateQualification(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
