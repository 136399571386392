import React from 'react'
// components
import user from '../../../assets/images/icons/user.svg'

// router
// import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import ButtonLink from '../../../components/elements/forms/ButtonLink';
import TextInput from '../../../components/elements/forms/TextInput';
import TextInputBox from '../../../components/elements/forms/TextInputBox';

const CreateCandidate = () => {
	const dispatch = useAppDispatch();

	const onSetHeader = React.useCallback(() => {
		dispatch(setTitle('Create Candidate'));
		dispatch(setNavButton({ button_name: '', button: false }));
	}, [dispatch]);

	React.useEffect(() => {
		onSetHeader();
		// onLoad();
	}, [onSetHeader]);

  return (
    <div className='font-abel w-full text-left text-[#4E4E4E] bg-[white] px-10 py-4'>
        <div className='flex items-center mb-4'>
            <div className='rounded-full border-[#4E4E4E] border-[2px] w-[132px] h-[132px] text-center object-cover overflow-hidden md:mr-5'>
                <img src={user} alt="upload" className='h-[100%] w-full rounded-full object-cover p-1' />
            </div>
            <div className='border border-[#4E4E4E] h-[46.5px] w-[150px] px-2 ml-2 flex justify-center items-center cursor-pointer'>
                <p className='text-[17px]'>Add profile picture</p>
            </div>
        </div>
        <div className='flex justify-between'>
            <div className="mb-5 w-[48%]">
                <label className='text-[#333333] text-sm pb-4'>First Name</label>
                <TextInput
                    name="name"
                    value=""
                    // status={error.title}
                    placeholder="First Name"
                    onChange={(e) => {}}
                />
            </div>
            <div className="mb-5 w-[48%]">
                <label className='text-[#333333] text-sm pb-4'>Last Name</label>
                <TextInput
                    name="last"
                    value=""
                    // status={error.title}
                    placeholder="Last Name"
                    onChange={(e) => {}}
                />
            </div>
        </div>
        <div className='flex justify-between'>
			<div className="mb-5 w-[48%]">
                <label className='text-[#333333] text-sm pb-4'>Other Name</label>
                <TextInput
                    name="other"
                    value=""
                    // status={error.title}
                    placeholder="Other Name"
                    onChange={(e) => {}}
                />
            </div>
            <div className="mb-5 w-[48%]">
                <label className='text-[#333333] text-sm pb-4'>Email Address</label>
                <TextInput
                    name="email"
                    value=""
                    // status={error.title}
                    placeholder="Email Address"
                    onChange={(e) => {}}
                />
            </div>
        </div>
        <div className='flex justify-between'>
			<div className="mb-5 w-[48%]">
                <label className='text-[#333333] text-sm pb-4'>Phone Number</label>
                <TextInput
                    name="phone"
                    value=""
                    // status={error.title}
                    placeholder="Phone Number"
                    onChange={(e) => {}}
                />
            </div>
            <div className="mb-5 w-[48%]">
                <label className='text-[#333333] text-sm pb-4'>Address</label>
                <TextInput
                    name="address"
                    value=""
                    // status={error.title}
                    placeholder="Address"
                    onChange={(e) => {}}
                />
            </div>
        </div>
        <div className='flex justify-between'>
            <div className="mb-5 w-[100%]">
                <label className='text-[#333333] text-sm pb-0'>Additional Information</label>
                <TextInputBox name="comment" value={''} placeholder='' />
            </div>
        </div>
        <div className='flex justify-center'>
            <ButtonLink
                title='Add Candidate'
                path='/'
                textColor="white"
                backgroundColor="#243677"
            />
        </div>
    </div>
  )
}

export default CreateCandidate
