import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';
// import { notification } from 'antd';

// state
import {
  setCandidates,
  setTotalCandidateNumber,
  setTotalCandidateGender,
  setCandidate,
  setLoading,
  setShow,
  setStattistics,
  setProfileStatistics,
  setWeeklyStat
} from '../../store/slices/candidate';
import { IGetCandidate } from '../../store/types/candidate';
import { ISavingSingleEmail } from '../../store/types/misc';

export const get_candidates = async (
  dispatch: Function,
  page: string = '1',
  q: string = ''
) => {
  dispatch(setLoading());
  try {
    const search = q ? `q=${q}&` : '';
    const response = await axiosInstance.get(
      `/get-candidates?${search}page=${page}`
    );

    dispatch(setCandidates(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      // logger
    }
  }
  dispatch(setLoading());
};

export const get_candidate: IGetCandidate = async (dispatch, slug) => {
  dispatch(setShow());
  try {
    const response = await axiosInstance.get(`/get-candidate/${slug}`);

    dispatch(setCandidate(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      // logger
    }
  }
  dispatch(setShow());
};

export const search_candidates = async (
  dispatch: any,
  search: string,
  page: string = '1'
) => {
  try {
    const response = await axiosInstance.get(
      `/search-user/${search}?page=${page}`
    );

    dispatch(setCandidates(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const candidate_stat = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('/candidate-statistics');

    dispatch(setStattistics(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const candidate_weekly_stat = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('/candidate-weekly-statistics');

    dispatch(setWeeklyStat(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const profile_stats = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('/candidate-profile-statistics');

    dispatch(setProfileStatistics(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_total_candidate = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get(`/get-number-of-candidates`);

    dispatch(setTotalCandidateNumber(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const update_highesteduation_level = async (
  dispatch: Function,
  data: ISavingSingleEmail
) => {
  try {
    const response = await axiosInstance.post(
      `/update-client-highest-education-level`,
      data
    );

    dispatch(setTotalCandidateNumber(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_total_candidate_gender = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('/get-gender-count');

    dispatch(setTotalCandidateGender(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};
