import React from 'react';

// components
import ChooseDrawerOption from '../misc/ChooseDrawerOption';
import Paginate from '../../../components/ui/paginate';
import Confirm from '../../../components/ui/modal/Confirm';

// state
import { nextPage, prevPage } from '../../../store/slices/candidate';
import {
	get_candidates,
	search_candidates
} from '../../../service/api/candidate';
import { add_candidate_to_order } from '../../../service/api/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

// types
import { IUserData } from '../../../store/types/candidate';

// navigation
import { useParams } from 'react-router-dom';

const AddCandidate = () => {
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const candidates = useAppSelector(({ candidate }) => candidate.candidates);
	const search = useAppSelector(({ navbar }) => navbar.search);
	const current_page = useAppSelector(
		({ candidate }) => candidate.current_page
	);
	const last_page = useAppSelector(({ candidate }) => candidate.last_page);
	const [detail, detailSet] = React.useState<IUserData | null>(null);
	const [show, showSet] = React.useState<boolean>(false);
	const [email, emailSet] = React.useState<string>('');

	const onLoad = React.useCallback(() => {
		if (search) search_candidates(dispatch, search, current_page);
		else get_candidates(dispatch, current_page);
	}, [current_page, dispatch, search]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="w-full h-full">
			<div className="flex justify-center items-center bg-table-bg px-3 py-5 w-full">
				<div className="mx-1 border-r px-2 w-[20%] text-center">FullName</div>
				<div className="mx-1 border-r px-2 w-[20%] text-center">Position</div>
				<div className="mx-1 border-r px-2 w-[15%] text-center">YoE(s)</div>
				<div className="mx-1 border-r px-2 w-[15%] text-center">Gender</div>
				<div className="mx-1 border-r px-2 w-[20%] text-center">Location</div>
				<div className="mx-1 px-2 w-[10%] text-center">Action</div>
			</div>

			{candidates.map((candidate) => (
				<div className="flex justify-center w-full items-center bg-table-bg px-3 py-5">
					<div className="mx-1 border-r px-2 w-[20%] text-center">
						{candidate?.profile?.first_name
							? candidate?.profile?.first_name
							: ''}{' '}
						{candidate?.profile?.last_name ? candidate?.profile?.last_name : ''}
					</div>
					{/* <div className="mx-1 border-r px-2 w-5/12 text-center">Email</div> */}
					<div className="mx-1 border-r px-2 w-[20%] text-center capitalize">
						{candidate?.profile?.address?.about?.profession?.name}
					</div>
					<div className="mx-1 border-r px-2 w-[15%] text-center">
						{candidate?.profile?.address?.about?.years_of_experience
							? candidate?.profile?.address?.about?.years_of_experience +
							  ' years(s)'
							: ''}
					</div>
					<div className="mx-1 border-r px-2 w-[15%] text-center capitalize">
						{candidate?.profile?.gender_status?.name}
					</div>
					<div className="mx-1 border-r px-2 w-[20%] text-center capitalize">
						{candidate?.profile?.address?.residential_state
							? `${candidate?.profile?.address?.residential_state?.name}, ${candidate?.profile?.address?.residential_state?.country?.name}`
							: ''}
					</div>
					<div className="mx-1 px-2 w-[10%] text-center flex justify-between">
						<div
							onClick={() => detailSet(candidate)}
							className="text-[10px] underline text-[red] cursor-pointer"
						>
							View
						</div>
						<div
							onClick={() => {
								showSet(true);
								emailSet(candidate.email);
							}}
							className="text-[10px] text-[#243677] underline cursor-pointer"
						>
							Add
						</div>
					</div>
				</div>
			))}

			<Paginate
				current_page={current_page}
				last_page={last_page}
				onPressNext={() => dispatch(nextPage())}
				onPressPrev={() => dispatch(prevPage())}
			/>

			<Confirm
				open={show}
				onAccept={() => {
					if (id && email)
						add_candidate_to_order(dispatch, { slug: id, email });
					emailSet('');
					showSet(false);
				}}
				onCancel={() => showSet(false)}
			/>

			<ChooseDrawerOption
				show={detail ? true : false}
				detail={detail}
				onClose={() => detailSet(null)}
			/>
		</div>
	);
};

export default AddCandidate;
