import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '@/components/elements/forms/ButtonComponent2';
import CheckBox from '@/components/elements/forms/CheckBox';
import DateInput from '@/components/elements/forms/DatePicker';
import CustomAutoComplete from '@/components/elements/forms/CustomAutoComplete';
import TextArea from '@/components/elements/forms/TextArea';

// state and types
import {
  ICandidateExperienceCustomData,
  ICandidateExperienceData,
  ICandidateExperienceError,
  IWorkExperience
} from '@/store/types/candidate';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { get_company, create_company } from '@/service/api/company';
import { get_role, create_role } from '@/service/api/role';
import {
  save_work_experience,
  update_work_experience
} from '@/service/api/work_experience';
import { useParams } from 'react-router-dom';
import { mysql_date } from '@/service/formatter/date';

type IUpdateEducation = {
  open: boolean;
  title: string;
  experirence?: IWorkExperience | null;
  onSubmit: () => void;
  onCancel: () => void;
};

const UpdateExperience: React.FC<IUpdateEducation> = ({
  title,
  open,
  experirence,
  onSubmit,
  onCancel
}) => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const loading = useAppSelector(({ experience }) => experience.loading);
  const companies = useAppSelector(({ company }) => company.companies);
  const company_loading = useAppSelector(({ company }) => company.loading);
  const roles = useAppSelector(({ role }) => role.roles);
  const role_loading = useAppSelector(({ role }) => role.loading);
  const [fill, fillSet] = React.useState<ICandidateExperienceCustomData>({
    company: '',
    description: '',
    end_date: '',
    position: '',
    start_date: ''
  });
  const [data, dataSet] = React.useState<ICandidateExperienceData>({
    company_id: '',
    description: '',
    end_date: '',
    position_id: '',
    start_date: '',
    current: false,
    candidate_number: '',
    id: ''
  });
  const [error, errorSet] = React.useState<ICandidateExperienceError>({
    company_id: '',
    description: '',
    end_date: '',
    position_id: '',
    start_date: '',
    id: ''
  });

  const clear_field = () => {
    dataSet({
      company_id: '',
      description: '',
      end_date: '',
      position_id: '',
      start_date: '',
      current: false,
      candidate_number: '',
      id: ''
    });
    fillSet({
      company: '',
      description: '',
      end_date: '',
      position: '',
      start_date: ''
    });
    errorSet({
      company_id: '',
      description: '',
      end_date: '',
      position_id: '',
      start_date: '',
      id: ''
    });
    onSubmit();
  };

  const onLoad = React.useCallback(() => {
    get_company(dispatch);
    get_role(dispatch);
  }, [dispatch]);

  const onSetData = React.useCallback(() => {
    if (slug) dataSet((prev) => ({ ...prev, candidate_number: slug }));
    if (experirence && experirence?.start_date) {
      dataSet((prev) => ({
        ...prev,
        description: experirence?.description,
        start_date: experirence.start_date
          ? mysql_date(experirence.start_date)
          : '',
        end_date: experirence?.end_date
          ? mysql_date(experirence?.end_date)
          : '',
        id: experirence?.id?.toString(),
        company_id: experirence?.company?.id?.toString(),
        position_id: experirence?.position?.id?.toString(),
        current: experirence.current
      }));
      fillSet((prev) => ({
        ...prev,
        company: experirence?.company?.name,
        description: experirence?.description,
        end_date: experirence?.end_date
          ? mysql_date(experirence?.end_date)
          : '',
        position: experirence?.position?.name,
        start_date: experirence.start_date
          ? mysql_date(experirence.start_date)
          : ''
      }));
    }
  }, [experirence, slug]);

  React.useEffect(() => {
    onLoad();
    onSetData();
  }, [onLoad, onSetData]);

  return (
    <Modal
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      centered={true}
      footer={null}
      closable={false}
      cancelText={false}
    >
      <>
        <div className="bg-[#F5F5F5] py-4 px-3 rounded-md">
          <CustomAutoComplete
            items={companies}
            container="my-2"
            placeholder="Enter Company"
            value={fill.company}
            loading={company_loading}
            status={error.company_id}
            onCreate={(res) => create_company(dispatch, { name: res })}
            onChange={(e) => {
              const { value } = e.target;
              fillSet((prev) => ({ ...prev, company: value }));
            }}
            onSelect={(res) => {
              fillSet((prev) => ({ ...prev, company: res.name }));
              errorSet((prev) => ({ ...prev, company_id: '' }));
              dataSet((prev) => ({ ...prev, company_id: res.id?.toString() }));
            }}
          />

          <CustomAutoComplete
            items={roles}
            loading={role_loading}
            container="my-2"
            placeholder="Role | Position"
            value={fill.position}
            onCreate={async (res) => create_role(dispatch, { name: res })}
            status={error.position_id}
            onChange={(e) => {
              const { value } = e.target;
              fillSet((prev) => ({ ...prev, position: value }));
            }}
            onSelect={(res) => {
              fillSet((prev) => ({ ...prev, position: res.name }));
              errorSet((prev) => ({ ...prev, position_id: '' }));
              dataSet((prev) => ({ ...prev, position_id: res.id?.toString() }));
            }}
          />
          <TextArea
            name="description"
            value={data.description}
            placeholder="Enter Job Description"
            status={error.description}
            onChange={(e) => {
              const { value } = e.target;
              dataSet((prev) => ({ ...prev, description: value }));
            }}
          />

          <div className="flex justify-between">
            <DateInput
              placeholder="Start Date"
              container={`w-full mr-1`}
              status={error.start_date}
              value={data.start_date}
              onSelect={(e) => {
                dataSet((prev) => ({ ...prev, start_date: e }));
                errorSet((prev) => ({ ...prev, start_date: '' }));
              }}
            />

            <DateInput
              placeholder="End Date"
              container={`w-full ml-1`}
              status={error.start_date}
              disabled={data.current ? true : false}
              value={data.end_date}
              onSelect={(e) => {
                dataSet((prev) => ({ ...prev, end_date: e }));
                errorSet((prev) => ({ ...prev, end_date: '' }));
              }}
            />
          </div>

          <CheckBox
            title="Currently works here"
            name="current"
            id="current"
            checked={data.current}
            onChange={() => {
              dataSet((prev) => ({
                ...prev,
                current: !data.current,
                end_date: ''
              }));
            }}
          />
        </div>

        <div className="w-full flex justify-end mt-5">
          <div className="w-[40%] mx-1">
            <ButtonComponent
              title="Save"
              onHandler={async () => {
                if (!data.id) {
                  const response = await save_work_experience(dispatch, data);
                  if (response) clear_field();
                } else {
                  const response = await update_work_experience(dispatch, data);
                  if (response) clear_field();
                }
              }}
              backgroundColor="#243677"
              textColor="white"
              active={true}
              loading={loading}
            />
          </div>
          <div className="w-[40%]">
            <ButtonComponent
              title="Cancel"
              onHandler={() => {
                clear_field();
                onCancel();
              }}
              backgroundColor="red"
              textColor="white"
              active={true}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

export default UpdateExperience;
