import React from 'react';

// components
import MultiSelect from '../../../components/ui/form/MultiSelect';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import Paginate from '../../../components/ui/paginate';

// contants and icons
import avatar_user from '../../../assets/images/temporary/user.png';

// state
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	get_candidates,
	search_candidates
} from '../../../service/api/candidate';
import { add_candidate_report_order } from '../../../service/api/report';
import { nextPage, prevPage } from '../../../store/slices/candidate';
import { get_role_candidate } from '../../../service/api/report_role_candidate';
import { IGetCandidateReport } from '../../../store/types/report_role';

type IDisplayCandidate = {
	show: boolean;
	report_role_id: string;
	onClose: () => void;
};

const DisplayCandidate: React.FC<IDisplayCandidate> = ({
	show,
	report_role_id,
	onClose
}) => {
	const dispatch = useAppDispatch();
	const candidates = useAppSelector(({ candidate }) => candidate.candidates);
	const curr_page = useAppSelector(({ candidate }) => candidate.current_page);
	const last_page = useAppSelector(({ candidate }) => candidate.last_page);

	const [search, searchSet] = React.useState<string>('');
	const [selected, selectedSet] = React.useState<IGetCandidateReport[]>([]);

	const onLoad = React.useCallback(() => {
		if (search.length > 3) search_candidates(dispatch, search);
		else get_candidates(dispatch, curr_page);
	}, [curr_page, dispatch, search]);

	const onSelectedCandidate = React.useCallback(async () => {
		const response = await get_role_candidate(report_role_id);
		if (response.length) {
			selectedSet(response);
		}
	}, [report_role_id]);

	React.useEffect(() => {
		onLoad();
		onSelectedCandidate();
	}, [onLoad, onSelectedCandidate]);

	const onSubmit = (email: string) => {
		add_candidate_report_order(dispatch, {
			report_role_id,
			email,
			level: (selected.length + 1).toString()
		});
		setTimeout(() => onSelectedCandidate(), 400);
	};

	return (
		<MultiSelect
			open={show}
			search={search}
			onSubmit={onClose}
			onSearch={(e) => searchSet(e)}
			onCancel={onClose}
		>
			<>
				<div className="flex flex-1 overflow-y-scroll overflow-x-hidden flex-col">
					{candidates.map((candidate) => (
						<div
							key={candidate.email}
							className={`flex items-center  border-b border-[#243677] py-3 
								${
									selected.find((item) => item.user?.email === candidate.email)
										? 'bg-[#CDD8FE]'
										: 'bg-[white]'
								}
							`}
						>
							<div className="w-[10%] flex items-center justify-center">
								<div className="flex items-center justify-center h-[55px] w-[55px] border-[#243677] overflow-hidden rounded-full border">
									<img
										src={
											candidate?.profile && candidate?.profile?.avatar
												? candidate?.profile?.avatar
												: avatar_user
										}
										alt={candidate?.profile?.first_name}
										className="h-[53px] w-[53px] rounded-full"
									/>
								</div>
							</div>
							<div className="w-[20%] text-center">
								{`${
									candidate?.profile?.first_name
										? candidate?.profile?.first_name
										: ''
								} ${
									candidate?.profile?.last_name
										? candidate?.profile?.last_name
										: ''
								}`}
							</div>
							<div className="w-[20%] text-center">
								{candidate?.profile?.address?.about?.profession?.name}
							</div>
							<div className="w-[10%] text-center">
								{candidate?.profile?.address?.about?.years_of_experience
									? candidate?.profile?.address?.about?.years_of_experience +
									  ' years'
									: null}
							</div>

							<div className="capitalize w-[20%] text-center">
								{candidate?.profile?.address?.residential_state
									? `${candidate?.profile?.address?.residential_state?.name}, ${candidate?.profile?.address?.residential_state?.country?.name}`
									: ''}
							</div>
							<div className="capitalize flex justify-center items-center w-[15%]">
								{selected.find(
									(item) => item.user?.email === candidate?.email
								) ? (
									<ButtonComponent
										title="Added"
										onHandler={() => null}
										active={true}
										backgroundColor="red"
									/>
								) : (
									<ButtonComponent
										title="Add"
										onHandler={() => onSubmit(candidate.email)}
										active={true}
									/>
								)}
							</div>
						</div>
					))}
				</div>
				<Paginate
					current_page={curr_page}
					last_page={last_page}
					onPressNext={() => dispatch(nextPage())}
					onPressPrev={() => dispatch(prevPage())}
				/>
			</>
		</MultiSelect>
	);
};

export default DisplayCandidate;
