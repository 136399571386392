import React from 'react';

// components
import { Skeleton } from 'antd';

// constants
import { get_date } from '@/service/formatter/date';
import scheduler from '@/assets/images/temporary/schedule.svg';
import {
  MdOutlineRecycling,
  MdPowerSettingsNew,
  MdRestoreFromTrash
} from 'react-icons/md';

// state and selectors
import {
  deactivate_task,
  activate_task,
  delete_task,
  recycle_task
} from '@/service/api/task';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const Header = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ task }) => task.show);
  const task = useAppSelector(({ task }) => task.task);
  return (
    <div className="h-[20vh] bg-[#e0e7ff] flex justify-evenly items-center w-full overflow-hidden px-2 py-2">
      <div className="w-[25%] flex items-center justify-center overflow-hidden h-full p-2">
        <img
          src={scheduler}
          alt="Scheduler Reps"
          className="h-[70px] w-[70px] object-contain"
        />
      </div>

      <div className="text-[#243677] w-[25%] h-full py-3">
        <h1 className="text-[18px] mb-[2px] font-bold capitalize">
          Scheduler: {loading && <Skeleton.Button style={{ width: '70px' }} />}
          {task?.name ? task?.name : ''}
        </h1>
        <h1 className="text-[18px] mb-[2px] font-bold capitalize">
          Name: {loading && <Skeleton.Button style={{ width: '70px' }} />}
          {task?.user?.profile?.full_name ? task?.user?.profile?.full_name : ''}
        </h1>
        <h1 className="text-[18px] mb-[2px] font-bold capitalize">
          Status: {loading && <Skeleton.Button style={{ width: '70px' }} />}
          <span
            className={`text-[white] px-2 py-[2px] rounded-md ${
              task?.deleted ? 'bg-[red]' : 'bg-[#243677]'
            }`}
          >
            {task?.deleted ? 'Stopped' : 'Running'}
          </span>
        </h1>
      </div>

      <div className="text-[#243677] w-[25%] h-full py-3">
        <h1 className="text-[18px] mb-[2px] font-bold">
          Created: {loading && <Skeleton.Button style={{ width: '70px' }} />}
          {task?.created_at ? get_date(task?.created_at) : ''}
        </h1>
        <h1 className="text-[18px] mb-[2px] font-bold">
          Expire: {loading && <Skeleton.Button style={{ width: '70px' }} />}
          {task?.expiry_date ? get_date(task.expiry_date) : ''}
        </h1>
      </div>

      <div className="text-[#243677] w-[25%] h-full py-3 flex justify-end items-end">
        <MdPowerSettingsNew
          className="cursor-pointer mx-2"
          size={30}
          title={task?.deactivated === '1' ? 'De-Activate' : 'Activate'}
          color={task?.deactivated === '1' ? 'red' : 'black'}
          onClick={() => {
            if (task?.slug && task?.deactivated?.toString() === '1')
              deactivate_task(dispatch, task?.slug);
            if (task?.slug && task?.deactivated?.toString() === '2')
              activate_task(dispatch, task?.slug);
          }}
        />
        {!task?.deleted ? (
          <MdRestoreFromTrash
            className="cursor-pointer mx-2"
            size={30}
            color="red"
            onClick={() => {
              if (task?.slug) delete_task(dispatch, task.slug);
            }}
          />
        ) : (
          <MdOutlineRecycling
            className="cursor-pointer mx-2"
            size={30}
            color="black"
            onClick={() => {
              if (task?.slug) recycle_task(dispatch, task.slug);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
