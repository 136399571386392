import React from 'react';

// components
import { DatePicker } from 'antd';
// import dayjs from 'dayjs';

// state
import type { DatePickerProps } from 'antd';
import { ErrorWarning } from '../../../store/types/misc';

type IDateInput = {
  onSelect: (dateString: string) => void;
  container?: string;
  status: ErrorWarning;
  placeholder: string;
  disabled?: boolean;
  value?: string | Date | null;
};

const DateInput: React.FC<IDateInput> = ({
  container,
  onSelect,
  status,
  placeholder,
  disabled
  // value
}) => {
  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    onSelect?.(dateString);
    date?.format();
  };

  return (
    <div className={`my-5 ${container}`}>
      <DatePicker
        // defaultValue={
        //   value ? dayjs(value, 'YYYY-MM-DD') : dayjs('2015-01-01', 'YYYY-MM-DD')
        // }
        disabled={disabled || false}
        placeholder={placeholder}
        onChange={onChange}
        className={`w-full text-xl py-3 px-4 font-abel h-[60px] hover:border-[#243677] ${
          status ? 'border-[red]' : 'border'
        }`}
      />
    </div>
  );
};

export default DateInput;
