import React from 'react';

// components
import TextInput from '../../../components/elements/forms/TextInput';
import { Modal } from 'antd';

// type
import { ErrorWarning } from '../../../store/types/misc';

type ICreate = {
  name: string;
  visible: boolean;
  placeholder: string;
  value: string;
  err: ErrorWarning;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOk: () => void;
  handleCancel: () => void;
};

const Create: React.FC<ICreate> = ({
  visible,
  name,
  value,
  err,
  placeholder,
  onChange,
  handleOk,
  handleCancel
}) => {
  return (
    <Modal
      title={placeholder}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        className: 'border text-[blue] border-[blue]'
      }}
    >
      <TextInput
        name={name}
        status={err}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Modal>
  );
};

export default Create;
