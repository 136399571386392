import React from 'react';

// types
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';

// navigate

// constants and icons

// components
import Createtask from '../misc/Createtask';
import { get_tasks } from '@/service/api/task';
import { get_date } from '@/service/formatter/date';
import Status from '../misc/Status';
import Action from '../misc/Action';
import { useNavigate } from 'react-router-dom';

const TaskAutomate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tasks = useAppSelector(({ task }) => task.tasks);
  const action = useAppSelector(({ navbar }) => navbar.action);
  const [show, showSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Automated Scheduler'));
    dispatch(
      setNavButton({
        button_name: 'Create Task',
        button: true
      })
    );
  }, [dispatch]);

  const onGetUser = React.useCallback(() => {
    get_tasks(dispatch);
  }, [dispatch]);

  const onAction = React.useCallback(() => {
    if (action) showSet(true);
  }, [action]);

  React.useEffect(() => {
    onLoad();
    onAction();
    onGetUser();
  }, [onAction, onGetUser, onLoad]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-4">
      <Createtask visible={show} onDone={() => showSet(false)} />
      <div className="bg-[white] py-4">
        <div className="flex justify-center items-center bg-table-bg px-3 py-5">
          <div className="mx-1 border-r px-2 w-[15%] text-center">Task</div>
          <div className="mx-1 border-r px-2 w-[30%] text-center">
            Created By
          </div>
          <div className="mx-1 border-r px-2 w-[20%] text-center">Date</div>
          <div className="mx-1 border-r px-2 w-[10%] text-center">Status</div>
          <div className="mx-1 border-r px-2 w-[15%] text-center">
            Expiry Date
          </div>
          <div className="mx-1 px-2 w-[10%] text-center">Action</div>
        </div>

        <div>
          {tasks?.map((task) => (
            <div
              key={task.slug}
              className={`flex justify-center items-center bg-table-bg px-3 py-5 border-t-2 border-[white] ${
                task.deleted
                  ? 'bg-[#fa837a] text-[white]'
                  : task?.deactivated?.toString() === '2'
                  ? 'bg-[#D3D3D3] text-[white]'
                  : ''
              }`}
            >
              <div className="mx-1 border-r px-2 w-[15%] text-center capitalize">
                {task?.name}
              </div>
              <div className="mx-1 border-r px-2 w-[30%] text-center">
                {task?.user?.profile?.full_name || task?.user?.email}
              </div>
              <div className="mx-1 border-r px-2 w-[20%] text-center">
                {task?.created_at ? get_date(task.created_at) : ''}
              </div>
              <div className="mx-1 border-r px-2 w-[10%] text-center">
                <Status item={task} />
              </div>
              <div className="mx-1 border-r px-2 w-[15%] text-center">
                {task?.expiry_date ? get_date(task.expiry_date) : 'NOT SET'}
              </div>
              <div className="mx-1 px-2 w-[10%] text-center">
                <Action item={task} navigate={navigate} dispatch={dispatch} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaskAutomate;
