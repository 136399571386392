import React from 'react';
import {
	ICreate,
	ICreateError,
	ICreateFill
} from '../../../store/types/report';
import { store_report } from '../../../service/api/report';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';

const useCreate = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [show, showSet] = React.useState<boolean>(false);
	const [open, openSet] = React.useState<boolean>(false);
	const [fill, fillSet] = React.useState<ICreateFill>({
		category: '',
		client: '',
		job_roles: [],
		theme: ''
	});

	const [data, dataSet] = React.useState<ICreate>({
		title: '',
		category: '',
		client: '',
		created_at: '',
		job_roles: [],
		theme: ''
	});

	const [error, errorSet] = React.useState<ICreateError>({
		title: '',
		category: '',
		client: '',
		created_at: '',
		job_roles: '',
		theme: ''
	});

	const onSubmit = () => {
		let validate = false;
		if (!data.title) {
			validate = true;
			errorSet((prev) => ({ ...prev, title: 'warning' }));
		}
		if (!data.client) {
			validate = true;
			errorSet((prev) => ({ ...prev, client: 'warning' }));
		}
		if (!data.created_at) {
			validate = true;
			errorSet((prev) => ({ ...prev, created_at: 'warning' }));
		}
		if (!data.job_roles.length) {
			validate = true;
			errorSet((prev) => ({ ...prev, job_roles: 'warning' }));
		}
		if (validate) {
			return;
		}
		store_report(dispatch, data, navigate);
	};

	return {
		data,
		fill,
		error,
		show,
		open,
		openSet,
		showSet,
		dataSet,
		errorSet,
		fillSet,
		onSubmit
	};
};

export default useCreate;
