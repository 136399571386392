import React from 'react';
import { UserOutlined, DashboardFilled } from '@ant-design/icons';
import { FaFile, FaMapMarkerAlt } from 'react-icons/fa';
import { BiCartAlt } from 'react-icons/bi';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { GiTakeMyMoney, GiTwoCoins } from 'react-icons/gi';
import { FaMailBulk } from 'react-icons/fa';
import { IoIosBowtie } from 'react-icons/io';

export type ItemType = {
  label: string;
  icon: React.ReactNode;
  link: string;
  key: string | number;
  children?: ItemType[];
};

export const items: ItemType[] = [
  {
    label: 'Dashboard',
    icon: React.createElement(DashboardFilled),
    link: '/',
    key: '/'
  },
  {
    label: 'Orders/Jobs',
    icon: React.createElement(BiCartAlt),
    key: '/orders',
    link: '/orders'
  },
  {
    label: 'Candidates',
    icon: React.createElement(UserOutlined),
    key: '/candidates',
    link: '/candidates'
  },
  {
    label: 'Clients',
    icon: React.createElement(UserOutlined),
    key: '/clients',
    link: '/clients'
  },
  {
    label: 'Blue',
    icon: React.createElement(IoIosBowtie),
    key: '/blue',
    link: '/blue'
  },
  {
    label: 'Reports',
    icon: React.createElement(FaFile),
    key: '/reports',
    link: '/reports'
  },
  {
    label: 'Complains & Contact',
    icon: React.createElement(TfiHeadphoneAlt),
    key: '/complains',
    link: '/complains'
  },
  {
    label: 'Price',
    icon: React.createElement(GiTakeMyMoney),
    key: '/our-prices',
    link: '/our-prices'
  },
  {
    label: 'Misc',
    icon: React.createElement(UserOutlined),
    key: '/misc',
    link: '/misc'
  },
  {
    label: 'Schedules',
    icon: React.createElement(GiTwoCoins),
    key: '/payables',
    link: '/payables'
  },
  {
    label: 'Regions',
    icon: React.createElement(FaMapMarkerAlt),
    key: '/regions',
    link: '/regions'
  },
  {
    label: 'Broadcast {Mailer}',
    icon: React.createElement(FaMailBulk),
    key: '/mailer',
    link: '/mailer'
  }
];
