import React from 'react';

// components
import TextInput from '../../../components/elements/forms/TextInput';
import {
	ISavingSingleEmail,
	ISavingSingleEmailError
} from '../../../store/types/misc';

type IInputUpdate = {
	label: string;
	name: string;
	email: string;
	onSubmit: (data: ISavingSingleEmail) => void;
};

const InputUpdate: React.FC<IInputUpdate> = ({
	label,
	email,
	name,
	onSubmit
}) => {
	const [data, dataSet] = React.useState<ISavingSingleEmail>({
		client_slug: '',
		name: ''
	});
	const [err, errSet] = React.useState<ISavingSingleEmailError>({
		client_slug: '',
		name: ''
	});

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
		errSet(() => ({ client_slug: '', name: '' }));
	};

	const onLoad = React.useCallback(() => {
		dataSet((prev) => ({ ...prev, client_slug: email, name }));
	}, [email, name]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<>
			<label className="text-[#333333] text-sm pb-4">{label}</label>
			<TextInput
				name="name"
				value={data.name}
				status={err.name}
				placeholder={label}
				onChange={onChange}
				onBlur={() => onSubmit(data)}
			/>
		</>
	);
};

export default InputUpdate;
