import React from 'react';

// components
import MessageBody from './MessageBody';
import CustomSelect from '@/components/elements/forms/CustomSelect';
import CustomInputSearch from '@/components/elements/forms/CustomInputSearch';
import ListMail from './ListMail';
import { Spin } from 'antd';

// constants
import { RiMailSendFill } from 'react-icons/ri';
import { ISelectCandidate } from '@/store/types/mailer';
import { BiMailSend } from 'react-icons/bi';

// types and services
import {
  search_candidates,
  search_clients,
  send_mail
} from '@/service/api/mailer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { IDataType } from '@/store/types/misc';

const batch = [
  { id: '1', name: 'Candidate' },
  { id: '2', name: 'Client' }
];

const SendBulkCandidate = () => {
  const dispatch = useAppDispatch();
  const loader = useAppSelector(({ mailer }) => mailer.send_user);
  const [show, showSet] = React.useState<boolean>(false);
  const [select, selectSet] = React.useState<string>('');
  const [error, errorSet] = React.useState<'warning' | ''>('');
  const [search, searchSet] = React.useState<string>('');
  const [user, userSet] = React.useState<IDataType[]>([]);
  const [candidate, candidateSet] = React.useState<string[]>([]);
  const [data, dataSet] = React.useState<ISelectCandidate>({
    message: '',
    subject: '',
    candidates: []
  });

  return (
    <div className="cursor-pointer w-[49%] rounded-sm shadow-sm border border-[#243677] text-center">
      <div
        className="text-[15px] border py-8 px-3  flex justify-center items-center"
        onClick={() => showSet(true)}
      >
        <BiMailSend size={25} className="mr-2" />
        Send Bulk Message
        {loader && <Spin size="large" className="ml-4" />}
      </div>
      <MessageBody
        title="Send Bulk Message"
        data={data}
        visible={show}
        onMessageChange={{
          mChange: (e) => dataSet((prev) => ({ ...prev, message: e })),
          sChange: (e) =>
            dataSet((prev) => ({ ...prev, subject: e.target.value }))
        }}
        onCancel={() => {
          showSet(false);
          dataSet({ message: '', subject: '', candidates: [] });
        }}
        onDone={() => {
          showSet(false);
          if (data.message && data.subject && data.candidates.length > 0) {
            console.log();
            send_mail(dispatch, {
              ...data,
              candidates: data.candidates.map((i) => ({
                name: i.name.split(' ')[0],
                email: i.id
              }))
            });
            dataSet({ message: '', subject: '', candidates: [] });
          }
        }}
      >
        <div>
          <CustomSelect
            items={batch}
            name="batch"
            onSelect={(_name, value) => {
              selectSet(value);
              searchSet('');
              errorSet('');
            }}
            placeholder="Select Customer to search..."
            selected={select}
            status={error}
          />

          <CustomInputSearch
            container="mt-4"
            value={search}
            items={user?.filter((i) => !candidate.includes(i.id))}
            icon={<RiMailSendFill size={30} color="#243677" />}
            onChange={async (e) => {
              if (select) {
                const { value } = e.target;
                searchSet(value?.toLowerCase());
                if (select === '1') {
                  const response = await search_candidates(search);
                  if (response) {
                    const users = response.map((i) => ({
                      name: `${i?.first_name} ${i?.last_name} | ${i?.user?.email}`,
                      id: i?.user?.email
                    }));
                    userSet(users);
                  }
                } else if (select === '2') {
                  const response = await search_clients(search);
                  if (response) {
                    const users = response.map((i) => ({
                      name: `${i?.full_name} ${i?.organization_name}`,
                      id: i?.client?.email
                    }));
                    userSet(users);
                  }
                } else {
                }
              } else {
                errorSet('warning');
              }
            }}
            onSelect={(e) => {
              searchSet('');
              candidateSet([...candidate, e.id]);
              dataSet((prev) => ({
                ...prev,
                candidates: [...data.candidates, e]
              }));
            }}
            placeholder="Please Input Email"
          />
          {candidate.length ? (
            <ListMail
              data={candidate}
              onDelete={(res) => {
                candidateSet(candidate.filter((i) => i !== res));
                dataSet((prev) => ({
                  ...prev,
                  candidates: data.candidates.filter((i) => i.id !== res)
                }));
              }}
            />
          ) : (
            ''
          )}
        </div>
      </MessageBody>
    </div>
  );
};

export default SendBulkCandidate;
