import React from 'react';

// state and types
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

// react router dom
import { useParams } from 'react-router-dom';

// components
import { Col, Divider, Drawer, Modal, Row } from 'antd';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import SelectInput from '../../../components/elements/forms/SelectInput';
import { get_candidate } from '@/service/api/candidate';

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
  className?: string;
}

type IDescriptionProps = {
  show: boolean;
  candidate_number: string;
  onClose: () => void;
};

type IStatusUpdate = {
  order_id: string;
  status: string;
};

const statuses = [
  { id: '1', name: 'Success' },
  { id: '2', name: 'Failed' }
];

const DescriptionItem: React.FC<DescriptionItemProps> = ({
  title,
  content,
  className
}) => (
  <div className={`site-description-item-profile-wrapper ${className}`}>
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const DrawerOption: React.FC<IDescriptionProps> = ({
  show,
  candidate_number,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const candidate = useAppSelector(({ candidate }) => candidate.candidate);
  const [open, openSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IStatusUpdate>({
    status: '',
    order_id: ''
  });

  const onLoad = React.useCallback(() => {
    if (candidate_number) get_candidate(dispatch, candidate_number);
  }, [candidate_number, dispatch]);

  const onSetID = React.useCallback(() => {
    if (id) dataSet((prev) => ({ ...prev, order_id: id }));
  }, [id]);

  React.useEffect(() => {
    onLoad();
    onSetID();
  }, [onLoad, onSetID]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={onClose}
      open={show}
    >
      <p
        className="site-description-item-profile-p"
        style={{ marginBottom: 24 }}
      >
        User Profile
      </p>
      <p className="site-description-item-profile-p">Personal</p>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Full Name"
            content={`${candidate?.profile?.first_name}  ${candidate?.profile?.last_name}`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Position"
            className="capitalize"
            content={`${
              candidate?.profile?.address?.about?.profession?.name
                ? candidate?.profile?.address?.about?.profession?.name
                : ''
            }  ${
              candidate?.profile?.address?.about?.years_of_experience
                ? '(' +
                  candidate?.profile?.address?.about?.years_of_experience +
                  'years)'
                : ''
            }`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="State"
            content={candidate?.profile?.address?.residential_state?.name}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Country"
            content={
              candidate?.profile?.address?.residential_state?.country?.name
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Birthday" content={candidate?.profile?.dob} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Website" content="-" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="About Me"
            content={candidate?.profile?.address?.about?.about_me}
          />
        </Col>
      </Row>

      <Divider />
      <div className="w-full flex justify-center items-center">
        <div className="w-[40%] h-[full] mr-2 flex">
          <ButtonComponent
            title="View Curriculum Vitae"
            backgroundColor="#243677"
            textColor="white"
            onHandler={() => openSet(true)}
            active={true}
          />
        </div>

        <div className="w-[60%]"></div>
      </div>

      <Divider />

      <p className="site-description-item-profile-p">Contacts</p>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Email" content={candidate?.email} />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Phone Number"
            content={candidate?.profile?.phone}
          />
        </Col>
      </Row>

      <Divider />

      <Row>
        <div className="w-full flex justify-between items-end py-2">
          <div className=" w-[60%] mr-2">
            <SelectInput
              name="Status"
              label="Status"
              items={statuses}
              onResponse={(name, value) =>
                dataSet((prev) => ({ ...prev, [name]: value }))
              }
            />
          </div>

          <div className="w-[40%] ml-2">
            <ButtonComponent
              title="Next"
              backgroundColor="#243677"
              textColor="white"
              active={true}
              onHandler={() => {
                if (data) console.log(data);
                // let validate: boolean = false;
                // if (!data.email || !data.order_id) validate = true;
                // if (data.status) validate = true;
                // if (validate) return;
                // update_applicant_status(dispatch, data);
              }}
            />
          </div>
        </div>
      </Row>
      <Modal
        centered
        open={open}
        onCancel={() => openSet(false)}
        width={1000}
        bodyStyle={{ height: '85vh' }}
        footer={null}
      >
        <iframe
          id="fred"
          title={candidate?.email}
          src={candidate?.profile?.curriculum_vitae}
          height="100%"
          width="100%"
        />
      </Modal>
    </Drawer>
  );
};

export default DrawerOption;
