export const get_normal_date = (timestamp: Date) => {
  return new Date(timestamp).toString().substring(0, 21);
};

export const get_date = (timestamp: Date) => {
  return new Date(timestamp).toString().substring(0, 15);
};

export const get_normal_date_time = (timestamp: Date) => {
  return new Date(timestamp).toString().substring(4, 21);
};

type IMYSQLDATE = (date: Date) => string;
export const mysql_date: IMYSQLDATE = (date: Date) => {
  return new Date(date).toISOString().slice(0, 10).replace('T', ' ');
};

export const get_normal_date_year = (timestamp: Date) => {
  const date = new Date(timestamp);
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
};
