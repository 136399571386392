import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

type IShow = {
	show: boolean;
	onClick: () => void;
};

const Show: React.FC<IShow> = ({ show, onClick }) => {
	return show ? (
		<FaEyeSlash
			size={30}
			color="grey"
			className="cursor-pointer"
			onClick={onClick}
		/>
	) : (
		<FaEye
			size={30}
			color="grey"
			className="cursor-pointer"
			onClick={onClick}
		/>
	);
};

export default Show;
