import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  IBlueOrder,
  IBlueOrderGet,
  IBlueOrderState
} from '../types/blue_order';

// Define the initial state using that type
const initialState: IBlueOrderState = {
  orders: [],
  order: null,
  loading: false,
  current_page: 1,
  last_page: 1
};

export const blue_order = createSlice({
  name: 'blue_order',
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<IBlueOrderGet>) => {
      state.orders = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setOrder: (state, action: PayloadAction<IBlueOrder>) => {
      state.order = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setPaginate: (state, action: PayloadAction<string>) => {
      if (action.payload === 'increase')
        state.current_page = state.current_page + 1;
      else state.current_page = state.current_page - 1;
    }
  }
});

export const { setOrders, setOrder, setLoading, setPaginate } =
  blue_order.actions;

export default blue_order.reducer;
