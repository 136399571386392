import React from 'react';

type IMiniButton = {
	title: string;
	backgroundColor?: string;
	textColor?: string;
	onHandler: () => void;
};

const MiniButton: React.FC<IMiniButton> = ({
	title,
	textColor,
	backgroundColor,
	onHandler
}) => {
	const bg = backgroundColor ? `bg-[${backgroundColor}]` : '';
	const txt = textColor ? `text-[${textColor}]` : '';
	return (
		<button
			onClick={onHandler}
			className={`w-full border-none py-2 px-3 text-center ${bg + ' ' + txt}`}
		>
			{title}
		</button>
	);
};

export default MiniButton;
