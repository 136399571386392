import React from 'react';

// components
import TextInputBox from '../../../components/elements/forms/TextInputBox';
import MiniButton from '../../../components/elements/forms/MiniButton';

// state and service
import { update_noun } from '../../../service/api/report_role';
import { IRoleCandidateUpdate } from '../../../store/types/report_role';
import { useAppDispatch } from '../../../store/hooks';

type IUpdateNoun = {
	id: string;
	onRefresh: () => void;
	noun: string;
};

const UpdateNoun: React.FC<IUpdateNoun> = ({ id, noun, onRefresh }) => {
	const dispatch = useAppDispatch();
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<IRoleCandidateUpdate>({
		id: '',
		name: ''
	});

	const onLoad = React.useCallback(() => {
		if (id) {
			dataSet((prev) => ({ ...prev, id: id.toString(), name: noun }));
		}
	}, [id, noun]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);
	return (
		<div>
			{!show ? (
				<div>{data.name}</div>
			) : (
				<TextInputBox
					name="noun"
					placeholder="Add Alias/Noun"
					value={data.name}
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
						dataSet((prev) => ({ ...prev, name: e.target.value }))
					}
				/>
			)}
			<div className="w-full flex justify-end my-2">
				<div className="w-[15%]">
					<MiniButton
						title="Update"
						onHandler={() => {
							if (show) {
								// update new data
								update_noun(dispatch, data);
								setTimeout(() => onRefresh(), 400);
								showSet(false);
							} else {
								showSet(true);
							}
						}}
						backgroundColor="#CDD8FE"
						textColor="#243677"
					/>
				</div>
			</div>
		</div>
	);
};

export default UpdateNoun;
