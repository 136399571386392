import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  roles: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  roles: [],
  update: false,
  loading: false
};

export const blue_role = createSlice({
  name: 'blue_role',
  initialState,
  reducers: {
    setBlueRole: (state, action: PayloadAction<IDataType[]>) => {
      state.roles = action.payload;
    },
    createBlueRole: (state, action: PayloadAction<IDataType>) => {
      state.roles = [action.payload, ...state.roles];
    },
    updateBlueRole: (state, action: PayloadAction<IDataType>) => {
      state.roles = state.roles.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setBlueRole,
  createBlueRole,
  updateBlueRole,
  setLoading,
  setUpdate
} = blue_role.actions;

export default blue_role.reducer;
