import React from 'react';

// components
import { Spin } from 'antd';
import { Link } from 'react-router-dom';

type IButtonLink = {
	title: string;
	loading?: boolean;
	path: string;
	backgroundColor?: string;
	textColor?: string;
};

const ButtonLink: React.FC<IButtonLink> = ({
	title,
	backgroundColor,
	textColor,
	loading,
	path
}) => {
	const bg = backgroundColor ? `bg-[${backgroundColor}]` : 'bg-transparent';
	const tc = textColor ? `text-[${textColor}]` : 'text-black';

	return (
		<Link
            className={`w-[130px] text-center hover:decoration-black font-[400] font-abel py-3 rounded-md text-[15px] mx-1 ${bg + ' ' + tc}`}
            to={path} >
			{loading && <Spin className="mr-4" />}
			{title}
		</Link>
	);
};

export default ButtonLink;
