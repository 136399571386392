import React from 'react';

// components
import upload from '../../../assets/images/icons/upload.svg';
import ButtonLink from '../../../components/elements/forms/ButtonLink';

// router
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	get_client,
	get_client_total_order
} from '../../../service/api/client';
import { setNavButton, setTitle } from '../../../store/slices/navbar';

const ShowClient = () => {
	const { slug } = useParams();
	const dispatch = useAppDispatch();
	const client = useAppSelector(({ client }) => client.client);
	const total_order = useAppSelector(({ client }) => client.client_total_order);

	const onLoad = React.useCallback(() => {
		if (slug) {
			get_client(dispatch, slug);
			get_client_total_order(dispatch, slug);
		}
	}, [dispatch, slug]);

	const onSetHeader = React.useCallback(() => {
		dispatch(setTitle('Client'));
		dispatch(setNavButton({ button_name: '', button: false }));
	}, [dispatch]);

	React.useEffect(() => {
		onSetHeader();
		onLoad();
	}, [onLoad, onSetHeader]);

	return (
		<>
			<div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-6">
				<div className="w-full bg-[#e0e7ff] h-full my-6 p-8">
					<div className=" flex flex-wrap justify-start items-center w-full">
						<div className="rounded-full border-[#4E4E4E] border-[4px] w-[152px] h-[152px] text-center object-cover overflow-hidden md:mr-5">
							<img
								src={client?.profile?.avatar}
								alt={client?.profile?.organization_name}
								className="h-[100%] w-full rounded-full object-cover p-1"
							/>
						</div>
						<div className="text-[#243677] max-w-[1100px] ">
							<p className="text-lg mb-4">
								{client?.profile?.organization_name}
							</p>
							<p className="text-sm capitalize">
								{client?.profile?.state?.name
									? `Location - ${client?.profile?.state?.name}, ${client?.profile?.state?.country?.name}`
									: ''}
							</p>
						</div>
					</div>
					<div className="flex justify-between mt-5">
						<div className="border border-[#4E4E4E] h-[46.5px] w-[150px] px-2 mr-2 flex justify-center items-center cursor-pointer">
							<p className="text-[17px] capitalize">
								Status -{' '}
								<span className="text-[green]">
									{client?.profile?.client_status?.name}
								</span>
							</p>
						</div>
						<div className="flex">
							<ButtonLink
								title="Edit Client"
								path={`/update-client/${client?.client_slug}`}
								textColor="white"
								backgroundColor="#243677"
							/>
							<ButtonLink
								title="View Reports"
								path="/view-report"
								textColor="white"
								backgroundColor="#243677"
							/>
						</div>
					</div>
				</div>
				<div className="max-w-[1150px] m-auto mt-5 pb-6">
					<div className="flex justify-between flex-wrap w-full h-full text-base">
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Organization Name</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4">
									{client?.profile?.organization_name}
								</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Contact Name</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4">
									{client?.profile?.full_name}
								</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Contact Email</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4">{client?.email}</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Phone Number</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4">{client?.profile?.phone}</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Industry</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								{client?.profile?.industries.map((item, index) => (
									<p className="text-[#707070] px-4 capitalize" key={index + 1}>
										{/* {client?.profile?.address} */}
									</p>
								))}
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Total Order</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4 capitalize">{total_order}</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Office Address</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4 capitalize">
									{client?.profile?.address}
								</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Country</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4 capitalize">
									{client?.profile?.state?.country?.name}
								</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">State</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4 capitalize">
									{client?.profile?.state?.name}
								</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Staff Size</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
								<p className="text-[#707070] px-4 capitalize">
									{client?.profile?.staff_size}
								</p>
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">
								Company’s Culture (optional)
							</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center pl-4">
								{client?.profile?.cultures.map((item, index) => (
									<div className="bg-[#CDD8FE] h-[27px] mr-2" key={index + 1}>
										<p className="text-[#4E4E4E] text-[16px] px-3">
											{client?.profile?.organization_name}
										</p>
									</div>
								))}
							</div>
						</div>
						<div className="mb-4 md:px-3 w-full md:max-w-[330px]">
							<label className="text-[#A4A4A4]">Company Logo</label>
							<div className="h-[55px] bg-[#F4F6FD] w-full flex items-center cursor-pointer">
								<img src={upload} alt="upload" className="pl-4" />
								<p className="text-[#707070] pl-2 pr-4">
									Upload company’s logo
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex justify-end pb-10 pr-4">
					<ButtonLink
						title="Delete Profile"
						path="/"
						textColor="white"
						backgroundColor="red" // #E36969
					/>
				</div>
			</div>
		</>
	);
};

export default ShowClient;
