import React from 'react';

const useGenerateCandidate = () => {
	const [search, searchSet] = React.useState<string>('');
	const [show, showSet] = React.useState<boolean>(false);
	const [showCandidate, showCandiateSet] = React.useState<boolean>(false);
	const [reportRoleId, reportRoleIdSet] = React.useState<string>('');
	const [reload, reloadSet] = React.useState<boolean>(false);

	return {
		search,
		show,
		reload,
		showCandidate,
		reportRoleId,
		reportRoleIdSet,
		showCandiateSet,
		reloadSet,
		showSet,
		searchSet
	};
};

export default useGenerateCandidate;
