import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import { get_clients } from '../../../service/api/client';

type ISelectClient = {
	label: string;
	onSelect: (data: { name: string; label: string }) => void;
};

const SelectClient: React.FC<ISelectClient> = ({ label, onSelect }) => {
	const dispatch = useAppDispatch();
	const clients = useAppSelector(({ client }) => client.clients);

	const onLoad = React.useCallback(() => {
		get_clients(dispatch);
	}, [dispatch]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="overflow-y-auto">
			{clients.map((client) => (
				<div
					key={client.email}
					className="flex items-center px-10 border-b py-3"
				>
					<div className="w-[25%]">{client.profile?.organization_name}</div>
					<div className="w-[30%]">{client.profile?.full_name}</div>
					<div className="w-[30%]">{client.email}</div>
					<div className="w-[15%]">
						{client?.email !== label ? (
							<ButtonComponent
								title="Select"
								onHandler={() =>
									onSelect({
										name: client.email,
										label: client?.profile?.organization_name
									})
								}
								backgroundColor="#243677"
								active={true}
							/>
						) : (
							<ButtonComponent
								title="Select"
								onHandler={() =>
									onSelect({
										name: client?.profile?.organization_name,
										label: client.email
									})
								}
								backgroundColor="red"
								active={true}
							/>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default SelectClient;
