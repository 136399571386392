import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IEducation } from '../types/candidate';

const initialState: {
  educations: IEducation[];
  update: boolean;
  loading: boolean;
} = {
  educations: [],
  loading: false,
  update: false
};

export const education = createSlice({
  name: 'education',
  initialState,
  reducers: {
    setEducation: (state, action: PayloadAction<IEducation[]>) => {
      state.educations = action.payload;
    },
    createEducation: (state, action: PayloadAction<IEducation>) => {
      state.educations = [action.payload, ...state.educations];
    },
    updateEducation: (state, action: PayloadAction<IEducation>) => {
      state.educations = state.educations.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setEducation,
  createEducation,
  updateEducation,
  setLoading,
  setUpdate
} = education.actions;

export default education.reducer;
