import React from 'react';

// components
import TableLoader from '../../../components/loader/TableLoader';
import TableLine from '../../../components/ui/card/TableLine';
import Paginate from '../../../components/ui/paginate';

// state
import { get_contract_and_permanent_orders } from '../../../service/api/order';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { setPrevPage, setNextPage } from '../../../store/slices/order';

const ContractPermanent = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector(({ order }) => order.orders);
  const current_page = useAppSelector(({ order }) => order.current_page);
  const last_page = useAppSelector(({ order }) => order.last_page);
  const loading = useAppSelector(({ order }) => order.loading);
  const search = useAppSelector(({ navbar }) => navbar.search);

  const onLoad = React.useCallback(() => {
    get_contract_and_permanent_orders(dispatch, search, current_page);
  }, [current_page, dispatch, search]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div>
      <div className="flex justify-center items-center px-3 py-3 text-[#567BFF] border-b">
        <div className="mx-1 border-r px-2 w-[25%] text-center">Name</div>
        <div className="mx-1 border-r px-2 w-[25%] text-center">Position</div>
        <div className="px-2 border-r w-[25%] text-center">Date</div>
        <div className="mx-1 px-2 w-[25%] text-center">Action</div>
      </div>

      {loading && orders.length < 1 ? (
        <TableLoader />
      ) : (
        orders.map((item, _) => (
          <TableLine key={_ + 1} item={item} onRefresh={onLoad} />
        ))
      )}

      {orders.length ? (
        <div className="w-full my-5">
          <Paginate
            current_page={current_page}
            last_page={last_page}
            onPressPrev={() => dispatch(setPrevPage())}
            onPressNext={() => dispatch(setNextPage())}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ContractPermanent;
