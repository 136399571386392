import React from 'react';

// components
import { Modal } from 'antd';
import TextInput from '@/components/elements/forms/TextInput';
import DatePicker from '@/components/elements/forms/DatePicker';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';

// types

// constants and icons
import { GrScheduleNew } from 'react-icons/gr';

// types
import {
  ICreateScheduleError,
  ICreateScheduler
} from '@/store/types/scheduler';
import { create_task_schedulers } from '@/service/api/task';

type IChangePassword = {
  visible: boolean;
  task_slug: string;
  dispatch: Function;
  onDone: () => void;
};

const CreateSchedule: React.FC<IChangePassword> = ({
  visible,
  task_slug,
  dispatch,
  onDone
}) => {
  const [data, dataSet] = React.useState<ICreateScheduler>({
    message: '',
    task_slug: '',
    expiry_date: ''
  });

  const [err, errSet] = React.useState<ICreateScheduleError>({
    message: '',
    task_slug: '',
    expiry_date: ''
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: '' }));
  };

  const onClear = () => {
    dataSet({ message: '', task_slug: '', expiry_date: '' });
    onDone();
  };

  const onLoad = React.useCallback(() => {
    if (task_slug) dataSet((prev) => ({ ...prev, task_slug }));
  }, [task_slug]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      open={visible}
      closeIcon={false}
      footer={null}
      closable={false}
      centered={true}
    >
      <p className="my-4 font-abel font-bold text-[20px]">Create Schedule</p>

      <div className="my-4 flex">
        <div className="w-full">
          <TextInput
            type="text"
            name="message"
            onChange={onChange}
            status={err.message}
            value={data.message}
            icon={<GrScheduleNew size={20} color="#567BFF" />}
            placeholder="Enter Scheduler Name"
          />
        </div>
      </div>

      <div className="my-4 flex">
        <div className="w-full">
          <DatePicker
            onSelect={(res) =>
              dataSet((prev) => ({ ...prev, expiry_date: res }))
            }
            status={err.message}
            placeholder="Expiry Date"
          />
        </div>
      </div>

      <div className="mb-4 mt-8 w-full flex justify-between">
        <div className="w-[45%]">
          <ButtonComponent
            title="Create Task"
            onHandler={() => {
              let error: boolean = false;
              if (!data.expiry_date) {
                error = true;
                errSet((p) => ({ ...p, expiry_date: 'warning' }));
              }
              if (!data.message) {
                error = true;
                errSet((p) => ({ ...p, message: 'warning' }));
              }
              if (!data.task_slug) {
                error = true;
                errSet((p) => ({ ...p, task_slug: 'warning' }));
              }
              if (error) return;
              create_task_schedulers(dispatch, data);
              onClear();
            }}
            active={true}
          />
        </div>
        <div className="w-[45%]">
          <ButtonComponent
            title="Cancel"
            onHandler={() => onClear()}
            backgroundColor="red"
            active={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateSchedule;
