import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// state
import {
	IGetReportRoles,
	IReportRolesCandidateUpdate
} from '../../store/types/report_role';
import { setReportRoles } from '../../store/slices/report_role';
import { setToast } from '../../store/slices/message';
// import { setMessage } from '../../store/slices/message';

export const get_client_report_roles: IGetReportRoles = async (
	dispatch,
	id
) => {
	try {
		const response = await axiosInstance.get(`get-report-roles/${id}`);

		dispatch(setReportRoles(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			console.log(error?.response?.data);
		}
	}
};

// candidate
export const update_sumary: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-summary',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_noun: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-noun',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_professional_summary: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-professional-summary',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_result: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-report',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_pros: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-pros',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_cons: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-cons',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_comment: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-comment',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_expected_pay: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-expected-pay',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};

export const update_notice_period: IReportRolesCandidateUpdate = async (
	dispatch,
	formData
) => {
	try {
		const response = await axiosInstance.post(
			'/update-report-role-candidate-notice-period',
			formData
		);

		const { message } = response.data;
		dispatch(setToast({ type: 'success', message }));
	} catch (error) {
		if (isAxiosError(error)) {
			const { message } = error?.response?.data;
			dispatch(setToast({ type: 'error', message }));
		}
	}
};
