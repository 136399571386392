import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { setCourse, updateCourse, setLoading } from '@/store/slices/course';

// notification
import { openNotification } from '@/components/alerts/notify';

export const get_course = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-courses');

    dispatch(setCourse(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_course = async (dispatch: Function, data: ICreateData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/create-course', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateCourse(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type IUpdateData = { name: string; id: string };
export const update_course = async (dispatch: Function, data: IUpdateData) => {
  dispatch(setLoading());
  try {
    // const response = await axiosInstance.patch('/update-course', data);
    // dispatch(setRoles(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};
