import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

//auth and layout
import LoginComponent from '../screens/auth/login';
import PrivateRoute from './private';
import AuthRoutes from './routes';

export interface IApplicationProps {}

const MainRouter: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route  path="/login" element={<LoginComponent />} />
                <Route path="/*" element={<PrivateRoute><AuthRoutes /></PrivateRoute>} />
            </Routes>
        </BrowserRouter>
    )
}

export default MainRouter