import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  createGender,
  setGenders,
  setLoading,
  setUpdate,
  updateGender
} from '@/store/slices/gender';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_genders = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-genders');

    dispatch(setGenders(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setGenders([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_gender = async (dispatch: Function, data: ICreateData) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-gender', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createGender(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_gender = async (dispatch: Function, data: IUpdateData) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-gender', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateGender(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
