import React from 'react';
import axiosUpload from '../../../utility/axiosUpload';
import { isAxiosError } from 'axios';
import { update_avatar } from '@/service/api/client';
// import { update_avatar } from '../../../service/api/client';

const useUpload = () => {
	const [loading, loadingSet] = React.useState<boolean>(false);

	const onUploadAvatar = async (
		e: React.ChangeEvent<HTMLInputElement>,
		client_slug: string
	) => {
		loadingSet(true);
		const { files } = e.target;
		if (!files?.[0]) return loadingSet(false);

		try {
			const formData = new FormData();
			formData.append('name', files?.[0]);

			const response = await axiosUpload.post('/upload-avatar', formData);

			await update_avatar({ client_slug, name: response.data });
		} catch (error) {
			if (isAxiosError(error)) console.log(error?.response);
		}

		loadingSet(false);
	};

	return { onUploadAvatar, loading };
};

export default useUpload;
