import React from 'react';

// loader
import { Skeleton } from 'antd';

const Loading = () => {
  return (
    <div>
      {Array.from(Array(10).keys()).map((item, _) => (
        <Skeleton.Button
          active
          block
          key={_ + 1}
          className="capitalize my-[1px] mt-[2px]"
        />
      ))}
    </div>
  );
};

export default Loading;
