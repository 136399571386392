import React from 'react';

// components
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import error404 from '../../../assets/images/icons/404.svg';

const ErrorPage: React.FC = () => {
  return (
    <article className="justify-center items-center h-[80vh] flex md:py-5 font-abel">
      <div className="h-full text-center flex justify-center items-center">
        <div>
          <div className="flex justify-center">
            <img src={error404} alt="403" />
          </div>
          <h1 className="text-[160px] text-[#243677] font-[800]">403</h1>
          <div className="text-center md:text-[24px] text-[18px]">
            <p>Oops! Looks Like You've Hit a Roadblock</p>
            <p>This page is forbidden Page</p>
            <Link to="/" className="">
              <Button className="text-[#100F0F] border-[2px] border-[#000000] hover:text-[black] hover:bg-white m-auto mt-4">
                Go back home
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ErrorPage;
