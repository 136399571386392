import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IWorkExperience } from '../types/candidate';

const initialState: {
  experiences: IWorkExperience[];
  update: boolean;
  loading: boolean;
} = {
  experiences: [],
  loading: false,
  update: false
};

export const experience = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    setExperience: (state, action: PayloadAction<IWorkExperience[]>) => {
      state.experiences = action.payload;
    },
    createExperience: (state, action: PayloadAction<IWorkExperience>) => {
      state.experiences = [action.payload, ...state.experiences];
    },
    updateExperience: (state, action: PayloadAction<IWorkExperience>) => {
      state.experiences = state.experiences.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setExperience,
  createExperience,
  updateExperience,
  setLoading,
  setUpdate
} = experience.actions;

export default experience.reducer;
