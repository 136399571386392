import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setExperience,
  updateExperience,
  createExperience,
  setLoading
} from '@/store/slices/work_experience';
import { ICandidateExperienceData } from '@/store/types/candidate';

// notification
import { openNotification } from '@/components/alerts/notify';

export const get_work_experience = async (dispatch: Function, slug: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-work-experiences/${slug}`);

    dispatch(setExperience(response.data));
  } catch (err) {
    dispatch(setExperience([]));
  }
  dispatch(setLoading());
};

export const save_work_experience = async (
  dispatch: Function,
  formData: ICandidateExperienceData
) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      '/create-work-experience',
      formData
    );

    dispatch(createExperience(response.data?.data));
    openNotification({ status: 'success', message: response?.data?.message });
    result = true;
  } catch (err) {
    result = false;
    if (isAxiosError(err))
      openNotification({
        status: 'error',
        message: err?.response?.data?.message
      });
  }
  dispatch(setLoading());
  return result;
};

export const update_work_experience = async (
  dispatch: Function,
  formData: ICandidateExperienceData
) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch(
      '/update-work-experience',
      formData
    );

    dispatch(updateExperience(response.data?.data));
    openNotification({ status: 'success', message: response?.data?.message });
    result = true;
  } catch (err) {
    result = false;
    if (isAxiosError(err))
      openNotification({
        status: 'error',
        message: err?.response?.data?.message
      });
  }
  dispatch(setLoading());
  return result;
};
