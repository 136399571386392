import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType, IStatusType } from '../types/misc';
import { IPriceOrder } from '../types/order';

type IMiscPayload = {
	job_types: IDataType[];
	experties_levels: IDataType[];
	technical_skills: IDataType[];
	soft_skills: IDataType[];
	countries: IDataType[];
	states: IDataType[];
	payment_types: IDataType[];
	currencies: IDataType[];
	price_ranges: IPriceOrder[];
	applicant_status: IStatusType[];
	order_types: IDataType[];
	positions: IDataType[];
	industries: IDataType[];
	cultures: IDataType[];
	education_level: IDataType[];
};

// Define the initial state using that type
const initialState: IMiscPayload = {
	job_types: [],
	experties_levels: [],
	technical_skills: [],
	soft_skills: [],
	countries: [],
	states: [],
	payment_types: [],
	currencies: [],
	price_ranges: [],
	applicant_status: [],
	order_types: [],
	positions: [],
	industries: [],
	cultures: [],
	education_level: []
};

export const misc = createSlice({
	name: 'misc',
	initialState,
	reducers: {
		setJobTypes: (state, action: PayloadAction<IDataType[]>) => {
			state.job_types = action.payload;
		},
		setExpertises: (state, action: PayloadAction<IDataType[]>) => {
			state.experties_levels = action.payload;
		},
		setTechnical: (state, action: PayloadAction<IDataType[]>) => {
			state.technical_skills = action.payload;
		},
		setSoftSkills: (state, action: PayloadAction<IDataType[]>) => {
			state.soft_skills = action.payload;
		},
		setPriceRange: (state, action: PayloadAction<IPriceOrder[]>) => {
			state.price_ranges = action.payload;
		},
		setPaymentType: (state, action: PayloadAction<IDataType[]>) => {
			state.payment_types = action.payload;
		},
		setCurrencies: (state, action: PayloadAction<IDataType[]>) => {
			state.currencies = action.payload;
		},
		setCountries: (state, action: PayloadAction<IDataType[]>) => {
			state.countries = action.payload;
		},
		setState: (state, action: PayloadAction<IDataType[]>) => {
			state.states = action.payload;
		},
		setApplicantStatus: (state, action: PayloadAction<IStatusType[]>) => {
			state.applicant_status = action.payload;
		},
		setOrderTypes: (state, action: PayloadAction<IStatusType[]>) => {
			state.order_types = action.payload;
		},
		setPositions: (state, action: PayloadAction<IDataType[]>) => {
			state.positions = action.payload;
		},
		setCultures: (state, action: PayloadAction<IDataType[]>) => {
			state.cultures = action.payload;
		},
		setIndustries: (state, action: PayloadAction<IDataType[]>) => {
			state.industries = action.payload;
		},
		setEducationLevel: (state, action: PayloadAction<IDataType[]>) => {
			state.education_level = action.payload;
		},
		createCulture: (state, action: PayloadAction<IDataType>) => {
			state.cultures = [...state.cultures, action.payload];
		},
		createIndustry: (state, action: PayloadAction<IDataType>) => {
			state.industries = [...state.industries, action.payload];
		}
	}
});

export const {
	setJobTypes,
	setCurrencies,
	setExpertises,
	setSoftSkills,
	setTechnical,
	setPaymentType,
	setCountries,
	setState,
	setPriceRange,
	setApplicantStatus,
	setOrderTypes,
	setPositions,
	setCultures,
	setIndustries,
	createCulture,
	createIndustry,
	setEducationLevel
} = misc.actions;

export default misc.reducer;
