import React from 'react';

// constants
import { BsChevronDown } from 'react-icons/bs';

// components
import StatsClients from '../misc/StatsClients';
import TableLoader from '../../../components/loader/TableLoader';
import TableLine from '../misc/TableLine';
import Paginate from '../../../components/ui/paginate';

// state
import { get_clients, search_client } from '../../../service/api/client';
import { setNext, setPrev } from '../../../store/slices/client';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { setTitle, setNavButton } from '../../../store/slices/navbar';

// useRouter Dom
import { useNavigate } from 'react-router-dom';

const ClientDashboard = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const search = useAppSelector(({ navbar }) => navbar.search);
	const clients = useAppSelector(({ client }) => client.clients);
	const current_page = useAppSelector(({ client }) => client.current_page);
	const last_page = useAppSelector(({ client }) => client.last_page);
	const action = useAppSelector(({ navbar }) => navbar.action);
	const loading = useAppSelector(({ client }) => client.loading);

	const onLoad = React.useCallback(() => {
		if (!search.length) {
			get_clients(dispatch, current_page);
		} else {
			search_client(dispatch, search);
		}
	}, [current_page, dispatch, search]);

	const setHeader = React.useCallback(() => {
		dispatch(setTitle('Clients'));
		dispatch(setNavButton({ button_name: 'Add Client', button: true }));
	}, [dispatch]);

	const onCreate = React.useCallback(() => {
		if (action) {
			navigate('/create-client');
		}
	}, [action, navigate]);

	React.useEffect(() => {
		setHeader();
		onLoad();
		onCreate();
	}, [onLoad, setHeader, onCreate]);

	return (
		<div className=" font-[abel] font-[400]">
			<div className="my-2 w-full text-right justify-between">
				<div className="w-4/12"></div>
				<div className="w-5/12"></div>
			</div>
			<div className="h-full w-full mt-4 mb-6 px-2 text-[#232164]">
				<StatsClients />

				<div className="flex justify-between px-7">
					<p className="md:text-[25px] text-[20px]">All client</p>
					<div className="flex justify-center items-center text-[#7D8287] bg-white h-[35px] w-[110px]">
						<span className="text-[14px]">Filter by date</span>
						<BsChevronDown size={12} className="ml-2" />
					</div>
				</div>
			</div>

			<div className="flex justify-center items-center px-3 py-3 text-[#567BFF] border-b">
				<div className="mx-1 border-r px-2 w-2/12 text-center font-bold">
					Logo
				</div>
				<div className="mx-1 border-r px-2 w-3/12 text-center font-bold">
					Client Name
				</div>
				<div className="mx-1 border-r px-2 w-3/12 text-center font-bold">
					Contact Person
				</div>
				<div className="mx-1 border-r px-2 w-3/12 text-center font-bold">
					Location
				</div>
				<div className="mx-1 px-2 w-2/12 text-center font-bold">Action</div>
			</div>

			{clients.length < 1 && loading ? (
				<TableLoader />
			) : (
				clients.map((item) => (
					<TableLine
						key={item.email}
						item={item}
						onView={(slug) => navigate(`/client/` + slug)}
					/>
				))
			)}

			<Paginate
				current_page={current_page}
				last_page={last_page}
				onPressNext={() => dispatch(setNext())}
				onPressPrev={() => dispatch(setPrev())}
			/>
		</div>
	);
};

export default ClientDashboard;
