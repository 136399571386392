import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICompanyIndustryState } from '../types/company_industry';

// Define the initial state using that type
const initialState: ICompanyIndustryState = {
	total: '',
	current_page: '1',
	last_page: '1'
};

export const industry = createSlice({
	name: 'industry',
	initialState,
	reducers: {
		setClients: (state, action: PayloadAction) => {},
		setTotalIndustry: (state, action: PayloadAction<string>) => {
			state.total = action.payload;
		},
		setNext: (state) => {
			if (
				Number(state.current_page) > 0 &&
				state.current_page < state.last_page
			) {
				state.current_page = state.current_page + 1;
			}
		},
		setPrev: (state) => {
			// if (state.current_page > 1 && state.current_page <= state.last_page) {
			// 	state.current_page = state.current_page - 1;
			// }
		}
	}
});

export const { setClients, setTotalIndustry, setNext, setPrev } =
	industry.actions;

export default industry.reducer;
