import React from 'react';

// components
import RolesPermission from '../misc/RolesPermission';
import { Skeleton, Spin } from 'antd';

// types and state
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setNavButton, setTitle } from '@/store/slices/navbar';

// constants and validators
import { get_date, get_normal_date_time } from '@/service/formatter/date';
import { FaUserCog, FaUserEdit, FaMinus } from 'react-icons/fa';
import user_avatar from '../../../assets/images/icons/user.svg';

// react router
import { useParams } from 'react-router-dom';
import {
  get_rol_and_perm,
  get_user_profile,
  delete_admin_permission,
  add_admin_role,
  add_admin_permission
} from '@/service/api/user';
import ErrorPage from '@/components/ui/errorpage/403_Page';

const UserRole = () => {
  const dispatch = useAppDispatch();
  const { admin_number } = useParams();

  const user = useAppSelector(({ auth }) => auth.user);
  const rol_per = useAppSelector(({ user }) => user.admin_roles_permissions);
  const loading = useAppSelector(({ user }) => user.update);
  const admin = useAppSelector(({ user }) => user.admin);
  const update = useAppSelector(({ user }) => user.update);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(''));
    dispatch(setNavButton({ button_name: '', button: false }));
  }, [dispatch]);

  const onGetData = React.useCallback(() => {
    if (admin_number) {
      get_user_profile(dispatch, admin_number);
      get_rol_and_perm(dispatch, { admin_number });
    }
  }, [dispatch, admin_number]);

  React.useEffect(() => {
    onLoad();
    onGetData();
  }, [onGetData, onLoad]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-4">
      {user?.role_id?.toString() !== '1' ? (
        <ErrorPage />
      ) : (
        <>
          <div className="flex flex-wrap justify-between items-center w-full bg-[#e0e7ff] h-full my-6 p-8">
            <div className="w-[20%] relative flex justify-center items-center h-[152px] overflow-hidden">
              <div className="h-[150px] w-[150px] overflow-hidden border-[#567BFF] border-4 rounded-full">
                <img
                  src={admin?.profile?.avatar || user_avatar}
                  alt={admin?.profile?.full_name}
                  className="h-[100%] w-full object-cover p-1 rounded-full"
                />
              </div>
            </div>

            <div className="w-[60%] px-4">
              <p className="text-[17px] capitalize">
                {`Name: ${admin?.profile ? admin.profile?.full_name : ''}`}
              </p>

              <p className="text-[17px]">{`Email: ${admin?.email || ''}`}</p>

              <p className="text-[17px] capitalize">
                {`Department: ${
                  admin?.profile ? admin.profile?.position?.name : ''
                }`}
              </p>

              <p className="text-[17px] capitalize">
                {`Date of Birth: ${
                  admin?.profile ? get_normal_date_time(admin.profile?.dob) : ''
                }`}
              </p>

              <p className="text-[17px] capitalize">
                {`Joined Us: ${
                  admin?.created_at
                    ? get_normal_date_time(admin.created_at)
                    : ''
                }`}
              </p>
            </div>

            <div className="border-[#4E4E4E] w-[20%] flex items-end h-[152px] text-center overflow-hidden"></div>
          </div>

          <div className="flex justify-evenly my-5">
            <div className="w-[60%]">
              <div className="flex items-center">
                <h3 className="text-[18px] text-[#567BFF]">User's Access</h3>
                {loading && <Skeleton.Button className="mx-2" active={true} />}
              </div>
              <div className="px-3 py-4">
                {rol_per.map((role, index) => (
                  <div key={index + 1} className="">
                    <div className="text-[16px] font-bold capitalize flex items-center px-2">
                      <FaUserCog size={22} color="#567BFF" className="mx-2" />
                      <p className="mx-2 capitalize text-[#567BFF] font-bold py-[2px] px-2">
                        {role?.role?.name}
                      </p>
                    </div>
                    <div className="px-7 py-2">
                      {role?.permissions?.map((access, _: number) => (
                        <div
                          key={_ + 1}
                          className="text-[14px] font-bold flex items-center my-2"
                        >
                          {update ? (
                            <Spin />
                          ) : (
                            <FaUserEdit
                              size={20}
                              className="mx-2"
                              color="green"
                            />
                          )}
                          <p className="mx-2 capitalize bg-[green] text-white py-[2px] px-2">
                            {access?.permission?.name}
                          </p>
                          <p className="capitalize bg-[green] text-[white] px-2 py-[2px] border-none rounded-sm">
                            {access?.created_at
                              ? get_date(access?.created_at)
                              : ''}
                          </p>
                          <FaMinus
                            size={19}
                            color="red"
                            className="mx-2 cursor-pointer"
                            onClick={() => {
                              if (user?.role_id?.toString() === '1') {
                                delete_admin_permission(dispatch, {
                                  id: access.id?.toString()
                                });
                                if (admin_number)
                                  setTimeout(
                                    () =>
                                      get_rol_and_perm(dispatch, {
                                        admin_number
                                      }),
                                    400
                                  );
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {admin_number && (
              <RolesPermission
                admin_number={admin_number}
                onAddRole={(role_id) => {
                  if (
                    role_id &&
                    admin_number &&
                    user?.role_id?.toString() === '1'
                  ) {
                    add_admin_role(dispatch, { admin_number, role_id });
                    setTimeout(
                      () => get_rol_and_perm(dispatch, { admin_number }),
                      400
                    );
                  }
                }}
                onAddPermission={(res) => {
                  if (admin_number && user?.role_id?.toString() === '1') {
                    add_admin_permission(dispatch, res);
                    setTimeout(
                      () => get_rol_and_perm(dispatch, { admin_number }),
                      400
                    );
                  }
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserRole;
