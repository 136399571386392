import React from 'react';

// constants and icons
import { FcGraduationCap } from 'react-icons/fc';
import { CiSquarePlus } from 'react-icons/ci';

// types and state
import { IEducation } from '@/store/types/candidate';
import { get_normal_date_year } from '@/service/formatter/date';
import UpdateEducation from './UpdateEducation';
import { useAppSelector } from '@/store/hooks';

const Education: React.FC = () => {
  const educations = useAppSelector(({ education }) => education.educations);
  const [show, showset] = React.useState<boolean>(false);
  const [edu, eduset] = React.useState<IEducation | null>(null);

  return (
    <div className="w-[32%] px-1">
      <div className="text-center text-[15px] font-bold pb-5 flex items-center justify-center">
        Education{' '}
        <CiSquarePlus
          className="mx-1 cursor-pointer"
          onClick={() => showset(!show)}
          size={25}
        />
      </div>
      {educations.map((item, i) => (
        <div
          key={i + 1}
          className="bg-[#F5F5F5] px-5 py-3 rounded-md shadow-md my-2"
        >
          {item.highest === 1 ? (
            <FcGraduationCap size={25} className="absolute -top-2 right-0" />
          ) : (
            ''
          )}
          <div className="flex justify-between">
            <p className="font-bold capitalize">{item?.college?.name}</p>
            <p
              onClick={() => {
                showset(true);
                eduset(item);
              }}
              className="cursor-pointer"
            >
              Edit
            </p>
          </div>
          <p className="my-[3px] capitalize">
            {item?.major?.name + ','} {item?.degree?.name}
          </p>
          <p className="my-[3px]">
            {item?.graduation
              ? get_normal_date_year(item.graduation)
              : 'Current'}
          </p>
        </div>
      ))}
      <UpdateEducation
        title="Education"
        open={show}
        education={edu}
        onSubmit={() => {
          eduset(null);
          showset(false);
        }}
        onCancel={() => {
          eduset(null);
          showset(false);
        }}
      />
    </div>
  );
};

export default Education;
