import React from 'react';

// components
import TabHeader from '../misc/TabHeader';
import ExecutiveService from '../misc/ExecutiveService';
import ContractPermanent from '../misc/ContractPermanent';
import CooperateService from '../misc/CooperateService';
import Tab from '../../../components/ui/tabs';

// constants
import { FaUserTie, FaUser } from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';

// navigator
import { useNavigate } from 'react-router-dom';

// state
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSearch } from '@/store/slices/navbar';

const items = [
	{
		key: 1,
		label: (
			<TabHeader
				title="Permanent Contract"
				icon={<FaUser color="#243677" size={20} />}
			/>
		),
		children: <ContractPermanent />
	},
	{
		key: 2,
		label: (
			<TabHeader
				title="Executive Service"
				icon={<FaUserTie color="#243677" size={20} />}
			/>
		),
		children: <ExecutiveService />
	},
	{
		key: 3,
		label: (
			<TabHeader title="Corporate Service" icon={<FcServices size={20} />} />
		),
		children: <CooperateService />
	}
];

const OrderDashboard = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const action = useAppSelector(({ navbar }) => navbar.action);

	const onLoad = React.useCallback(() => {
		dispatch(setTitle('Order | Jobs'));
		dispatch(setNavButton({ button_name: 'Create Order', button: true }));
	}, [dispatch]);

	const onCreate = React.useCallback(() => {
		if (action) {
			navigate('/create-order');
		}
	}, [action, navigate]);

	React.useEffect(() => {
		onLoad();
		onCreate();
	}, [onCreate, onLoad]);

	return (
		<div className="w-full font-abel">
			<Tab
				items={items}
				defaultTab={1}
				onChange={() => dispatch(setSearch(''))}
			/>
		</div>
	);
};

export default OrderDashboard;
