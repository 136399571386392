import React from 'react';

// components
import LineGraph from '@/components/ui/graph/LineGraph';

// hooks
import { useAppSelector } from '@/store/hooks';

const ReportStatistics = () => {
  const statistics = useAppSelector(({ report }) => report.statistics);
  return (
    <div className="w-[60%] h-[35vh]">
      <LineGraph data={[{ data: statistics, id: 'Report' }]} />
    </div>
  );
};

export default ReportStatistics;
