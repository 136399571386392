import React from 'react';

// constants
import { BsChevronDown } from 'react-icons/bs';

// components
import StatsCandidates from '../misc/StatsCandidate';
import Paginate from '../../../components/ui/paginate';
import TableLoader from '../../../components/loader/TableLoader';
import TableLine from '../misc/TableLine';

// state
import { get_candidates } from '../../../service/api/candidate';
import { nextPage, prevPage } from '../../../store/slices/candidate';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { setTitle, setNavButton } from '../../../store/slices/navbar';

// navigator
import { useNavigate } from 'react-router-dom';

const CandidateDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const candidates = useAppSelector(({ candidate }) => candidate.candidates);
  const last_page = useAppSelector(({ candidate }) => candidate.last_page);
  const q = useAppSelector(({ navbar }) => navbar.search);
  const action = useAppSelector(({ navbar }) => navbar.action);
  const loading = useAppSelector(({ candidate }) => candidate.loading);
  const current_page = useAppSelector(
    ({ candidate }) => candidate.current_page
  );

  const onLoad = React.useCallback(() => {
    get_candidates(dispatch, current_page, q);
  }, [current_page, dispatch, q]);

  const setHeader = React.useCallback(() => {
    dispatch(setTitle('Candidates'));
    dispatch(setNavButton({ button_name: 'Add Candidate', button: true }));
  }, [dispatch]);

  const onCreate = React.useCallback(() => {
    if (action) navigate('/create-candidate');
  }, [action, navigate]);

  React.useEffect(() => {
    setHeader();
    onLoad();
    onCreate();
  }, [onLoad, setHeader, onCreate]);

  return (
    <div className=" font-[abel] font-[400]">
      <div className="my-2 w-full text-right justify-between">
        <div className="w-4/12"></div>
        <div className="w-5/12"></div>
      </div>
      <div className="h-full w-full mt-4 mb-6 px-2 text-[#232164]">
        <StatsCandidates />

        <div className="flex justify-between px-7">
          <p className="md:text-[25px] text-[20px]">All candidates</p>
          <div className="flex justify-center items-center text-[#7D8287] bg-white h-[35px] w-[110px]">
            <span className="text-[14px]">Filter by date</span>
            <BsChevronDown size={12} className="ml-2" />
          </div>
        </div>
      </div>

      <div className="flex items-center px-3 py-2 mb-3 text-[#567BFF] border-b capitalize">
        <div className="w-[15%] text-center flex justify-center items-center font-bold">
          Image
        </div>
        <div className="border-x w-[20%] text-center break-words font-bold">
          Full Name
        </div>
        <div className="border-x w-[20%] text-center break-words px-1 font-bold">
          Email
        </div>
        <div className="border-x w-[30%] text-center break-words font-bold">
          Role [YOE]
        </div>
        <div className="mx-1 px-2 w-[15%] text-center flex items-center justify-center font-bold">
          Action
        </div>
      </div>

      {candidates.length < 1 && loading ? (
        <TableLoader />
      ) : (
        candidates.map((candidate) => (
          <TableLine
            key={candidate.email.toString()}
            item={candidate}
            onView={(res) => navigate(`/view-candidate/${res}`)}
          />
        ))
      )}

      <Paginate
        current_page={current_page}
        last_page={last_page}
        onPressNext={() => dispatch(nextPage())}
        onPressPrev={() => dispatch(prevPage())}
      />
    </div>
  );
};

export default CandidateDashboard;
