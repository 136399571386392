import React from 'react';
import { Col, Divider, Drawer, Modal, Row } from 'antd';
import { IUserData } from '../../../store/types/candidate';
import constant_avatar from '../../../assets/images/constants/user.png';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

interface DescriptionItemProps {
	title: string;
	content: React.ReactNode;
}

type IDescriptionProps = {
	show: boolean;
	detail: IUserData | null;
	onClose: () => void;
};

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
	<div className="site-description-item-profile-wrapper">
		<p className="site-description-item-profile-p-label">{title}:</p>
		{content}
	</div>
);

const ChooseDrawerOption: React.FC<IDescriptionProps> = ({
	show,
	detail,
	onClose
}) => {
	const [open, openSet] = React.useState<boolean>(false);

	const avatar = detail?.profile?.avatar || constant_avatar;
	return (
		<Drawer
			width={640}
			placement="right"
			closable={false}
			onClose={onClose}
			open={show}
		>
			<p
				className="site-description-item-profile-p"
				style={{ marginBottom: 24 }}
			>
				User Profile
			</p>

			<div className="h-[80px] w-[80px] overflow-hidden border rounded-full">
				<img
					src={avatar}
					alt={detail?.profile?.first_name + 'Icon'}
					className="obeject-cover"
				/>
			</div>

			<p className="site-description-item-profile-p">Personal</p>
			<Row>
				<Col span={12}>
					<DescriptionItem
						title="Full Name"
						content={`${detail?.profile?.first_name}  ${detail?.profile?.last_name}`}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<DescriptionItem
						title="State"
						content={detail?.profile?.address?.residential_state?.name}
					/>
				</Col>
				<Col span={12}>
					<DescriptionItem
						title="Country"
						content={detail?.profile?.address?.residential_state?.country?.name}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<DescriptionItem title="Birthday" content={detail?.profile?.dob} />
				</Col>
				<Col span={12}>
					<DescriptionItem title="Website" content="-" />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<DescriptionItem
						title="About Me"
						content={detail?.profile?.address?.about?.about_me}
					/>
				</Col>
			</Row>
			<Divider />
			<p className="site-description-item-profile-p">Contacts</p>
			<Row>
				<Col span={12}>
					<DescriptionItem title="Email" content={detail?.email} />
				</Col>
				<Col span={12}>
					<DescriptionItem
						title="Phone Number"
						content={detail?.profile?.phone}
					/>
				</Col>
			</Row>
			<Modal
				centered
				open={open}
				onCancel={() => openSet(false)}
				width={1000}
				bodyStyle={{ height: '85vh' }}
				footer={null}
			>
				<iframe
					id="fred"
					title={detail?.email}
					src={detail?.profile?.curriculum_vitae}
					height="100%"
					width="100%"
				/>
			</Modal>
			<ButtonComponent
				title="View Curriculum Vitae"
				onHandler={() => openSet(true)}
			/>

			<div className="my-4 flex justify-items-end w-full">
				<ButtonComponent title="Add" onHandler={() => openSet(true)} />
			</div>
		</Drawer>
	);
};

export default ChooseDrawerOption;
