import React from 'react';

type ITabHeader = {
	title: string;
	icon?: React.ReactNode;
};

const TabHeader: React.FC<ITabHeader> = ({ title, icon }) => {
	return (
		<div className="flex items-center justify-center">
			{icon} <p className="mx-[4px]">{title}</p>
		</div>
	);
};

export default TabHeader;
