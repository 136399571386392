import React from 'react';

// state
import Gender from '../molecules/Gender';
import Communication from '../molecules/Communication';
import Experience from '../molecules/Experience';
import Qualification from '../molecules/Qualification';
import BlueRole from '../molecules/BlueRole';
import Role from '../molecules/Role';
import JobType from '../molecules/JobType';
import SoftSkill from '../molecules/SoftSkill';
import TechnicalSkill from '../molecules/TechnicalSkill';
import PaymentType from '../molecules/PaymentType';
import Currency from '../molecules/Currency';
import Industry from '../molecules/Industry';
import Culture from '../molecules/Culture';

const DashboardMisc = () => {
  return (
    <div className="flex flex-row justify-between w-full flex-wrap px-10">
      <SoftSkill />
      <TechnicalSkill />
      <JobType />
      <Currency />
      <PaymentType />
      <Experience />
      <Industry />
      <Culture />
      <Role />
      <Qualification />
      <Communication />
      <BlueRole />
      <Gender />
    </div>
  );
};

export default DashboardMisc;
