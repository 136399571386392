import React from 'react';

type ITextInput = {
	name: string;
	required?: boolean;
	placeholder: string;
	prefix?: React.ReactNode;
	className?: string;
	value: string | number | [];
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	status?: 'warning' | '';
	label?: string;
	text_class?: string;
};

const TextArea: React.FC<ITextInput> = ({
	className,
	placeholder,
	status,
	label,
	name,
	required,
	value,
	onChange,
	text_class
}) => {
	const state = status ? 'border-[red]' : 'border-[grey]';
	return (
		<div className={`h-[200px] w-full ${className}`}>
			{label && (
				<label className={`text-lg`}>
					{label} {required && <span className="text-[red] font-bold">*</span>}
				</label>
			)}
			<textarea
				name={name}
				id={name}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				className={`px-2 outline-none w-full h-full border placeholder:font-abel font-abel rounded-md overflow-hidden ${state} ${text_class}`}
			/>
		</div>
	);
};

export default TextArea;
