import { ICandidateBC, IMailerCandidate } from '@/store/types/mailer';
import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';
import { ICandidateUser } from '@/store/types/candidate';
import { ISearchClient } from '@/store/types/client';
import { setBcLoader, setMessage, setSendUser } from '@/store/slices/mailer';

// types and store

export const broadcast_candidates = async (
  dispatch: Function,
  data: IMailerCandidate
) => {
  dispatch(setBcLoader());
  let result;
  try {
    const response = await axiosInstance.post('/broadcast-all-candidate', data);

    dispatch(setMessage(response.data?.message));
  } catch (error) {
    if (isAxiosError(error)) console.log(error);
  }
  dispatch(setBcLoader());
  return result;
};

export const send_mail = async (dispatch: Function, data: ICandidateBC) => {
  dispatch(setSendUser());
  let result;
  try {
    const response = await axiosInstance.post('/broadcast-to-users', data);

    dispatch(setMessage(response.data?.message));
  } catch (error) {
    if (isAxiosError(error)) console.log(error);
  }
  dispatch(setSendUser());
  return result;
};

/** ========================================================================================================== */
/** ========================================== search ======================================================== */
/** ========================================================================================================== */
type ISearch = (search: string) => Promise<ICandidateUser[]>;
export const search_candidates: ISearch = async (search: string) => {
  try {
    const response = await axiosInstance.get(`/search-candidate?q=${search}`);

    return response.data;
  } catch (err) {
    return [];
  }
};

type ISearchClientFunc = (search: string) => Promise<ISearchClient[]>;
export const search_clients: ISearchClientFunc = async (search: string) => {
  try {
    const response = await axiosInstance.get(`/search-client?q=${search}`);

    return response.data;
  } catch (err) {
    return [];
  }
};
