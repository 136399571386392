import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';
// import { notification } from 'antd';

// state
import { setComplains, setComplain } from '../../store/slices/complain_and_contact';
import { setLoading, stopLoading } from '../../store/slices/loading';

export const get_contact_complains = async (dispatch: any, page = 1) => {
	dispatch(setLoading());
	try {
		const response = await axiosInstance.get(`/get-complains?page=${page}`);

		dispatch(setComplains(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
	dispatch(stopLoading());
};

export const get_contact_us = async (dispatch: any, id: string) => {
	try {
		const response = await axiosInstance.get(`/get-complain/${id}`);

		dispatch(setComplain(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const update_contact_us = async (dispatch: any, id: string) => {
	try {
		await axiosInstance.get(`/update-seen-complain/${id}`);
		get_contact_complains(dispatch);
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const reply_mail = async (data: any) => {
	try {
		const response = await axiosInstance.post('/reply-complain-user', data);
		return response?.data;
	} catch (err) {
		if (isAxiosError(err)) {
			return err.response?.data;
		}
	}
};
