import React from 'react';

// components
import { Modal } from 'antd';
import TextInput from '@/components/elements/forms/TextInput';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';
import Show from './Show';

// types
import {
	IUpdatePassword,
	IUpdatePasswordError,
	IUpdatePasswordVisible
} from '@/store/types/auth';

// constants and icons
import { FaLock, FaKey } from 'react-icons/fa';

// types
import { useAppDispatch } from '@/store/hooks';
import { update_password } from '@/service/api/auth';

type IChangePassword = {
	visible: boolean;
	onDone: () => void;
};

const ChangePassword: React.FC<IChangePassword> = ({ visible, onDone }) => {
	const dispatch = useAppDispatch();
	const [data, dataSet] = React.useState<IUpdatePassword>({
		password_confirmation: '',
		password: '',
		old_password: ''
	});

	const [err, errSet] = React.useState<IUpdatePasswordError>({
		new_password: '',
		old_password: '',
		password_confirmation: ''
	});

	const [show, showSet] = React.useState<IUpdatePasswordVisible>({
		new_password: false,
		old_password: false
	});

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		dataSet((prev) => ({ ...prev, [name]: value }));
		errSet((prev) => ({ ...prev, [name]: '' }));
	};

	const onSubmit = () => {
		let check: boolean = false;
		if (data.password !== data.password_confirmation) {
			check = true;
			errSet((prev) => ({ ...prev, password_confirmation: 'warning' }));
		}
		if (!data.old_password) {
			check = true;
			errSet((prev) => ({ ...prev, old_password: 'warning' }));
		}
		if (!data.password) {
			check = true;
			errSet((prev) => ({ ...prev, new_password: 'warning' }));
		}
		if (check) return;
		update_password(dispatch, data);
		onDone();
	};

	return (
		<Modal
			open={visible}
			closeIcon={false}
			footer={null}
			closable={false}
			centered={true}
		>
			<p className="my-4 font-abel font-bold text-[20px]">Change Password</p>
			<div className="my-4 flex">
				<div className="w-[90%]">
					<TextInput
						name="old_password"
						onChange={onChange}
						type={show.old_password ? 'text' : 'password'}
						value={data.old_password}
						status={err.old_password}
						icon={<FaKey size={20} color="#567BFF" />}
						placeholder="Enter Old Password"
					/>
				</div>
				<div className="w-[10%] flex justify-center items-center">
					<Show
						show={show.old_password}
						onClick={() =>
							showSet((prev) => ({
								...prev,
								old_password: !show.old_password
							}))
						}
					/>
				</div>
			</div>

			<div className="my-4 flex">
				<div className="w-[90%]">
					<TextInput
						name="password"
						onChange={onChange}
						status={err.new_password}
						value={data.password}
						type={show.new_password ? 'text' : 'password'}
						icon={<FaLock size={20} color="red" />}
						placeholder="Enter New Password"
					/>
				</div>
				<div className="w-[10%] flex justify-center items-center">
					<Show
						show={show.new_password}
						onClick={() =>
							showSet((prev) => ({
								...prev,
								new_password: !show.new_password
							}))
						}
					/>
				</div>
			</div>

			<div className="my-4">
				<TextInput
					name="password_confirmation"
					onChange={onChange}
					status={err.password_confirmation}
					type="password"
					value={data.password_confirmation}
					icon={<FaLock size={20} color="#567BFF" />}
					placeholder="Confirm New Password"
				/>
			</div>

			<div className="mb-4 mt-8 w-full flex justify-between">
				<div className="w-[45%]">
					<ButtonComponent
						title="Update Password"
						onHandler={onSubmit}
						active={true}
					/>
				</div>
				<div className="w-[45%]">
					<ButtonComponent
						title="Cancel"
						onHandler={onDone}
						backgroundColor="red"
						active={true}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ChangePassword;
