import React from 'react';

// use selector
import { useAppSelector } from '@/store/hooks';
import { get_normal_date } from '@/service/formatter/date';

const OrderDetail = () => {
  const order = useAppSelector(({ order }) => order.order);

  return (
    <>
      <div>Organization Name: {order?.user?.profile?.organization_name}</div>
      <div>Contact Name: {order?.user?.profile?.full_name}</div>
      <div>Email: {order?.user?.email}</div>
      <div>Phone: {order?.user?.profile?.phone}</div>
      <div>Address: {order?.user?.profile?.address}</div>

      <hr className="my-2" />
      <div>Job Type: {order?.job_type?.name}</div>
      <div>Expertise Level: {order?.expertise_level?.name}</div>
      <div>Duration: {order?.duration}</div>
      <div>Budget: {order?.budget}</div>
      <div>
        Date: {order?.created_at ? get_normal_date(order?.created_at) : ''}
      </div>

      <hr className="my-2" />
      <div>Price: {order?.price}</div>
      <div>Price From: {order?.price_from}</div>
      <div>Price to: {order?.price_to}</div>
      <div>State: {order?.state?.name}</div>
      <div>Currency: {order?.client_salary_range?.currency?.name}</div>
      <div>Salary Range: {order?.client_salary_range?.name}</div>

      <hr className="my-2" />
      <p className="my-2">Soft Skills:</p>

      {order?.soft_skills?.map((skill) => (
        <span className="bg-[#ff474c] text-white mx-2 my-1 px-2 py-1 rounded-[5px]">
          {skill.skill?.name}
        </span>
      ))}

      <hr className="my-2" />

      <p className="my-2">Technical Skills:</p>

      {order?.technical_skills?.map((skill) => (
        <span className="bg-[#ff474c] text-white mx-2 my-1 px-2 py-1 rounded-[5px]">
          {skill.skill?.name}
        </span>
      ))}
    </>
  );
};

export default OrderDetail;
