import React from 'react';

// import { useAppSelector } from '@/store/hooks';
import LineGraph from '@/components/ui/graph/LineGraph';
import { useAppSelector } from '@/store/hooks';

const OrderStatistic = () => {
  const statistics = useAppSelector(({ order }) => order.statistics);
  return (
    <div className="w-full h-[40vh]">
      <LineGraph data={statistics} />
    </div>
  );
};

export default OrderStatistic;
