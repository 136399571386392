import React from 'react';
import useUpload from '../hooks/useUpload';

// components
import TextInputBox from '../../../components/elements/forms/TextInputBox';
import { SweetAlert } from '../../../components/alerts';
import CreateModal from '../misc/CreateModal';
import InputUpdate from '../misc/InputUpdate';
import SelectUpdate from '../misc/SelectUpdate';

// router
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';

// state
import {
	get_client,
	update_address,
	update_contact_name,
	update_organization_name,
	update_password,
	update_phone_number,
	update_staff_size,
	update_state
} from '../../../service/api/client';
import {
	get_states,
	get_countries,
	get_cultures,
	get_industries
} from '../../../service/api/misc';

// react router
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';

const UpdateClient = () => {
	const dispatch = useAppDispatch();
	const { slug } = useParams();
	const navigate = useNavigate();
	const client = useAppSelector(({ client }) => client.client);
	const message = useAppSelector(({ message }) => message.message);
	const countries = useAppSelector(({ misc }) => misc.countries);
	const states = useAppSelector(({ misc }) => misc.states);
	const action = useAppSelector(({ navbar }) => navbar.action);
	const [show, showSet] = React.useState<boolean>(false);
	const { onUploadAvatar, loading } = useUpload();

	const onSetHeader = React.useCallback(() => {
		dispatch(setTitle('Update Client'));
		dispatch(setNavButton({ button_name: 'Change Password', button: true }));
	}, [dispatch]);

	const onLoad = React.useCallback(() => {
		if (slug) {
			get_countries(dispatch);
			get_cultures(dispatch);
			get_industries(dispatch);
			get_client(dispatch, slug);
		}
	}, [slug, dispatch]);

	// const onState = React.useCallback(() => {
	// 	if (client?.profile?.state)
	// 		get_states(dispatch, client?.profile?.state?.id);
	// }, [client?.profile?.state, dispatch]);

	const onAction = React.useCallback(() => {
		if (action) showSet(true);
	}, [action]);

	React.useEffect(() => {
		onSetHeader();
		onAction();
		onLoad();
		// onState();
	}, [onSetHeader, onAction, onLoad]);

	return (
		<div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-10 py-4">
			<div className="flex items-center mb-4">
				<div className="rounded-full border-[#4E4E4E] border-[2px] w-[132px] h-[132px] text-center flex items-center justify-center overflow-hidden md:mr-5 relative">
					{loading && <Spin size="large" className="absolute" />}
					<img
						src={client?.profile?.avatar}
						alt={client?.profile?.organization_name}
						className="h-[100%] w-full rounded-full object-cover p-1"
					/>
				</div>
				<div
					onClick={() => document.getElementById('avatar')?.click()}
					className="border border-[#4E4E4E] h-[46.5px] w-[150px] px-2 ml-2 flex justify-center items-center cursor-pointer"
				>
					<input
						hidden
						type="file"
						id="avatar"
						name="avatar"
						onChange={(e) => {
							if (client?.client_slug) onUploadAvatar(e, client?.client_slug);
						}}
					/>
					<p className="text-[17px]">Add profile picture</p>
				</div>
			</div>

			<div className="flex justify-between">
				<div className="mb-5 w-[48%]">
					{client?.client_slug && (
						<InputUpdate
							label="Company Name"
							name={client?.profile?.organization_name || ''}
							email={client?.client_slug || ''}
							onSubmit={(res) => update_organization_name(res)}
						/>
					)}
				</div>

				<div className="mb-5 w-[48%]">
					{client?.client_slug && (
						<InputUpdate
							label="Representative Name"
							name={client?.profile?.full_name || ''}
							email={client?.client_slug || ''}
							onSubmit={(res) => update_contact_name(res)}
						/>
					)}
				</div>
			</div>

			<div className="flex justify-between">
				<div className="mb-5 w-[48%]">
					{client?.email && (
						<InputUpdate
							label="Office Address"
							name={client?.profile?.address || ''}
							email={client?.client_slug || ''}
							onSubmit={(res) => update_address(res)}
						/>
					)}
				</div>

				<div className="mb-5 w-[48%]">
					{client?.email && (
						<InputUpdate
							label="Phone Number"
							name={client?.profile?.phone || ''}
							email={client?.client_slug || ''}
							onSubmit={(res) => update_phone_number(res)}
						/>
					)}
				</div>
			</div>

			<div className="flex justify-evenly">
				<div className="mb-5 w-[48%]">
					{client?.email && (
						<InputUpdate
							label="Staff Size"
							name={client?.profile?.staff_size || ''}
							email={client?.client_slug || ''}
							onSubmit={(res) => update_staff_size(res)}
						/>
					)}
				</div>
			</div>

			<div className="flex justify-between">
				<div className="mb-5 w-[48%]">
					{client?.client_slug && (
						<SelectUpdate
							label="Country"
							items={countries}
							name={client?.profile?.state?.country?.id || ''}
							email={client?.client_slug || ''}
							onSelect={(res) => get_states(dispatch, res.name)}
						/>
					)}
				</div>

				<div className="mb-5 w-[48%]">
					{client?.client_slug && (
						<SelectUpdate
							items={states}
							label="State"
							name={client?.profile?.state?.id || ''}
							email={client?.client_slug || ''}
							onSelect={(res) => {
								update_state(res);
							}}
						/>
					)}
				</div>
			</div>

			<div className="flex justify-between">
				<div className="mb-5 w-[100%]">
					<label className="text-[#333333] text-sm pb-0">
						Additional Information
					</label>
					<TextInputBox name="comment" value={''} placeholder="" />
				</div>
			</div>

			{client?.client_slug && (
				<CreateModal
					email={client?.email || ''}
					title="Change Password"
					handleOk={(res) => {
						if (res.password && res.password_confirmation) update_password(res);
						showSet(false);
					}}
					visible={show}
				/>
			)}

			<SweetAlert
				visible={message ? true : false}
				button_name="Ok"
				onSubmit={() => navigate(-1)}
			/>
		</div>
	);
};

export default UpdateClient;
