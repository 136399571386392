import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// state
import {
	setClients,
	setClient,
	setSearchUsers,
	setTotalClients,
	setAllClient,
	setClientCategories,
	setClientThemes,
	setClientTotalOrder,
	setLoading,
	// setUpdate,
	setSearch
} from '../../store/slices/client';
import { IClientPasswordChange, IUpdateFunc } from '../../store/types/client';
import { setMessage } from '../../store/slices/message';
import { ISavingSingleEmail } from '../../store/types/misc';

export const get_clients = async (dispatch: any, page: string = '1') => {
	dispatch(setLoading());
	try {
		const response = await axiosInstance.get(`/get-clients?page=${page}`);

		dispatch(setClients(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
	dispatch(setLoading());
};

export const get_client = async (dispatch: Function, slug: string) => {
	try {
		const response = await axiosInstance.get(`/get-client/${slug}`);

		dispatch(setClient(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const get_client_total_order = async (
	dispatch: Function,
	slug: string
) => {
	try {
		const response = await axiosInstance.get(`/get-client-total-order/${slug}`);
		dispatch(setClientTotalOrder(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const update_password = async (data: IClientPasswordChange) => {
	try {
		console.log(data);
		await axiosInstance.post('/update-client-password', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const update_organization_name = async (data: ISavingSingleEmail) => {
	try {
		await axiosInstance.post('/update-client-organization-name', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
			// console.log(err?.response?.data)
		}
	}
};

export const update_staff_size = async (data: ISavingSingleEmail) => {
	try {
		await axiosInstance.post('/update-client-staff-size', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const update_avatar = async (data: ISavingSingleEmail) => {
	try {
		await axiosInstance.post('/update-client-avatar', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
			// console.log(err?.response?.data);
		}
	}
};

export const update_contact_name = async (data: ISavingSingleEmail) => {
	try {
		await axiosInstance.post('/update-client-contact-name', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const update_address = async (data: ISavingSingleEmail) => {
	try {
		await axiosInstance.post('/update-client-address', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const update_phone_number = async (data: ISavingSingleEmail) => {
	try {
		await axiosInstance.post('/update-client-phone-number', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const update_state = async (data: ISavingSingleEmail) => {
	try {
		await axiosInstance.post('/update-client-state', data);
	} catch (err) {
		if (isAxiosError(err)) {
			// logger
		}
	}
};

export const search_client = async (dispatch: any, search: string) => {
	try {
		const response = await axiosInstance.get(`/search-client/${search}`);

		dispatch(setSearch(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const update_client: IUpdateFunc = async (dispatch, formData) => {
	try {
		const response = await axiosInstance.post(`/update-client`, formData);

		dispatch(
			setMessage({
				message: response?.data?.message,
				type: 'success',
				title: 'Client Updated'
			})
		);
	} catch (err) {
		if (isAxiosError(err)) {
			dispatch(
				setMessage({
					message: err?.response?.data?.message,
					type: 'error',
					title: 'Update Failed.'
				})
			);
		}
	}
};

export const get_client_categories = async (dispatch: Function) => {
	try {
		const response = await axiosInstance.get('/get-client-categories');

		dispatch(setClientCategories(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_client_themes = async (dispatch: Function) => {
	try {
		const response = await axiosInstance.get(`/get-client-themes`);

		dispatch(setClientThemes(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_all_clients = async (dispatch: Function) => {
	try {
		const response = await axiosInstance.get('/get-all-clients');

		dispatch(setAllClient(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const search_client_report = async (dispatch: any, search: string) => {
	try {
		const response = await axiosInstance.get(`/search-client/${search}`);

		dispatch(setSearchUsers(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_total_clients = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-number-of-clients');

		dispatch(setTotalClients(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};
