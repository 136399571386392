import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  genders: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  genders: [],
  update: false,
  loading: false
};

export const gender = createSlice({
  name: 'gender',
  initialState,
  reducers: {
    setGenders: (state, action: PayloadAction<IDataType[]>) => {
      state.genders = action.payload;
    },
    createGender: (state, action: PayloadAction<IDataType>) => {
      state.genders = [action.payload, ...state.genders];
    },
    updateGender: (state, action: PayloadAction<IDataType>) => {
      state.genders = state.genders.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const { setGenders, createGender, updateGender, setLoading, setUpdate } =
  gender.actions;

export default gender.reducer;
