import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setCurrencies,
  createCurrency,
  updateCurrency,
  setLoading,
  setUpdate
} from '@/store/slices/currency';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_currencies = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-currencies');

    dispatch(setCurrencies(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setCurrencies([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_currency = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-currency', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createCurrency(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_currency = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-currency', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateCurrency(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
