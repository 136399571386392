import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IGetRole, IReportRoleState } from '../types/report_role';

// Define the initial state using that type
const initialState: IReportRoleState = {
	roles: []
};

export const report_roles = createSlice({
	name: 'report',
	initialState,
	reducers: {
		setReportRoles: (state, action: PayloadAction<IGetRole[]>) => {
			state.roles = action.payload;
		}
	}
});

export const { setReportRoles } = report_roles.actions;

export default report_roles.reducer;
