import React from 'react';

// constants and icons
import { get_normal_date_time } from '../../../service/formatter/date';

// types
import { ICOMPLAIN } from '../../../store/types/complains';

// constants
import { FaEye } from 'react-icons/fa';
import { BsHourglassSplit } from 'react-icons/bs';
import { GrCompliance } from 'react-icons/gr';
import { FcCancel } from 'react-icons/fc';

type ITableLine = {
  key?: number | string;
  item: ICOMPLAIN;
  onView: (data: ICOMPLAIN) => void;
};

const TableLine: React.FC<ITableLine> = ({ item, onView }) => {
  const date = get_normal_date_time(item.created_at);

  return (
    <div
      className={`flex justify-center items-center ${
        Number(item.seen) === 0 ? 'bg-[#FFFFFF]' : 'bg-[#F7F8FE]'
      }  px-3 py-2 mb-3 border-b border-[#BCBCBC] w-full`}
    >
      <div className="border-r px-1 w-1/12 text-center">
        <input
          type="checkbox"
          onChange={() => null}
          checked={Number(item.seen) ? true : false}
          className="border border-[#243677] accent-[#243677]"
        />
      </div>
      <div className="mx-1 border-r px-2 w-4/12 text-center">
        {item.first_name} {item.last_name}
      </div>
      <div className="mx-1 border-r px-2 w-4/12 text-center">{item.email}</div>
      <div className="mx-1 px-2 w-2/12 text-center">{date}</div>
      <div className="mx-1 px-2 w-1/12 text-center flex items-center justify-between">
        {Number(item.status) === 0 ? (
          <BsHourglassSplit color="#f4b304" size={20} />
        ) : Number(item.status) === 1 ? (
          <GrCompliance size={20} />
        ) : (
          <FcCancel size={20} />
        )}
        <FaEye
          size={20}
          color="#243677"
          className="cursor-pointer"
          onClick={() => onView(item)}
        />
      </div>
    </div>
  );
};

export default TableLine;
