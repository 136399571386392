import { IProfile } from './auth';
import { IDataType, ILoadingState, IPaginateState } from './misc';

export type IAdminUsers = {
  current_page: string;
  last_page: string;
  data: IProfile[];
};

export type IRolePermission = IDataType & { slug: string };
export type IAdminRole = {
  id: string;
  role: {
    id: string;
    name: string;
    slug: string;
  };
};

export type IPermissionType = {
  id: string;
  admin_roles_id: string;
  permission_id: string;
  created_at: string;
  updated_at: string;
  permission: {
    id: string;
    name: string;
    slug: string;
  };
};

export type IAdminUserState = {
  admins: IProfile[];
  admin: IProfile | null;
  profile: IProfile | null;
  roles: IRolePermission[];
  admin_roles: IAdminRole[];
  permission: IRolePermission[];
  admin_permission: IPermissionType[];
  admin_roles_permissions: IGetRolePermission[];
} & ILoadingState &
  IPaginateState;

// export const IRolePermission = {};

export type ISetUserRole = {
  show: boolean;
  user: IProfile | null;
};

export type ISetRole = {
  role_id: string;
  client_slug: string;
};

export type ISetAdminRole = {
  role_id: string;
  admin_number: string;
};

export type ISetRolePermission = {
  admin_role_id: string;
  permission_id: string;
};

export type IDeleteRole = {
  id: string;
};

export enum IPaginate {
  'increase',
  'decrease'
}

export type IGetRolePermission = {
  id: string;
  role: IRole;
  permissions: IPermissions[];
}[];

export type IRole = {
  id: 2;
  name: string;
  slug: string;
};

export type IPermission = {
  id: string;
  name: string;
  slug: string;
};

type IPermissions = {
  id: 2;
  updated_at: Date;
  permission: {
    id: string;
    name: string;
    slug: string;
  };
};

// functions
export type RolePermission = (
  dispatch: Function,
  data: { admin_number: string }
) => void;
