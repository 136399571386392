import React from 'react';

// icons
import { FaUserEdit } from 'react-icons/fa';

type IEditInput = {
	title: string;
	name: string;
	placeholder: string;
	onUpdate: (data: string) => void;
};
const EditInput: React.FC<IEditInput> = ({
	name,
	title,
	placeholder,
	onUpdate
}) => {
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<string>('');

	const onLoad = React.useCallback(() => {
		dataSet(name);
	}, [name]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="flex items-center">
			{show ? (
				<div className="flex">
					{title}:
					<input
						value={data}
						name={name}
						placeholder={placeholder}
						className="mx-3 placeholder:text-[18px] text-[16px] outline-none"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							dataSet(e.target.value)
						}
						onBlur={() => {
							onUpdate(data);
							showSet(false);
						}}
					/>
				</div>
			) : (
				<div className="flex items-center">
					<p className="text-lg mb-1 capitalize">
						{title}: {data}
					</p>
					<div className="mx-3">
						<FaUserEdit
							size={25}
							color="#567BFF"
							onClick={() => showSet(true)}
							className="cursor-pointer"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default EditInput;
