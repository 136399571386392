import { IDataType } from '@/store/types/misc';
import React from 'react';

// constanst and icon
import { MdOutlineModeEditOutline } from 'react-icons/md';

type IItem = {
  item: IDataType;
  onEdit: (data: IDataType) => void;
};

const Items: React.FC<IItem> = ({ item, onEdit }) => {
  return (
    <div className="flex w-full justify-between capitalize my-3 border-b pb-3">
      {item.name}{' '}
      <MdOutlineModeEditOutline
        className="cursor-pointer"
        onClick={() => onEdit(item)}
      />
    </div>
  );
};

export default Items;
