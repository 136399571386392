import React from 'react';

// constants and config
import { BsChevronDown } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import { get_normal_date_time } from '../../../service/formatter/date';

// components
import { Switch } from 'antd';
import ClearLink from '../../../components/ui/link/ClearLink';
import Paginate from '../../../components/ui/paginate';

// constants
import { update_report_status } from '../../../service/api/report';
import { setNext, setPrev } from '../../../store/slices/report';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const ReportOrder = () => {
	const dispatch = useAppDispatch();
	const current_page = useAppSelector(({ report }) => report.current_page);
	const last_page = useAppSelector(({ report }) => report.last_page);
	const reports = useAppSelector(({ report }) => report.reports);
	const update_loading = useAppSelector(({ report }) => report.update_loading);

	return (
		<div className="text-[#232164] mt-5">
			<div className="flex justify-between">
				<p className="md:text-[30px] text-[18px]">Recent report</p>
				<div className="flex justify-center items-center text-[#7D8287] bg-white h-[35px] w-[110px]">
					<span className="text-[14px]">Filter by date</span>
					<BsChevronDown size={12} className="ml-2" />
				</div>
			</div>

			<div className="h-full w-full bg-white py-4 text-[#232164]">
				<div className="flex justify-center items-center px-3 py-3 mb-2 text-[#567BFF] border-b">
					<div className="mx-1 border-r px-2 w-[30%] text-center font-bold">
						Position/Role
					</div>
					<div className="mx-1 border-r px-2 w-[20%] text-center font-bold">
						Client
					</div>
					<div className="mx-1 border-r px-2 w-[20%] text-center font-bold">
						Created
					</div>
					<div className="mx-1 border-r px-2 w-[10%] text-center font-bold">
						Visible
					</div>
					<div className="mx-1 px-2 w-[20%] text-center font-bold">Action</div>
				</div>
				{reports.map((report) => (
					<div
						key={report.id}
						className="flex justify-center items-center px-3 py-2 mb-3 border-b border-[#BCBCBC]"
					>
						<div className="mx-1 border-r px-2 w-[30%] text-center capitalize">
							{report?.title}
						</div>
						<div className="mx-1 border-r px-2 w-[20%] text-center capitalize">
							{report?.client?.profile?.organization_name}
						</div>
						<div className="mx-1 border-r px-2 w-[20%] text-center flex items-center justify-center">
							{get_normal_date_time(report?.created_at)}
						</div>
						<div className="mx-1 border-r px-2 w-[10%] text-center flex items-center justify-center">
							<Switch
								checked={report?.status === '2' ? true : false}
								loading={update_loading}
								onChange={() => update_report_status(dispatch, report.id)}
							/>
						</div>
						<div className="mx-1 px-2 w-[20%] text-center flex items-center justify-center">
							<ClearLink path={`/add-candidate-report/${report?.id}`}>
								<span className="underline text-[#319759] cursor-pointer mx-3">
									Edit
								</span>
							</ClearLink>
							<FaTrashAlt
								size={17}
								color="#B10E0E"
								className="cursor-pointer"
							/>
						</div>
					</div>
				))}

				{reports.length ? (
					<Paginate
						current_page={current_page}
						last_page={last_page}
						onPressNext={() => dispatch(setNext())}
						onPressPrev={() => dispatch(setPrev())}
					/>
				) : null}
			</div>
		</div>
	);
};

export default ReportOrder;
