import React from 'react';

// components
import SelectInput from '../../../components/elements/forms/SelectInput';
import AddPriceRange from '../misc/AddPriceRange';
import Currency from '../misc/Currency';

// state
import {
	get_currencies,
	get_price_range,
	get_price_range_data
} from '../../../service/api/misc';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '@/store/slices/navbar';

const PriceRange: React.FC = () => {
	const dispatch = useAppDispatch();
	const currencies = useAppSelector(({ misc }) => misc.currencies);
	const price_ranges = useAppSelector(({ misc }) => misc.price_ranges);
	const [modal, modalSet] = React.useState<boolean>(false);
	const [currency, setCurrency] = React.useState<string>('');
	const action = useAppSelector(({ navbar }) => navbar.action);

	const onLoad = React.useCallback(() => {
		if (currency) {
			get_price_range_data(dispatch, currency);
		} else {
			get_price_range(dispatch);
		}
		get_currencies(dispatch);
	}, [currency, dispatch]);

	const onTitle = React.useCallback(() => {
		dispatch(setTitle('Order Price'));
		dispatch(setNavButton({ button_name: 'Create Price', button: true }));
	}, [dispatch]);

	const onAction = React.useCallback(() => {
		if (action) modalSet(true);
	}, [action]);

	React.useEffect(() => {
		onTitle();
		onLoad();
		onAction();
	}, [onLoad, onTitle, onAction]);

	return (
		<div className="px-10">
			<div className="w-full flex items-center justify-end">
				<div className="w-[30%]">
					<SelectInput
						name="currency"
						selectedValue={currency}
						onResponse={(_, value) => setCurrency(value)}
						items={currencies}
					/>
				</div>
			</div>
			<div className="h-[80vh] overflow-y-auto w-full my-5">
				{price_ranges.map((item) => (
					<Currency item={item} key={item.id.toString()} />
				))}
			</div>
			<AddPriceRange visible={modal} handleOk={() => modalSet(false)} />
		</div>
	);
};

export default PriceRange;
