import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  industries: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  industries: [],
  update: false,
  loading: false
};

export const industry = createSlice({
  name: 'industry',
  initialState,
  reducers: {
    setIndustries: (state, action: PayloadAction<IDataType[]>) => {
      state.industries = action.payload;
    },
    createIndusry: (state, action: PayloadAction<IDataType>) => {
      state.industries = [action.payload, ...state.industries];
    },
    updateIndusry: (state, action: PayloadAction<IDataType>) => {
      state.industries = state.industries.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setIndustries,
  createIndusry,
  updateIndusry,
  setLoading,
  setUpdate
} = industry.actions;

export default industry.reducer;
