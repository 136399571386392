import React from 'react';

type ICertification = {};

const Certification: React.FC<ICertification> = () => {
  return (
    <div className="w-[32%]">
      <h3 className="text-center text-[15px] font-bold pb-5">Certification</h3>
    </div>
  );
};

export default Certification;
