import React from 'react';

// components
import ButtonComponent from './ButtonComponent';

type IPaginate = {
	current_page: string;
	last_page: string;
	onPressPrev: () => void;
	onPressNext: () => void;
};

const Paginate: React.FC<IPaginate> = ({
	current_page,
	last_page,
	onPressPrev,
	onPressNext
}) => {
	return (
		<div className="flex relative items-center justify-center mb-2 mt-2 py-2">
			<div className="flex w-full justify-center">
				<div className="w-[130px] mr-1">
					<ButtonComponent
						title="Previous Page"
						onHandler={() => {
							if (
								Number(current_page) > 1 &&
								Number(current_page) <= Number(last_page)
							) {
								onPressPrev();
							}
						}}
						textColor="white"
						backgroundColor={Number(current_page) === 1 ? '#CDD8FE' : '#243677'}
					/>
				</div>

				<div className="w-[130px] ml-1">
					<ButtonComponent
						title="Next Page"
						onHandler={() => {
							if (Number(current_page) < Number(last_page)) {
								onPressNext();
							}
						}}
						textColor="white"
						backgroundColor={current_page === last_page ? '#CDD8FE' : '#243677'}
					/>
				</div>
			</div>

			<div className="flex absolute right-0">
				<span className="text-[20px] mx-4">
					{current_page} | {last_page}
				</span>
			</div>
		</div>
	);
};

export default Paginate;
