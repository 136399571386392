import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// types and store
import {
  setAdmin,
  setAdminPermissionRole,
  setAdmins,
  setPermissions,
  setRoles,
  setAdminRole,
  setSearch,
  setAddNewRole,
  // setLoading,
  // setShow,
  setUpdate,
  setDelete,
  setAdminPermission,
  setAddPermission
} from '@/store/slices/user';
import { openNotification } from '@/components/alerts/notify';
import {
  IDeleteRole,
  ISetAdminRole,
  ISetRolePermission,
  RolePermission
} from '@/store/types/user';

export const get_admins = async (dispatch: Function, page: string = '1') => {
  try {
    const response = await axiosInstance.get(`/get-all-admin?page=${page}`);

    dispatch(setAdmins(response.data));
  } catch (error) {
    if (isAxiosError(error)) console.log(error);
  }
};

export const search_admins = async (
  dispatch: Function,
  search: string,
  page: string = '1'
) => {
  try {
    const response = await axiosInstance.get(
      `/search-admin/${search}?page=${page}`
    );

    dispatch(setSearch(response.data));
  } catch (error) {}
};

type ICreateUser = { email: string };
export const create_admin = async (dispatch: Function, data: ICreateUser) => {
  dispatch(setUpdate);
  try {
    const response = await axiosInstance.post('/create-user', data);

    const { message } = response.data;
    openNotification({ message, status: 'success' });
  } catch (error) {
    if (isAxiosError(error)) {
      const { message } = error?.response?.data;
      openNotification({ message, status: 'error' });
    }
  }
  dispatch(setUpdate);
};

export const get_roles = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('get-roles');

    dispatch(setRoles(response.data));
  } catch (err) {}
};

export const get_permissions = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('get-permissions');

    dispatch(setPermissions(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err);
  }
};

export const get_my_roles = async (
  dispatch: Function,
  data: { admin_number: string }
) => {
  try {
    const response = await axiosInstance.post('get-user-roles', data);

    dispatch(setAdminRole(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error?.response?.data);
    }
  }
};

export const get_my_permission = async (
  dispatch: Function,
  data: { id: string }
) => {
  try {
    const response = await axiosInstance.post('get-my-role-permissions', data);

    dispatch(setAdminPermission(response.data));
  } catch (error) {
    if (isAxiosError(error)) console.log(error);
  }
};

export const add_admin_role = async (
  dispatch: Function,
  data: ISetAdminRole
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('add-admin-role', data);

    openNotification({ status: 'success', message: response?.data?.message });
    dispatch(setAddNewRole(response.data));
  } catch (err) {
    if (isAxiosError(err))
      openNotification({
        status: 'error',
        message: 'This user has been added already'
      });
  }
  dispatch(setUpdate());
};

export const add_admin_permission = async (
  dispatch: Function,
  data: ISetRolePermission
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('add-permission-to-role', data);

    openNotification({ status: 'success', message: response?.data?.message });
    dispatch(setAddPermission(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'error',
        message: 'This user has been added already'
      });
    }
  }
  dispatch(setUpdate());
};

export const delete_admin_permission = async (
  dispatch: Function,
  data: IDeleteRole
) => {
  dispatch(setDelete());
  try {
    const response = await axiosInstance.post('delete-admin-permission', data);

    openNotification({ status: 'success', message: response?.data?.message });
    // dispatch(setDeletePermission(data?.id));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'error',
        message: 'This user has been added already'
      });
    }
  }
  dispatch(setDelete());
};

export const get_rol_and_perm: RolePermission = async (dispatch, data) => {
  try {
    const response = await axiosInstance.post(
      '/get-user-roles-and-permission',
      data
    );

    dispatch(setAdminPermissionRole(response.data));
  } catch (error) {
    if (isAxiosError(error)) console.log(error?.response);
  }
};

export const get_user_profile = async (
  dispatch: Function,
  admin_number: string
) => {
  try {
    const response = await axiosInstance.get(`get-admin/${admin_number}`);

    dispatch(setAdmin(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err);
  }
};
