import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  colleges: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  colleges: [],
  update: false,
  loading: false
};

export const college = createSlice({
  name: 'college',
  initialState,
  reducers: {
    setCollege: (state, action: PayloadAction<[]>) => {
      state.colleges = action.payload;
    },
    updateCollege: (state, action: PayloadAction<IDataType>) => {
      state.colleges = [...state.colleges, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const { setCollege, updateCollege, setLoading, setUpdate } =
  college.actions;

export default college.reducer;
