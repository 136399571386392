import React from 'react';

// components
import StatsOverview from '../misc/StatsOverview';
import OrderStatistic from '../misc/OrderStatistic';
import ReportStatistics from '../misc/ReportStatistics';
import CandidateStatistic from '../misc/CandidateStatistic';
import CandidateProfileStatistic from '../misc/CandidateProfileStatistic';

// hooks
import { get_reports, report_stats } from '@/service/api/report';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import {
  candidate_stat,
  candidate_weekly_stat,
  profile_stats
} from '@/service/api/candidate';
import { order_stats } from '@/service/api/order';

// navigation
import { useNavigate } from 'react-router-dom';
import CandidateWeeklyStatistic from '../misc/CandidateWeeklyStatistic';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const action = useAppSelector(({ navbar }) => navbar.action);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Dashboard'));
    dispatch(setNavButton({ button_name: 'Create Report', button: true }));
  }, [dispatch]);

  const onGetData = React.useCallback(() => {
    get_reports(dispatch, '1');
    report_stats(dispatch);
    candidate_stat(dispatch);
    candidate_weekly_stat(dispatch);
    profile_stats(dispatch);
    order_stats(dispatch);
  }, [dispatch]);

  const onSetButtonAction = React.useCallback(() => {
    if (action) {
      navigate('/reports');
    }
  }, [action, navigate]);

  React.useEffect(() => {
    onGetData();
    onLoad();
    onSetButtonAction();
  }, [onGetData, onLoad, onSetButtonAction]);
  return (
    <div className="w-full font-abel md:px-10 px-4">
      <StatsOverview />
      <div className="flex w-full overflow-hidden flex-wrap">
        <ReportStatistics />
        <CandidateProfileStatistic />

        <CandidateWeeklyStatistic />
        <CandidateStatistic />
        <OrderStatistic />
      </div>
    </div>
  );
};

export default Dashboard;
