import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

type ISelectClient = {
  label: string;
  onSelect: (data: { name: string; label: string }) => void;
};

const SelectClient: React.FC<ISelectClient> = ({ label, onSelect }) => {
  const clients = useAppSelector(({ client }) => client.clients);

  return (
    <div className="overflow-y-auto">
      {clients.map((client) => (
        <div
          key={client.email}
          className="flex items-center px-10 border-b py-3"
        >
          <div className="w-[25%]">{client.profile?.organization_name}</div>
          <div className="w-[30%]">{client.profile?.full_name}</div>
          <div className="w-[30%]">{client.email}</div>
          <div className="w-[15%]">
            {client.email === label ? (
              <ButtonComponent
                title="Selected"
                onHandler={() =>
                  onSelect({
                    name: client?.profile?.organization_name,
                    label: client.client_slug
                  })
                }
                active={true}
                backgroundColor="red"
              />
            ) : (
              <ButtonComponent
                title="Select"
                onHandler={() =>
                  onSelect({
                    name: client.client_slug,
                    label: client?.profile?.organization_name
                  })
                }
                active={true}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectClient;
