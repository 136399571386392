import React from 'react';

export type ICheckBox = {
	name: string;
	id: string;
	onChange: (value: string) => void;
	checked?: boolean;
};

const TabPicker: React.FC<ICheckBox> = ({ id, name, checked, onChange }) => {
	return (
		<div
			onClick={() => onChange?.(id)}
			className={`px-3 py-2 cursor-pointer rounded-sm border border-[#243677] font-abel m-2 hover:bg-[#CDD8FE] capitalize ${
				checked
					? 'text-[white] bg-[#243677]'
					: 'text-[#4E4E4E] border-[#4E4E4E]'
			}`}
		>
			{name}
		</div>
	);
};

export default TabPicker;
