import React from 'react';

type IShortDesc = {
	title: String;
	name: string;
	onSave: (data: string) => void;
};

const ShortDescription: React.FC<IShortDesc> = ({ name, title, onSave }) => {
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<string>('');

	const onLoad = React.useCallback(() => {
		dataSet(name);
	}, [name]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="bg-[#F4F6FD] text-[#243677] px-2 py-3 text-[10px] rounded-sm cursor-pointer">
			{!show ? (
				<div onClick={() => showSet(true)}>
					<span>{title}</span>
					<span className="font-bold">{data}</span>
				</div>
			) : (
				<input
					value={data}
					className="outline-none"
					onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
						dataSet(target.value)
					}
					onBlur={() => {
						showSet(false);
						onSave(data);
					}}
				/>
			)}
		</div>
	);
};

export default ShortDescription;
