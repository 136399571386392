import React from 'react';

// components
import TableLoader from '@/components/loader/TableLoader';

// types and constants
import Paginate from '@/components/ui/paginate';
import { get_normal_date_time } from '@/service/formatter/date';

// state
import { setNextCorporate, setPrevCorporate } from '@/store/slices/order';
import { get_corporate_orders } from '@/service/api/order';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';

const CooperateService = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orders = useAppSelector(({ order }) => order.corporate_orders);
  const last_page = useAppSelector(({ order }) => order.corporate_last_page);
  const loading = useAppSelector(({ order }) => order.loading);
  const current_page = useAppSelector(
    ({ order }) => order.corporate_current_page
  );

  const onLoad = React.useCallback(() => {
    get_corporate_orders(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div>
      <div className="flex justify-center items-center px-3 py-3 text-[#567BFF] border-b">
        <div className="mx-1 border-r px-2 w-[25%] text-center">
          Company Name
        </div>
        <div className="mx-1 border-r px-2 w-[25%] text-center">Name</div>
        <div className="px-2 border-r w-[25%] text-center">Date</div>
        <div className="mx-1 px-2 w-[25%] text-center">Action</div>
      </div>

      {loading && orders.length < 1 ? (
        <TableLoader />
      ) : (
        orders.map((item) => (
          <div
            className={`flex justify-center items-center px-3 py-2 mb-3 border-b border-[#BCBCBC] ${
              item.seen?.toString() === '1' ? 'bg-[#F9FAFF]' : 'bg-[white]'
            }`}
            key={item?.id}
          >
            <div className="mx-1 border-r px-2 w-[25%] text-center capitalize">
              {item?.company?.profile?.organization_name}
            </div>
            <div className="mx-1 border-r px-2 w-[25%] text-center capitalize">
              {item?.company?.profile?.full_name}
            </div>
            <div className="px-2 border-r w-[25%] text-center">
              {get_normal_date_time(item?.created_at)}
            </div>
            <div className="mx-1 px-2 w-[25%] text-center flex items-center justify-center">
              {orders.length && loading ? (
                <Skeleton.Button
                  size="small"
                  active={true}
                  style={{ width: '100%' }}
                  block={true}
                />
              ) : (
                <>
                  <span
                    className="underline text-[#319759] cursor-pointer mx-2"
                    onClick={() => navigate(`/corporate-service/${item.id}`)}
                  >
                    View
                  </span>
                  <FaTrashAlt
                    size={17}
                    color="#B10E0E"
                    className="cursor-pointer mx-2"
                  />
                </>
              )}
            </div>
          </div>
        ))
      )}

      <div className="w-full my-5">
        <Paginate
          current_page={current_page}
          last_page={last_page}
          onPressNext={() => dispatch(setNextCorporate())}
          onPressPrev={() => dispatch(setPrevCorporate())}
        />
      </div>
    </div>
  );
};

export default CooperateService;
