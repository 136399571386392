import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// state and dispatch
import {
  createTask,
  deleteTask,
  recycleTask,
  setShow,
  setTask,
  setTasks
} from '../../store/slices/task';
import { ICreateTask } from '@/store/types/task';
import { notify, openNotification } from '@/components/alerts/notify';
import {
  activateSchedule,
  createSchedler,
  deactivateSchedule,
  setSchedulers
} from '@/store/slices/schedule';
import { ICreateScheduler } from '@/store/types/scheduler';

export const get_tasks = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('/get-tasks');

    dispatch(setTasks(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_task = async (dispatch: Function, slug: string) => {
  dispatch(setShow());
  try {
    const response = await axiosInstance.get(`/get-task/${slug}`);

    dispatch(setTask(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setShow());
};

export const create_task = async (dispatch: Function, data: ICreateTask) => {
  try {
    const response = await axiosInstance.post('/create-task', data);

    const { data: task, message } = response?.data;
    dispatch(createTask(task));
    notify({ description: 'New Scheduler has been created.', message });
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        status: 'error',
        message
      });
    }
  }
};

export const activate_task = async (dispatch: Function, slug: string) => {
  try {
    const response = await axiosInstance.patch(`/activate-task/${slug}`);

    const { data: task, message } = response?.data;
    dispatch(createTask(task));
    notify({ description: 'Task has been activated.', message });
    get_task(dispatch, slug);
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        status: 'error',
        message
      });
    }
  }
};

export const deactivate_task = async (dispatch: Function, slug: string) => {
  try {
    const response = await axiosInstance.patch(`/deactivate-task/${slug}`);

    const { data: task, message } = response?.data;
    dispatch(createTask(task));
    notify({ description: 'Task has been deactivated.', message });
    get_task(dispatch, slug);
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        status: 'error',
        message
      });
    }
  }
};

export const delete_task = async (dispatch: Function, slug: string) => {
  try {
    const response = await axiosInstance.delete(`/delete-task/${slug}`);

    const { message, data } = response?.data;
    dispatch(deleteTask(data));
    notify({ description: 'Scheduler has been deactivated.', message });
    get_task(dispatch, slug);
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        status: 'error',
        message
      });
    }
  }
};

export const recycle_task = async (dispatch: Function, slug: string) => {
  try {
    const response = await axiosInstance.delete(`/recycle-task/${slug}`);

    const { data, message } = response?.data;
    dispatch(recycleTask(data));
    notify({ description: 'Scheduler has been Re-activated.', message });
    get_task(dispatch, slug);
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        status: 'error',
        message
      });
    }
  }
};

/****
 * *******************************************************************************************************************
 * *******************************************************************************************************************
 * *******************************************************************************************************************
 * *******************************************************************************************************************
 *
 * Scheduler Api
 *
 * *******************************************************************************************************************
 * *******************************************************************************************************************
 * *******************************************************************************************************************
 * *******************************************************************************************************************
 */

export const get_task_schedulers = async (dispatch: Function, slug: string) => {
  try {
    const response = await axiosInstance.get(`/get-schedulers/${slug}`);

    dispatch(setSchedulers(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const create_task_schedulers = async (
  dispatch: Function,
  formData: ICreateScheduler
) => {
  try {
    const response = await axiosInstance.post(
      '/create-task-scheduler',
      formData
    );

    const { message, data } = response?.data;
    dispatch(createSchedler(data));
    notify({ description: 'Scheduler has been created.', message });
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

type IStatusData = { status: string };
export const activate_task_schedulers = async (
  dispatch: Function,
  id: string,
  formData: IStatusData = { status: '1' }
) => {
  try {
    const response = await axiosInstance.patch(
      `/activate-schedule/${id}`,
      formData
    );

    const { message, data } = response?.data;
    dispatch(activateSchedule(data));
    notify({ description: 'Scheduler has been activated.', message });
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const deactivate_task_schedulers = async (
  dispatch: Function,
  id: string
) => {
  try {
    const response = await axiosInstance.patch(`/deactivate-schedule/${id}`, {
      status: '5'
    });

    const { message, data } = response?.data;
    dispatch(deactivateSchedule(data));
    notify({ description: 'Scheduler has been deactivated.', message });
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
};
