import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITask, ITaskState, ITasksResponse } from '../types/task';

// Define the initial state using that type
const initialState: ITaskState = {
  tasks: [],
  task: null,
  loading: false,
  delete: false,
  show: false,
  update: false,
  current_page: '',
  last_page: '',
  search_current_page: '',
  search_last_page: ''
};

export const task = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<ITasksResponse>) => {
      state.tasks = action.payload?.data;
      state.current_page = action.payload?.current_page;
      state.last_page = action.payload?.last_page;
    },
    setTask: (state, action: PayloadAction<ITask>) => {
      state.task = action.payload;
    },
    createTask: (state, action: PayloadAction<ITask>) => {
      state.tasks = [action.payload, ...state.tasks];
    },
    deleteTask: (state, action: PayloadAction<ITask>) => {
      const update_tasks = state.tasks.filter(
        (task) => task.slug !== action.payload.slug
      );
      state.tasks = [action.payload, ...update_tasks];
    },
    recycleTask: (state, action: PayloadAction<ITask>) => {
      const update_tasks = state.tasks.filter(
        (task) => task.slug !== action.payload.slug
      );
      state.tasks = [action.payload, ...update_tasks];
    },

    // Loading
    setShow: (state) => {
      state.show = !state.show;
    }
  }
});

export const {
  setTasks,
  setTask,
  createTask,
  deleteTask,
  recycleTask,
  setShow
} = task.actions;

export default task.reducer;
