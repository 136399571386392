import React from 'react';

// state
import { useAppSelector } from '../../../store/hooks';
import {} from '../../../service/api/report';

// components
import MiniButton from '../../../components/elements/forms/MiniButton';
import RichTextInputBox from '../../../components/elements/forms/RichTextInputBox';

type IUpdateReportCandidateNote = {
	onSubmit: (data: string) => void;
};

const UpdateReportCandidateNote: React.FC<IUpdateReportCandidateNote> = ({
	onSubmit
}) => {
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<string>('');
	const report_stats = useAppSelector(({ report }) => report.report_stats);

	const onLoad = React.useCallback(() => {
		if (report_stats?.candidate_note) dataSet(report_stats?.candidate_note);
	}, [report_stats?.candidate_note]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div>
			<div>
				{!show ? (
					report_stats?.comment ? (
						<div
							dangerouslySetInnerHTML={{
								__html: report_stats.candidate_note
							}}
						/>
					) : (
						<div />
					)
				) : (
					<RichTextInputBox
						value={data}
						placeholder="Enter Candidate Note"
						status=""
						onChange={(e) => dataSet(e)}
					/>
				)}
			</div>
			<div>
				<div className="w-full flex justify-end my-2">
					<div className="w-[15%]">
						<MiniButton
							title={!show ? 'Edit' : 'Update'}
							onHandler={() => {
								if (show) {
									onSubmit(data);
									showSet(false);
								} else {
									showSet(true);
								}
							}}
							backgroundColor="#CDD8FE"
							textColor="#243677"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateReportCandidateNote;
