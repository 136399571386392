import React from 'react';

// services and state
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { get_total_candidate } from '../../../service/api/candidate';
import { get_total_clients } from '../../../service/api/client';
import { get_total_order } from '../../../service/api/order';
import { get_total_reports } from '../../../service/api/report';

const StasOverview: React.FC = () => {
	const dispatch = useAppDispatch();
	const total_clients = useAppSelector(({ client }) => client.total_clients);
	const total_orders = useAppSelector(({ order }) => order.total_orders);
	const total_report = useAppSelector(({ report }) => report.total_reports);
	const total_candidate = useAppSelector(
		({ candidate }) => candidate.total_candidates
	);
	const onLoad = React.useCallback(() => {
		get_total_candidate(dispatch);
		get_total_clients(dispatch);
		get_total_order(dispatch);
		get_total_reports(dispatch);
	}, [dispatch]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="h-full w-full bg-white p-6 text-[#232164]">
			<p className="md:text-[30px] text-[18px]">Statistics Overview</p>
			<div className="flex justify-evenly flex-wrap mt-2 mb-5">
				<div className="text-center px-2">
					<p className="md:text-[60px] text-[30px] mb-0">{total_candidate}</p>
					<p className="md:text-[22px] text-[16px]">Candidates</p>
				</div>
				<div className="border-r border-[#232164] hidden md:block"></div>
				<div className="text-center px-2">
					<p className="md:text-[60px] text-[30px] mb-0">{total_clients}</p>
					<p className="md:text-[22px] text-[16px]">Clients</p>
				</div>
				<div className="border-r border-[#232164] hidden md:block"></div>
				<div className="text-center px-2">
					<p className="md:text-[60px] text-[30px] mb-0">{total_orders}</p>
					<p className="md:text-[22px] text-[16px]">Jobs</p>
				</div>
				<div className="border-r border-[#232164] hidden md:block"></div>
				<div className="text-center px-2">
					<p className="md:text-[60px] text-[30px] mb-0">0</p>
					<p className="md:text-[22px] text-[16px]">Comments</p>
				</div>
				<div className="border-r border-[#232164] hidden md:block"></div>
				<div className="text-center px-2">
					<p className="md:text-[60px] text-[30px] mb-0">{total_report}</p>
					<p className="md:text-[22px] text-[16px]">Reports</p>
				</div>
			</div>
		</div>
	);
};

export default StasOverview;
