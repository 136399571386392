import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { setDegree, updateDegree, setLoading } from '@/store/slices/degree';

// notification
import { openNotification } from '@/components/alerts/notify';

export const get_degree = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-degrees');

    dispatch(setDegree(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_degree = async (dispatch: Function, data: ICreateData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/create-degree', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateDegree(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type IUpdateData = { name: string; id: string };
export const update_degree = async (dispatch: Function, data: IUpdateData) => {
  dispatch(setLoading());
  try {
    // const response = await axiosInstance.patch('/update-degree', data);
    // dispatch(setRoles(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};
