import React from 'react';

// types
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';

// components
import BroadCastCandidate from '../misc/BroadCastCandidate';
import SendBulkCandidate from '../misc/SendBulkCandidate';
import SuccessMessage from '../misc/SuccessMessage';

const Broadcast = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector(({ mailer }) => mailer.message);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Broadcast | Mailer'));
    dispatch(
      setNavButton({
        button_name: '',
        button: false
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-4">
      <div className="bg-[white] py-4 w-full flex justify-between">
        <BroadCastCandidate />
        <SendBulkCandidate />
      </div>
      <SuccessMessage
        visible={message.length > 0 ? true : false}
        message={message}
      />
    </div>
  );
};

export default Broadcast;
