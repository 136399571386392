import React from 'react';

// router
import { useParams } from 'react-router-dom';

// constant
import { AiFillEdit } from 'react-icons/ai';
import avatar_user from '../../../assets/images/temporary/user.png';

// component
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// state and service
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	get_report,
	update_report_name,
	report_completed
} from '../../../service/api/report';
import { IUpdateReportNameState } from '../../../store/types/report';

const ReportCompanyHeader = () => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const report = useAppSelector(({ report }) => report.report);
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<IUpdateReportNameState>({
		id: '',
		name: ''
	});

	const onLoad = React.useCallback(() => {
		if (id) {
			get_report(dispatch, id);
			dataSet((prev) => ({ ...prev, id }));
		}
	}, [dispatch, id]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="w-full flex bg-[#e0e7ff] px-5 py-5 items-center">
			<div className="w-[20%] text-center">
				<div className="w-[100px] h-[100px] rounded-full border">
					<img
						src={
							report?.client?.profile && report?.client?.profile?.avatar
								? report?.client?.profile?.avatar
								: avatar_user
						}
						alt={report?.client?.profile?.organization_name}
						className="w-[100px] h-[100px] rounded-full"
					/>
				</div>
			</div>

			<div className="text-[#243677] max-w-[1100px] w-[50%]">
				<div className="text-[#243677] max-w-[1100px] font-bold text-xl">
					{report?.client?.profile?.organization_name}
				</div>
				<div className="text-[#243677] max-w-[1100px] font-bold text-md">
					{!show ? (
						<div className="flex items-center">
							{report?.title}{' '}
							<AiFillEdit
								className="mx-2 cursor-pointer"
								size={20}
								onClick={() => showSet(true)}
							/>
						</div>
					) : (
						<input
							className="outline-none border"
							autoFocus={true}
							value={data.name}
							placeholder={data.name}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								dataSet((prev) => ({ ...prev, name: e.target.value }))
							}
							onBlur={() => {
								if (data.name && data.id) {
									update_report_name(dispatch, data);
									setTimeout(() => onLoad(), 400);
								}
								showSet(false);
							}}
						/>
					)}
				</div>
				<div className="text-[#243677] max-w-[1100px] font-bold">
					{report?.client?.profile?.state &&
						report?.client?.profile?.state?.name +
							', ' +
							report?.client?.profile?.state?.country?.name}
				</div>
			</div>

			{/* Completed Report */}
			<div className="w-[30%] text-center flex justify-center">
				<div className="w-[70%]">
					{id && report?.completed === '1' && (
						<ButtonComponent
							title="Report Completed"
							onHandler={() => report_completed(dispatch, { id, status: '2' })}
							backgroundColor="#243677"
							active={true}
						/>
					)}
					{id && report?.completed === '2' && (
						<ButtonComponent
							title="Continue Edit Report"
							onHandler={() => report_completed(dispatch, { id, status: '1' })}
							backgroundColor="green"
							active={true}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReportCompanyHeader;
