import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// notification
import { notification } from 'antd';

// state
import { setMoreLoading, stopMoreLoading } from '../../store/slices/loading';
import {
	setExpertises,
	setTechnical,
	setJobTypes,
	setCountries,
	setState,
	setPaymentType,
	setCurrencies,
	setPriceRange,
	setApplicantStatus,
	setOrderTypes,
	setPositions,
	setSoftSkills,
	setIndustries,
	setCultures,
	createCulture,
	createIndustry,
	setEducationLevel
} from '../../store/slices/misc';
import { ISaveFunc, ISaveFuncRes } from '../../store/types/misc';

export const get_technical_skills = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-technical-skills');

		dispatch(setTechnical(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// const { message } = err?.response?.data;
		}
	}
};

export const get_payment_types = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-payment-type');

		dispatch(setPaymentType(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_currencies = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-currencies');

		dispatch(setCurrencies(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_price_range = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/price-range');

		dispatch(setPriceRange(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_price_range_data = async (dispatch: any, id: string) => {
	try {
		const response = await axiosInstance.get(`/price-range-data/${id}`);

		dispatch(setPriceRange(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const create_technical_skills = async (
	dispatch: any,
	formData: { name: string }
) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post(
			'/create-technical-skill',
			formData
		);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const create_expertise_level = async (
	dispatch: any,
	formData: { name: string }
) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post(
			'/create-expertise-level',
			formData
		);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const create_job_type = async (
	dispatch: any,
	formData: { name: string }
) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-job-type', formData);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const create_payment_type = async (
	dispatch: any,
	formData: { name: string }
) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-payment-type', formData);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const create_currency = async (
	dispatch: any,
	formData: { name: string }
) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-currency', formData);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const create_price_range = async (
	dispatch: any,
	formData: { name: string }
) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-price-range', formData);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

/**
 * Regions
 * @param dispatch
 */

// get-applicant-status
export const get_applicant_status = async (dispatch: Function) => {
	try {
		const response = await axiosInstance.get('/get-applicant-status');

		dispatch(setApplicantStatus(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

// ==============================================================================
// ---------------------- Misc --------------------------------------------------
// ==============================================================================

export const get_job_types = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-job-types');

		dispatch(setJobTypes(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// const { message } = err?.response?.data;
		}
	}
};

export const get_expertises = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-expertise-level');

		dispatch(setExpertises(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// const { message } = err?.response?.data;
		}
	}
};

export const get_order_types = async (dispatch: Function) => {
	try {
		const response = await axiosInstance.get('/get-order-types');

		dispatch(setOrderTypes(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_countries = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-countries');

		dispatch(setCountries(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// const { message } = err?.response?.data;
		}
	}
};

export const get_education_levels = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-level-eduation');

		dispatch(setEducationLevel(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			// const { message } = err?.response?.data;
		}
	}
};

export const get_states = async (dispatch: any, id: string) => {
	try {
		const response = await axiosInstance.get(`/get-states/${id}`);

		dispatch(setState(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_positions = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-positions');

		dispatch(setPositions(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_soft_skills = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-soft-skills');

		dispatch(setSoftSkills(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_industries = async (dispatch: Function) => {
	try {
		const response = await axiosInstance.get('/get-industries');

		dispatch(setIndustries(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const get_cultures = async (dispatch: Function) => {
	try {
		const response = await axiosInstance.get('/get-cultures');

		dispatch(setCultures(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

// ---------------------- create --------------------------------------------------
export const create_position: ISaveFuncRes = async (dispatch, formData) => {
	try {
		const response = await axiosInstance.post('/create-position', formData);

		get_positions(dispatch);
		return response.data;
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};

export const create_soft_skills: ISaveFunc = async (dispatch, formData) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-soft-skill', formData);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const save_soft_skills: ISaveFunc = async (dispatch, formData) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-soft-skill', formData);

		notification.success({
			message: response.data.message
		});
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const save_industry: ISaveFunc = async (dispatch, data) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-industry', data);

		notification.success({ message: 'Industry created succesful.' });
		dispatch(createIndustry(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({
				message: 'This already exists.'
			});
		}
	}
	dispatch(stopMoreLoading());
};

export const save_culture: ISaveFunc = async (dispatch, data) => {
	dispatch(setMoreLoading());
	try {
		const response = await axiosInstance.post('/create-culture', data);

		notification.success({ message: 'Culture created succesful.' });
		dispatch(createCulture(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
			notification.error({ message: 'This already exists.' });
		}
	}
	dispatch(stopMoreLoading());
};
