import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  experiences: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  experiences: [],
  update: false,
  loading: false
};

export const experience = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    setExperiences: (state, action: PayloadAction<IDataType[]>) => {
      state.experiences = action.payload;
    },
    createExperience: (state, action: PayloadAction<IDataType>) => {
      state.experiences = [action.payload, ...state.experiences];
    },
    updateExperience: (state, action: PayloadAction<IDataType>) => {
      state.experiences = state.experiences.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setExperiences,
  createExperience,
  updateExperience,
  setLoading,
  setUpdate
} = experience.actions;

export default experience.reducer;
