import React from 'react';

// link
import { useNavigate } from 'react-router-dom';

// dispatch
import { useAppDispatch } from '../../../store/hooks';
import { setSearch } from '../../../store/slices/navbar';

type IClearLink = {
	path: string;
	children: React.ReactNode;
};

const ClearLink: React.FC<IClearLink> = ({ path, children }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	return (
		<div
			onClick={() => {
				dispatch(setSearch(''));
				navigate(path);
			}}
		>
			{children}
		</div>
	);
};

export default ClearLink;
