import React from 'react';

type IRadioSelect = {
	name: string;
	checked: boolean;
	onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioSelect: React.FC<IRadioSelect> = ({ name, checked, onClick }) => {
	return (
		<div className="flex items-center my-2">
			<div className="mr-2">
				<input
					type="checkbox"
					id={name}
					name={name}
					checked={checked}
					className="border-[#567BFF] border-2 cursor-pointer"
					onChange={onClick}
				/>
			</div>
			<div
				onClick={() => document.getElementById(name as string)?.click()}
				className="capitalize mx-2 text-[16px] cursor-pointer"
			>
				{name}
			</div>
		</div>
	);
};

export default RadioSelect;
