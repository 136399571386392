import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType, ILineChart } from '../types/misc';
import {
  IOrderType,
  IPayloadOrderData,
  IActionApplicant,
  IOrderAction,
  ICorporateResponse,
  IShortItems,
  ISoftSkill,
  ICorporate
} from '../types/order';

// Define the initial state using that type
const initialState: IOrderAction = {
  statistics: [],

  corporate_orders: [],
  corporate_service: null,
  corporate_current_page: '1',
  corporate_last_page: '1',

  executive_orders: [],
  executive_current_page: '1',
  executive_last_page: '1',

  total_orders: '',

  orders: [],
  current_page: '1',
  last_page: '1',
  order: null,

  positions: [],
  check_positions: [],

  applicants: [],
  applicant_current_page: '1',
  applicant_last_page: '1',
  applicant_total: null,

  client_orders: [],
  all_client_orders: [],

  soft_skills: [],

  availability_loading: false,

  loading: false,
  delete: false,
  show: false,
  update: false
};

export const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setStatistics: (state, action: PayloadAction<ILineChart[]>) => {
      state.statistics = action.payload;
    },
    setOrders: (state, action: PayloadAction<IPayloadOrderData>) => {
      state.orders = action.payload.data;
      state.current_page = action.payload?.current_page;
      state.last_page = action.payload?.last_page;
    },

    setOrder: (state, action: PayloadAction<IOrderType>) => {
      state.order = action.payload;
    },

    /**
     * Executive Order
     *
     * @param state
     * @param action
     */
    setExecutiveOrders: (state, action: PayloadAction<IPayloadOrderData>) => {
      state.executive_orders = action.payload.data;
      state.executive_current_page = action.payload.current_page;
      state.executive_last_page = action.payload.last_page;
    },
    setCorporateService: (state, action: PayloadAction<ICorporate>) => {
      state.corporate_service = action.payload;
    },

    setAllClientOrders: (state, action: PayloadAction<IShortItems[]>) => {
      state.all_client_orders = action.payload;
    },

    /**
     * Corporate Orders
     *
     * @param state
     * @param action
     */
    setCorporateOrders: (state, action: PayloadAction<ICorporateResponse>) => {
      state.corporate_orders = action.payload.data;
      state.corporate_current_page = action.payload.current_page;
      state.corporate_last_page = action.payload.last_page;
    },

    setTotalOrder: (state, action: PayloadAction<string>) => {
      state.total_orders = action.payload;
    },

    setApplicants: (state, action: PayloadAction<IActionApplicant>) => {
      state.applicants = action.payload.data;
      state.applicant_last_page = action.payload?.last_page;
      state.applicant_current_page = action.payload?.current_page;
    },
    setApplicantTotal: (state, action: PayloadAction<number>) => {
      state.applicant_total = action.payload;
    },
    setApplicantNext: (state) => {
      state.applicant_current_page = state.applicant_current_page + 1;
    },
    setApplicantPrev: (state) => {
      state.applicant_current_page = (
        Number(state.applicant_current_page) - 1
      ).toString();
    },

    setAvailability: (state, action: PayloadAction<string>) => {
      state.orders = state.orders.map((item) => {
        if (item.slug === action.payload) {
          item.availability = '0';
        }
        return item;
      });
    },
    setPosition: (state, action: PayloadAction<IDataType[]>) => {
      state.positions = action.payload.map((item) => ({
        label: item.name,
        value: item.name
      }));
      state.check_positions = action.payload;
    },

    setSoftSkill: (state, action: PayloadAction<ISoftSkill[]>) => {
      state.soft_skills = action.payload;
    },

    setAvailabilityLoading: (state) => {
      state.availability_loading = !state.availability_loading;
    },

    // =================== pagination =======================================
    setNextCorporate: (state) => {
      state.corporate_current_page = (
        Number(state.corporate_current_page) + 1
      ).toString();
    },
    setPrevCorporate: (state) => {
      state.corporate_current_page = (
        Number(state.corporate_current_page) - 1
      ).toString();
    },
    setNextExecutive: (state) => {
      state.executive_current_page = (
        Number(state.executive_current_page) + 1
      ).toString();
    },
    setPrevExecutive: (state) => {
      state.executive_current_page = (
        Number(state.executive_current_page) - 1
      ).toString();
    },
    setNextPage: (state) => {
      state.current_page = (Number(state.current_page) + 1).toString();
    },
    setPrevPage: (state) => {
      state.current_page = (Number(state.current_page) - 1).toString();
    },

    // =================== loading =======================================
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
    setDelete: (state) => {
      state.delete = !state.delete;
    }
  }
});

export const {
  setStatistics,

  setCorporateOrders,
  setCorporateService,

  setExecutiveOrders,

  setOrders,

  setTotalOrder,

  setOrder,
  setApplicants,
  setApplicantTotal,
  setApplicantNext,
  setApplicantPrev,

  // setClientOrder,
  setAllClientOrders,

  setSoftSkill,

  setAvailability,
  setAvailabilityLoading,
  setPosition,

  // pagination
  setPrevCorporate,
  setNextCorporate,
  setNextExecutive,
  setPrevExecutive,
  setNextPage,
  setPrevPage,

  // loading
  setLoading,
  setUpdate,
  setDelete,
  setShow
} = order.actions;

export default order.reducer;
