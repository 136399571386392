import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISchedule, IScheduleState } from '../types/scheduler';

// Define the initial state using that type
const initialState: IScheduleState = {
  schedules: [],
  schedule: null,
  loading: false,
  delete: false,
  show: false,
  update: false
};

export const schedule = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setSchedulers: (state, action: PayloadAction<ISchedule[]>) => {
      state.schedules = action.payload;
    },
    setSchedule: (state, action: PayloadAction<string>) => {
      const schedule = state.schedules.find(
        (schedule) => schedule.id?.toString() === action.payload?.toString()
      );
      if (schedule) state.schedule = schedule;
    },
    createSchedler: (state, action: PayloadAction<ISchedule>) => {
      state.schedules = [action.payload, ...state.schedules];
    },
    activateSchedule: (state, action: PayloadAction<ISchedule>) => {
      const changes = state.schedules.filter(
        (schedule) => schedule.id?.toString() !== action.payload?.id?.toString()
      );
      state.schedules = [action.payload, ...changes];
    },
    deactivateSchedule: (state, action: PayloadAction<ISchedule>) => {
      const changes = state.schedules.filter(
        (schedule) => schedule.id?.toString() !== action.payload?.id?.toString()
      );
      state.schedules = [action.payload, ...changes];
    },

    // Loading
    setShow: (state) => {
      state.show = !state.show;
    }
  }
});

export const {
  setSchedulers,
  setSchedule,
  createSchedler,
  deactivateSchedule,
  activateSchedule,
  setShow
} = schedule.actions;

export default schedule.reducer;
