import React from 'react';
import { FaEye } from 'react-icons/fa';

// types
import { IApplicantData } from '../../../store/types/order';
import { get_date } from '@/service/formatter/date';

type IApplicantShortData = {
  item: IApplicantData;
  onView: (data: IApplicantData) => void;
  onReject?: (data: IApplicantData) => void;
};

const ApplicantShortData: React.FC<IApplicantShortData> = ({
  item,
  onView
}) => {
  return (
    <div className="flex justify-center items-center bg-table-bg px-3 py-5 my-2">
      <div className="mx-1 flex items-center justify-center border-r px-2 w-[15%] text-center">
        <img
          src={item?.user?.profile?.avatar}
          alt={item?.user?.profile?.first_name + ' Avatar'}
          className="h-[60px] w-[60px] rounded-full"
        />
      </div>
      <div className="mx-1 border-r px-2 w-[20%] text-center">
        {item?.user?.profile?.first_name} {item?.user?.profile?.last_name}
      </div>
      <div className="mx-1 border-r px-2 w-[20%] text-center">
        {item?.user?.email}
      </div>
      <div className="mx-1 border-r px-2 w-[20%] text-center capitalize">
        {item?.user?.profile?.address?.about?.profession?.name}
      </div>
      <div className="mx-1 border-r px-2 w-[15%] text-center">
        {item?.created_at ? get_date(item.created_at) : ''}
      </div>
      <div className="mx-1 w-[10%] text-center flex items-center justify-center">
        <FaEye
          size={20}
          color="#243677"
          className="cursor-pointer"
          onClick={() => onView(item)}
        />

        {/* <FaRegThumbsDown
          size={20}
          color="#ff0000"
          className="cursor-pointer"
          onClick={() => onReject?.(item)}
        /> */}
      </div>
    </div>
  );
};

export default ApplicantShortData;
