import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IAdminRole,
  IAdminUserState,
  IAdminUsers,
  IGetRolePermission,
  IPaginate,
  IPermissionType,
  IRolePermission
} from '../types/user';
import { IProfile } from '../types/auth';

// Define the initial state using that type
const initialState: IAdminUserState = {
  admins: [],
  admin: null,
  profile: null,

  // roles
  roles: [],
  admin_roles: [],
  admin_roles_permissions: [],

  // permissions
  permission: [],
  admin_permission: [],

  current_page: '1',
  last_page: '1',
  search_current_page: '1',
  search_last_page: '1',
  loading: false,
  update: false,
  show: false,
  delete: false
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAdmins: (state, action: PayloadAction<IAdminUsers>) => {
      state.admins = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setAdmin: (state, action: PayloadAction<IProfile>) => {
      state.admin = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
    setSearch: (state, action: PayloadAction<IAdminUsers>) => {
      state.admins = action.payload.data;
      state.search_current_page = action.payload.current_page;
      state.search_last_page = action.payload.last_page;
    },

    // roles
    setRoles: (state, action: PayloadAction<IRolePermission[]>) => {
      state.roles = action.payload;
    },
    setAddNewRole: (state, action: PayloadAction<IAdminRole>) => {
      state.admin_roles = [...state.admin_roles, action.payload];
    },
    setAdminRole: (state, action: PayloadAction<IAdminRole[]>) => {
      state.admin_roles = action.payload;
    },

    // Permission
    setPermissions: (state, action: PayloadAction<IRolePermission[]>) => {
      state.permission = action.payload;
    },
    setAdminPermission: (state, action: PayloadAction<IPermissionType[]>) => {
      state.admin_permission = action.payload;
    },
    setAddPermission: (state, action: PayloadAction<IPermissionType>) => {
      state.admin_permission = [...state.admin_permission, action.payload];
    },

    setAdminPermissionRole: (
      state,
      action: PayloadAction<IGetRolePermission[]>
    ) => {
      state.admin_roles_permissions = action.payload;
    },

    // loading
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
    setDelete: (state) => {
      state.delete = !state.delete;
    },

    // Paginate
    setPaginate: (state, action: PayloadAction<IPaginate>) => {
      if (action.payload === IPaginate.increase) {
        state.current_page = (Number(state.current_page) + 1).toString();
      } else {
        state.current_page = (Number(state.current_page) - 1).toString();
      }
    },
    setSearchPaginate: (state, action: PayloadAction<IPaginate>) => {
      if (action.payload === IPaginate.decrease) {
        state.search_current_page = (
          Number(state.search_current_page) + 1
        ).toString();
      } else {
        state.search_current_page = (
          Number(state.search_current_page) - 1
        ).toString();
      }
    }
  }
});

export const {
  setRoles,
  setAdminRole,
  setAddNewRole,
  setAdmin,
  setAdmins,
  setSearch,
  setPaginate,
  setPermissions,
  setAddPermission,
  setUserProfile,
  setSearchPaginate,
  setAdminPermission,
  setAdminPermissionRole,
  setLoading,
  setUpdate,
  setDelete,
  setShow
} = user.actions;

export default user.reducer;
