import React from 'react';

// components
import LineGraph from '@/components/ui/graph/LineGraph';

// hooks
import { useAppSelector } from '@/store/hooks';

const CandidateWeeklyStatistic = () => {
  const statistics = useAppSelector(
    ({ candidate }) => candidate.weekly_statistics
  );
  return (
    <div className="w-[49%] h-[40vh]">
      <LineGraph data={[{ id: 'Candidate Weekly Report', data: statistics }]} />
    </div>
  );
};

export default CandidateWeeklyStatistic;
