import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { setCompany, updateCompany, setLoading } from '@/store/slices/company';

// notification
import { openNotification } from '@/components/alerts/notify';

export const get_company = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-companies');

    dispatch(setCompany(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_company = async (dispatch: Function, data: ICreateData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/create-company', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateCompany(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type IUpdateData = { name: string; id: string };
export const update_company = async (dispatch: Function, data: IUpdateData) => {
  dispatch(setLoading());
  try {
    // const response = await axiosInstance.patch('/update-company', data);
    // dispatch(setRoles(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};
