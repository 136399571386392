import React from 'react';

// components
import { Spin } from 'antd';

type IButton = {
	title: string;
	onClick: () => void;
	textColor?: string;
	backgroundColor?: string;
	icon?: React.ReactNode;
	loading?: boolean;
};

const Button: React.FC<IButton> = ({
	title,
	icon,
	loading,
	textColor,
	backgroundColor,
	onClick
}) => {
	const bg_color = backgroundColor || 'bg-transparent border-[#243677] border';
	const txColor = textColor || 'text-[#243677]';
	return (
		<button
			className={`px-5 py-2 w-full flex items-center justify-center rounded-md text-[15px] font-abel ${bg_color} ${txColor}`}
			onClick={onClick}
		>
			{loading && <Spin className="mx-2" />} {title} {icon}
		</button>
	);
};

export default Button;
