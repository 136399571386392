import React from 'react';

// constants
import user_avatar from '../../../assets/images/icons/user.svg';

// components
import CreateUser from '../misc/CreateUser';
import Paginate from '@/components/ui/paginate';

// types
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { get_admins, search_admins } from '@/service/api/user';
import { setNavButton, setTitle } from '@/store/slices/navbar';
import { IPaginate } from '@/store/types/user';
import { setPaginate, setSearchPaginate } from '@/store/slices/user';

// router
import { useNavigate } from 'react-router-dom';
import ErrorPage from '@/components/ui/errorpage/403_Page';

const RolesAndPermission = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(({ auth }) => auth.user);
  const admins = useAppSelector(({ user }) => user.admins);
  const curr_page = useAppSelector(({ user }) => user.current_page);
  const s_curr_page = useAppSelector(({ user }) => user.search_current_page);
  const last_page = useAppSelector(({ user }) => user.last_page);
  const s_last_page = useAppSelector(({ user }) => user.search_last_page);
  const search = useAppSelector(({ navbar }) => navbar.search);
  const action = useAppSelector(({ navbar }) => navbar.action);
  const [create, createUser] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    if (user?.role_id?.toString() === '1') {
      dispatch(setTitle('Roles & Permissions'));
      dispatch(setNavButton({ button_name: 'Create User', button: true }));
    }
  }, [dispatch, user?.role_id]);

  const onFetch = React.useCallback(() => {
    if (search.length > 3) search_admins(dispatch, search, s_curr_page);
    else get_admins(dispatch, curr_page);
  }, [search, dispatch, s_curr_page, curr_page]);

  const onAction = React.useCallback(() => {
    if (action) createUser(true);
  }, [action]);

  React.useEffect(() => {
    onLoad();
    onFetch();
    onAction();
  }, [onLoad, onFetch, onAction]);

  return (
    <div className="w-full">
      {user?.role_id?.toString() !== '1' ? (
        <ErrorPage />
      ) : (
        <>
          <div className="px-8">
            {admins.map((admin, _) => (
              <div
                key={_ + 1}
                className="flex items-center justify-center py-3 border-b-2"
              >
                <div className="w-[10%]">
                  <img
                    src={admin?.profile?.avatar || user_avatar}
                    alt={admin?.profile?.full_name || 'avatar'}
                    className="w-[50px] h-[50px] rounded-full border-2"
                  />
                </div>
                <div className="w-[20%] capitalize">
                  {admin?.profile?.full_name}
                </div>
                <div className="w-[30%]">{admin?.email}</div>
                <div className="w-[30%] capitalize">
                  {admin?.profile?.position?.name}
                </div>
                <div className="text-[red] font-abel w-[10%]">
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      if (user?.role_id?.toString() === '1')
                        navigate(`/user-profile/${admin?.admin_number}`);
                    }}
                  >
                    Set Roles
                  </p>
                </div>
              </div>
            ))}
          </div>
          <Paginate
            current_page={search.length > 3 ? s_curr_page : curr_page}
            last_page={search.length > 3 ? s_last_page : last_page}
            onPressNext={() => {
              if (search.length > 3)
                dispatch(setSearchPaginate(IPaginate.increase));
              else dispatch(setPaginate(IPaginate.increase));
            }}
            onPressPrev={() => {
              if (search.length > 3)
                dispatch(setSearchPaginate(IPaginate.decrease));
              else dispatch(setPaginate(IPaginate.decrease));
            }}
          />
          <CreateUser
            visible={create}
            onDone={() => {
              createUser(false);
              setTimeout(() => onFetch(), 400);
            }}
          />
        </>
      )}
    </div>
  );
};

export default RolesAndPermission;
