import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  technical_skills: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  technical_skills: [],
  update: false,
  loading: false
};

export const technical_skill = createSlice({
  name: 'technical_skill',
  initialState,
  reducers: {
    setTecnicalSkill: (state, action: PayloadAction<IDataType[]>) => {
      state.technical_skills = action.payload;
    },
    createTecnicalSkill: (state, action: PayloadAction<IDataType>) => {
      state.technical_skills = [action.payload, ...state.technical_skills];
    },
    updateTecnicalSkill: (state, action: PayloadAction<IDataType>) => {
      state.technical_skills = state.technical_skills.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setTecnicalSkill,
  createTecnicalSkill,
  updateTecnicalSkill,
  setLoading,
  setUpdate
} = technical_skill.actions;

export default technical_skill.reducer;
