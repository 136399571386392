import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IClientResponse, IClientState, IClientUser } from '../types/client';
import { IDataType } from '../types/misc';

// Define a type for the slice state

// Define the initial state using that type
const initialState: IClientState = {
	clients: [],
	client: null,
	search_users: [],
	current_page: '1',
	last_page: '1',
	total_clients: '',
	client_categories: [],
	client_themes: [],
	client_total_order: '',
	loading: false,
	update: false,
	show: false,
	delete: false
};

export const client = createSlice({
	name: 'client',
	initialState,
	reducers: {
		setClients: (state, action: PayloadAction<IClientResponse>) => {
			state.current_page = action.payload.current_page;
			state.last_page = action.payload.last_page;
			state.clients = action.payload.data;
		},
		setSearch: (state, action: PayloadAction<IClientUser[]>) => {
			state.clients = action.payload;
		},
		setAllClient: (state, action: PayloadAction<IClientUser[]>) => {
			state.clients = action.payload;
		},
		setClient: (state, action: PayloadAction<IClientUser>) => {
			state.client = action.payload;
		},
		setSearchUsers: (state, action: PayloadAction<IClientUser[]>) => {
			state.search_users = action.payload.map((client) => ({
				id: client.email,
				name: client?.profile?.organization_name || client.email
			}));
		},
		setTotalClients: (state, action: PayloadAction<string>) => {
			state.total_clients = action.payload;
		},
		setClientThemes: (state, action: PayloadAction<IDataType[]>) => {
			state.client_themes = action.payload;
		},
		setClientCategories: (state, action: PayloadAction<IDataType[]>) => {
			state.client_categories = action.payload;
		},
		setClientTotalOrder: (state, action: PayloadAction<string>) => {
			state.client_total_order = action.payload;
		},
		setNext: (state) => {
			if (
				Number(state.current_page) > 0 &&
				state.current_page < state.last_page
			) {
				state.current_page = state.current_page + 1;
			}
		},
		setPrev: (state) => {
			if (
				Number(state.current_page) > 1 &&
				state.current_page <= state.last_page
			) {
				state.current_page = (Number(state.current_page) - 1).toString();
			}
		},

		// loading
		setLoading: (state) => {
			state.loading = !state.loading;
		},
		setUpdate: (state) => {
			state.update = !state.update;
		},
		setShow: (state) => {
			state.show = !state.show;
		},
		setDelete: (state) => {
			state.delete = !state.delete;
		}
	}
});

export const {
	setClients,
	setSearch,
	setTotalClients,
	setSearchUsers,
	setAllClient,
	setClientThemes,
	setClientCategories,
	setClientTotalOrder,
	setClient,
	setNext,
	setPrev,
	setLoading,
	setUpdate,
	setShow,
	setDelete
} = client.actions;

export default client.reducer;
