import React from 'react';
import useGenerateCandidate from '../hooks/useGenerateCandidate';

// state
import { setTitle, setNavButton } from '../../../store/slices/navbar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { delete_report_order } from '../../../service/api/report';

// react router dom
import { useParams } from 'react-router-dom';

// components
import AddOrder from '../misc/AddOrder';
import DisplayCandidate from '../misc/DisplayCandidate';
import AddCandidateReport from '../misc/AddCandidateReport';
import ReportCompanyHeader from '../misc/ReportCompanyHeader';

// constants and icons
import { AiOutlinePlus } from 'react-icons/ai';
import { get_client_report_roles } from '../../../service/api/report_role';
import ShowReportStat from '../misc/ShowReportStat';

const GenerateCandidateReport = () => {
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const report = useAppSelector(({ report }) => report.report);
	const roles = useAppSelector(({ report_role }) => report_role.roles);
	const action = useAppSelector(({ navbar }) => navbar.action);
	const [show_report, showReportSet] = React.useState<boolean>(false);
	const {
		show,
		reload,
		showCandidate,
		reportRoleId,
		reloadSet,
		reportRoleIdSet,
		showCandiateSet,
		showSet
	} = useGenerateCandidate();

	const onLoad = React.useCallback(() => {
		if (id) get_client_report_roles(dispatch, id);
	}, [dispatch, id]);

	const onSetHeader = React.useCallback(() => {
		dispatch(setTitle('Report Detail'));
		dispatch(setNavButton({ button_name: 'Report Detail', button: true }));
	}, [dispatch]);

	const onAction = React.useCallback(() => {
		if (action) showReportSet(true);
	}, [action]);

	React.useEffect(() => {
		onLoad();
		onAction();
		onSetHeader();
	}, [onAction, onLoad, onSetHeader]);

	const onFileteredRole = roles.map((item) => item.order.slug);

	return (
		<div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-5">
			<ReportCompanyHeader />

			<div className="py-5 flex flex-wrap w-full justify-evenly">
				{roles.map((report_role) => (
					<AddCandidateReport
						key={report_role.id}
						onReload={reload}
						report_role_id={report_role.id?.toString()}
						order={report_role.order}
						onDelete={(res) => {
							if (id && res) {
								delete_report_order(dispatch, {
									order_slug: res,
									report_id: id
								});
								setTimeout(() => onLoad(), 400);
							}
						}}
						onAddCandidate={(res) => {
							showCandiateSet(true);
							reportRoleIdSet(res);
						}}
					/>
				))}
				<div
					onClick={() => {
						showSet(true);
						onLoad();
					}}
					className="w-[33%] bg-white flex items-center justify-center flex-col border rounded-md overflow-hidden min-h-[200px] cursor-pointer"
				>
					<div className="bg-[#F6F7FB] h-[60px] w-[60px] flex items-center justify-center rounded-full ">
						<AiOutlinePlus size={30} />
					</div>
				</div>
			</div>

			{reportRoleId && id && (
				<DisplayCandidate
					report_role_id={reportRoleId?.toString()}
					show={showCandidate}
					onClose={() => {
						reloadSet(true);
						onLoad();
						showCandiateSet(false);
						reportRoleIdSet('');
						setTimeout(() => reloadSet(false), 400);
					}}
				/>
			)}

			{report?.client?.email && id && (
				<AddOrder
					id={id}
					show={show}
					selected={onFileteredRole}
					mail={report?.client?.email}
					onClose={() => {
						showSet(false);
						onLoad();
					}}
					onSubmit={onLoad}
				/>
			)}

			{id && (
				<ShowReportStat
					id={id}
					show={show_report}
					onCancel={() => {
						showReportSet(false);
					}}
				/>
			)}
		</div>
	);
};

export default GenerateCandidateReport;
