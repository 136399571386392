import React from 'react';

// components
import TextInputBox from '../../../components/elements/forms/TextArea';
import TabPicker from '../../../components/elements/forms/TabPicker';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// types
import { SoftSkills } from '../../../store/types/order';
import { ErrorWarning } from '../../../store/types/misc';

// state
import { get_soft_skills } from '../../../service/api/misc';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	add_order_soft_skills,
	delete_order_soft_skills,
	get_order_soft_skills
} from '@/service/api/order';

type OrderNameType = {
	onPress: (res: SoftSkills) => void;
};

const EditSelectSoftSkills: React.FC<OrderNameType> = ({ onPress }) => {
	const dispatch = useAppDispatch();
	const loading = useAppSelector(({ order }) => order.loading);
	const skills = useAppSelector(({ misc }) => misc.soft_skills);
	const order = useAppSelector(({ order }) => order.order);
	const sel_skills = useAppSelector(({ order }) => order.soft_skills);
	const [data, dataSet] = React.useState<SoftSkills>({
		soft_skills: [],
		others: ''
	});
	const [error, errorSet] = React.useState<ErrorWarning>('');

	const onGetSkill = React.useCallback(() => {
		get_soft_skills(dispatch);
	}, [dispatch]);

	const onSetData = React.useCallback(() => {
		if (order?.slug) get_order_soft_skills(dispatch, order.slug);
	}, [dispatch, order?.slug]);

	React.useEffect(() => {
		onGetSkill();
		onSetData();
	}, [onGetSkill, onSetData]);

	return (
		<div className="md:flex flex-col flex justify-center mt-10">
			<div className="font-abel text-left md:text-[#4E4E4E] md:text-[28px]">
				Select the relevant skills your company needs
			</div>

			<div className="w-full">
				{error && (
					<p className="text-[red] text-right mt-1">
						Please choose skills you need
					</p>
				)}
				<div className="flex flex-wrap max-h-screen w-full my-5">
					{skills.map((item) => (
						<TabPicker
							key={item.id}
							name={item.name}
							id={item.id}
							checked={
								sel_skills?.find(
									(skill) =>
										skill?.skill?.id?.toString() === item.id?.toString()
								)
									? true
									: false
							}
							onChange={(e) => {
								const sel = sel_skills?.find(
									(skill) => skill?.skill?.id?.toString() === e?.toString()
								);
								if (!sel && order?.slug && e) {
									add_order_soft_skills(dispatch, {
										order_id: order?.slug,
										skill_id: e?.toString()
									});
								} else {
									if (order?.slug)
										delete_order_soft_skills(dispatch, {
											order_id: order?.slug,
											skill_id: e.toString()
										});
								}
								errorSet('');
								setTimeout(() => {
									if (order?.slug) get_order_soft_skills(dispatch, order.slug);
								}, 400);
							}}
						/>
					))}
				</div>

				<div className="w-full text-[#797979] opacity-50 text-left">
					<TextInputBox
						placeholder="Add more skills"
						value={data.others}
						name="others"
						label="More Skills"
						text_class="h-[200px]"
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							const { name, value } = e.target;
							dataSet((prev) => ({ ...prev, [name]: value }));
						}}
					/>
				</div>

				<div className="flex items-center justify-end mt-10 ">
					<div className="w-[30%]">
						<ButtonComponent
							title="Next"
							textColor="white"
							backgroundColor="#243677"
							loading={loading}
							onHandler={() => onPress(data)}
							active={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditSelectSoftSkills;
