import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';
import { useAppDispatch } from '@/store/hooks';
import { clearMessage } from '@/store/slices/mailer';

type ISuccessMessage = {
  visible: boolean;
  message: string[];
};

const SuccessMessage: React.FC<ISuccessMessage> = ({ visible, message }) => {
  const dispatch = useAppDispatch();
  return (
    <Modal
      open={visible}
      closeIcon={false}
      footer={null}
      closable={false}
      centered={true}
    >
      <div className="flex h-[400px]">
        {message.map((i, index) => (
          <p
            key={index + 1}
            className="bg-[#CDD8FE] text-[#243677] py-2 px-3 mr-2 my-2"
          >
            {i}
          </p>
        ))}
      </div>

      <div className="w-full flex justify-end">
        <div className="w-[40%]">
          <ButtonComponent
            title="Cancel"
            onHandler={() => dispatch(clearMessage())}
            backgroundColor="red"
            active={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuccessMessage;
