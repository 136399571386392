// notification
import { notification } from 'antd';

// type
import { IMessageType } from '../../../store/types/message';
export type INotification = {
	status: 'success' | 'info' | 'warning' | 'error';
	message: string;
};

export const notify = ({ description, message }: IMessageType) => {
	notification.success({
		message: message,
		description: description,
		placement: 'topRight'
	});
};

export const openNotification = ({ status, message }: INotification) => {
	notification[status]({
		message: status.toUpperCase(),
		description: message,
		placement: 'topRight'
	});
};
