import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IReportData,
  IReportResponse,
  IReportStat,
  IReportState,
  IReportsResponse,
  ReportStatus
} from '../types/report';
import { IGraphState } from '../types/misc';

// Define the initial state using that type
const initialState: IReportState = {
  reports: [],
  statistics: [],
  search_results: [],
  report: null,
  total_reports: '',
  current_page: '1',
  last_page: '1',
  gender_reports: [],
  report_stats: null,
  update_loading: false
};

export const report = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setTotalReports: (state, action: PayloadAction<string>) => {
      state.total_reports = action.payload;
    },
    setStatistics: (state, action: PayloadAction<IGraphState[]>) => {
      state.statistics = action.payload;
    },
    setReports: (state, action: PayloadAction<IReportsResponse>) => {
      state.reports = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setSearchResult: (state, action: PayloadAction<IReportResponse>) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.search_results = action.payload.data;
    },
    setReport: (state, action: PayloadAction<IReportData>) => {
      state.report = action.payload;
    },
    setReportStatus: (state, action: PayloadAction<ReportStatus>) => {
      if (state.report)
        state.report = { ...state.report, completed: action.payload };
    },
    setGenderReport: (state, action: PayloadAction<IGraphState[]>) => {
      state.gender_reports = action.payload;
    },
    setReportStats: (state, action: PayloadAction<IReportStat>) => {
      state.report_stats = action.payload;
    },
    setNext: (state) => {
      if (Number(state.current_page) < Number(state.last_page)) {
        state.current_page = (Number(state.current_page) + 1).toString();
      }
    },
    setPrev: (state) => {
      if (
        Number(state.current_page) > 1 &&
        Number(state.current_page) <= Number(state.last_page)
      ) {
        state.current_page = (Number(state.current_page) - 1).toString();
      }
    },
    setLoadingUpdate: (state) => {
      state.update_loading = !state.update_loading;
    }
  }
});

export const {
  setTotalReports,
  setSearchResult,
  setStatistics,
  setNext,
  setPrev,
  setReport,
  setReports,
  setReportStats,
  setReportStatus,
  setGenderReport,
  setLoadingUpdate
} = report.actions;

export default report.reducer;
