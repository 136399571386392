import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setSoftSkill,
  createSoftSkill,
  updateSoftSkill,
  setLoading,
  setUpdate
} from '@/store/slices/soft_skill';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_soft_skills = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-soft-skills');

    dispatch(setSoftSkill(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setSoftSkill([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_soft_skill = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-soft-skill', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createSoftSkill(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_soft_skill = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-soft-skill', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateSoftSkill(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
