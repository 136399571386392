import React from 'react';

// types
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';

// components
import { get_task, get_task_schedulers } from '@/service/api/task';
import CreateSchedule from '../misc/CreateSchedule';
import ListSchedule from '../misc/ListSchedule';
import ShowSchedule from '../misc/ShowSchedule';
import Header from '../misc/Header';

// router
import { useParams } from 'react-router-dom';

const TaskAutomateShow = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const action = useAppSelector(({ navbar }) => navbar.action);
  const [show, showSet] = React.useState<boolean>(false);
  const [schedule, scheduleSet] = React.useState<{
    show: boolean;
    schedule: string;
  }>({
    show: false,
    schedule: ''
  });

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Schedule'));
    dispatch(
      setNavButton({
        button_name: 'Create Schdule',
        button: true
      })
    );
  }, [dispatch]);

  const onGetUser = React.useCallback(() => {
    if (slug) {
      get_task(dispatch, slug);
      get_task_schedulers(dispatch, slug);
    }
  }, [dispatch, slug]);

  const onAction = React.useCallback(() => {
    if (action) showSet(true);
  }, [action]);

  React.useEffect(() => {
    onLoad();
    onAction();
    onGetUser();
  }, [onAction, onGetUser, onLoad]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-4 pt-3 pb-5">
      <Header />
      <ListSchedule
        dispatch={dispatch}
        onView={(res) => scheduleSet((prev) => ({ show: true, schedule: res }))}
      />
      {slug && (
        <CreateSchedule
          visible={show}
          task_slug={slug}
          dispatch={dispatch}
          onDone={() => showSet(false)}
        />
      )}
      {schedule.show && schedule.schedule && (
        <ShowSchedule
          visible={schedule.show}
          schedule_id={schedule.schedule}
          dispatch={dispatch}
          onDone={() => scheduleSet((prev) => ({ show: false, schedule: '' }))}
        />
      )}
    </div>
  );
};

export default TaskAutomateShow;
