// components
import { Popconfirm } from 'antd';

// constants
import { FaQuestionCircle, FaToggleOn, FaRegEye } from 'react-icons/fa';
import { get_date, get_normal_date } from '@/service/formatter/date';

// state and services
import {
  activate_task_schedulers,
  deactivate_task_schedulers
} from '@/service/api/task';
import { useAppSelector } from '@/store/hooks';

type IListSchedule = {
  dispatch: Function;
  onView: (data: string) => void;
};

const ListSchedule: React.FC<IListSchedule> = ({ dispatch, onView }) => {
  const schedulers = useAppSelector(({ schedule }) => schedule.schedules);

  return (
    <div>
      <div className="flex justify-center items-center bg-table-bg px-3 py-5">
        <div className="mx-1 border-r px-2 w-[10%] text-center">S/N</div>
        <div className="mx-1 border-r px-2 w-[35%] text-center">Created By</div>
        <div className="mx-1 border-r px-2 w-[20%] text-center">Date</div>
        <div className="mx-1 border-r px-2 w-[10%] text-center">Status</div>
        <div className="mx-1 border-r px-2 w-[15%] text-center">
          Expiry Date
        </div>
        <div className="mx-1 px-2 w-[10%] text-center">Action</div>
      </div>
      <div>
        {schedulers?.map((schedule, index) => (
          <div
            key={index + 1}
            className="flex justify-center items-center bg-table-bg px-3 py-5"
          >
            <div className="mx-1 border-r px-2 w-[10%] text-center">
              {index + 1}
            </div>
            <div className="mx-1 border-r px-2 w-[35%] text-center">
              {schedule?.user?.profile?.full_name || schedule?.user?.email}
            </div>
            <div className="mx-1 border-r px-2 w-[20%] text-center">
              {schedule?.created_at ? get_normal_date(schedule.created_at) : ''}
            </div>
            <div className="mx-1 border-r px-2 w-[10%] text-center">
              <span>{schedule?.status?.name}</span>
            </div>
            <div className="mx-1 border-r px-2 w-[15%] text-center">
              {schedule?.expiry_date ? get_date(schedule.expiry_date) : ''}
            </div>
            <div className="mx-1 px-2 w-[10%] text-center">
              <div className="flex w-full items-center justify-evenly">
                <Popconfirm
                  title="Delete this task"
                  placement="bottomLeft"
                  okText="Confirm"
                  okButtonProps={{ style: { background: '#243677' } }}
                  icon={<FaQuestionCircle style={{ color: 'red' }} />}
                  onConfirm={() => {
                    if (schedule.deactivated)
                      activate_task_schedulers(dispatch, schedule?.id);
                    else deactivate_task_schedulers(dispatch, schedule.id);
                  }}
                >
                  <FaToggleOn
                    className="cursor-pointer"
                    size={25}
                    title={''}
                    color={schedule?.deactivated ? 'black' : 'green'}
                  />
                </Popconfirm>
                <FaRegEye
                  className="cursor-pointer"
                  size={25}
                  color="black"
                  onClick={() => onView(schedule?.id)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListSchedule;
