import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setBlueRole,
  createBlueRole,
  updateBlueRole,
  setLoading,
  setUpdate
} from '@/store/slices/blue_role';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_blue_roles = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-blue-roles');

    dispatch(setBlueRole(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setBlueRole([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_blue_roles = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-blue-role', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createBlueRole(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_blue_roles = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-blue-role', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateBlueRole(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
