import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  degrees: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  degrees: [],
  update: false,
  loading: false
};

export const degree = createSlice({
  name: 'degree',
  initialState,
  reducers: {
    setDegree: (state, action: PayloadAction<[]>) => {
      state.degrees = action.payload;
    },
    updateDegree: (state, action: PayloadAction<IDataType>) => {
      state.degrees = [...state.degrees, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const { setDegree, updateDegree, setLoading, setUpdate } =
  degree.actions;

export default degree.reducer;
