import { setTotalIndustry } from '../../store/slices/company_industry';
import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// state
// import { setClients, setTotalClients } from '../../store/slices/client';

export const get_total_industry = async (dispatch: any) => {
	try {
		const response = await axiosInstance.get('/get-total-company-industry');

		dispatch(setTotalIndustry(response.data));
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
};
