import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  ICandidateState,
  IActionApplicant,
  IUserData
} from '../types/candidate';
import { IAggregateType, IGraphState, IPieChart } from '../types/misc';

// Define the initial state using that type
const initialState: ICandidateState = {
  candidates: [],
  statistics: [],
  weekly_statistics: [],
  profile_stats: [],
  candidate: null,
  total_candidates: '',
  gender_aggregate: [],
  current_page: '1',
  last_page: '1',
  loading: false,
  show: false,
  update: false,
  delete: false
};

export const candidate = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setCandidates: (state, action: PayloadAction<IActionApplicant>) => {
      state.candidates = action.payload.data;
      state.current_page = action.payload?.current_page;
      state.last_page = action.payload?.last_page;
    },
    setStattistics: (state, action: PayloadAction<IGraphState[]>) => {
      state.statistics = action.payload;
    },
    setWeeklyStat: (state, action: PayloadAction<IGraphState[]>) => {
      state.weekly_statistics = action.payload;
    },
    setProfileStatistics: (state, action: PayloadAction<IPieChart[]>) => {
      state.profile_stats = action.payload;
    },
    setCandidate: (state, action: PayloadAction<IUserData>) => {
      state.candidate = action.payload;
    },
    setTotalCandidateNumber: (state, action: PayloadAction<string>) => {
      state.total_candidates = action.payload;
    },
    setTotalCandidateGender: (
      state,
      action: PayloadAction<IAggregateType[]>
    ) => {
      state.gender_aggregate = action.payload;
    },

    /*-------------------------------------------------------------------- */
    /*----------------------------- loading ------------------------------ */
    /*-------------------------------------------------------------------- */
    nextPage: (state) => {
      state.current_page = (Number(state.current_page) + 1).toString();
    },
    prevPage: (state) => {
      state.current_page = (Number(state.current_page) - 1).toString();
    },

    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    }
  }
});

export const {
  setCandidates,
  setCandidate,
  setStattistics,
  setWeeklyStat,
  setProfileStatistics,
  setTotalCandidateGender,
  setTotalCandidateNumber,
  nextPage,
  prevPage,
  setLoading,
  setShow
} = candidate.actions;

export default candidate.reducer;
