import React from 'react';

// components
import TextInputBox from '../../../components/elements/forms/TextArea';
import TabPicker from '../../../components/elements/forms/TabPicker';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// types
import { SoftSkills } from '../../../store/types/order';
import { ErrorWarning } from '../../../store/types/misc';

// state
import { get_soft_skills } from '../../../service/api/misc';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

type OrderNameType = {
	onPress: (res: SoftSkills) => void;
};

const SelectSoftSkills: React.FC<OrderNameType> = ({ onPress }) => {
	const dispatch = useAppDispatch();
	const skills = useAppSelector(({ misc }) => misc.soft_skills);
	const [data, dataSet] = React.useState<SoftSkills>({
		soft_skills: [],
		others: ''
	});
	const [error, errorSet] = React.useState<ErrorWarning>('');

	const onSubmitEvent = () => {
		if (data.soft_skills.length < 1) {
			errorSet('warning');
			return;
		}
		onPress(data);
	};

	const onGetSkill = React.useCallback(() => {
		get_soft_skills(dispatch);
	}, [dispatch]);

	React.useEffect(() => {
		onGetSkill();
	}, [onGetSkill]);

	return (
		<div className="md:flex flex-col flex justify-center mt-10">
			<div className="font-abel text-left md:text-[#4E4E4E] md:text-[28px]">
				Select the relevant skills your company needs
			</div>

			<div className="w-full">
				{error && (
					<p className="text-[red] text-right mt-1">
						Please choose skills you need
					</p>
				)}
				<div className="flex flex-wrap max-h-screen w-full my-5">
					{skills.map((item) => (
						<TabPicker
							key={item.id}
							name={item.name}
							id={item.id}
							checked={data.soft_skills.includes(item.id)}
							onChange={(e) => {
								if (data.soft_skills.includes(e)) {
									const newSoftSkills = data.soft_skills.filter(
										(item) => item !== e
									);
									dataSet((prev) => ({ ...prev, soft_skills: newSoftSkills }));
								} else {
									dataSet((prev) => ({
										...prev,
										soft_skills: [...data.soft_skills, e]
									}));
								}
								errorSet('');
							}}
						/>
					))}
				</div>

				<div className="w-full text-[#797979] opacity-50 text-left">
					<TextInputBox
						placeholder="Add more skills"
						value={data.others}
						name="others"
						label="More Skills"
						text_class="h-[200px]"
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							const { name, value } = e.target;
							dataSet((prev) => ({ ...prev, [name]: value }));
						}}
					/>
				</div>

				<div className="flex items-center justify-end mt-10 ">
					<div className="w-[30%]">
						<ButtonComponent
							title="Next"
							textColor="white"
							backgroundColor="#243677"
							onHandler={onSubmitEvent}
							active={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectSoftSkills;
