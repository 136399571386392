import React from 'react';

// types
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import {
  get_profile,
  update_avatar,
  update_dob,
  update_name,
  update_position
} from '@/service/api/auth';
import { get_positions } from '@/service/api/misc';

// navigate
import { useNavigate } from 'react-router-dom';

// constants and icons
import { BiCloudUpload } from 'react-icons/bi';
import user from '../../../assets/images/icons/user.svg';
import { get_normal_date_time } from '@/service/formatter/date';

// components
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import ChangePassword from '../misc/ChangePassword';
import EditInput from '../misc/EditInput';
import EditDate from '../misc/EditDate';
import EditSelect from '../misc/EditSelect';

const DashboardProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useAppSelector(({ auth }) => auth.profile);
  const positions = useAppSelector(({ misc }) => misc.positions);
  const action = useAppSelector(({ navbar }) => navbar.action);
  const [password, passwordSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Profile'));
    dispatch(
      setNavButton({
        button_name: 'Change Password',
        button: profile?.role_id?.toString() === '1' ? true : false
      })
    );
  }, [dispatch, profile?.role_id]);

  const onGetUser = React.useCallback(() => {
    get_profile(dispatch);
    get_positions(dispatch);
  }, [dispatch]);

  const onAction = React.useCallback(() => {
    if (action) passwordSet(true);
  }, [action]);

  React.useEffect(() => {
    onLoad();
    onAction();
    onGetUser();
  }, [onAction, onGetUser, onLoad]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-4">
      <div className="flex flex-wrap justify-between items-center w-full bg-[#e0e7ff] h-full my-6 p-8">
        <div className="w-[20%] relative flex justify-center items-center h-[152px] overflow-hidden">
          <div className="h-[150px] w-[150px] overflow-hidden border-[#567BFF] border-4 rounded-full">
            <img
              src={profile?.profile?.avatar || user}
              alt={profile?.profile?.full_name}
              className="h-[100%] w-full object-cover p-1 rounded-full"
            />
          </div>
          <div className="absolute right-1 bottom-1 rounded-full bg-[white] cursor-pointer">
            <BiCloudUpload
              color="#567BFF"
              size={35}
              onClick={() => document.getElementById('file')?.click()}
            />
          </div>
          <input
            type="file"
            name="file"
            id="file"
            value=""
            hidden={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                update_avatar(e.target.files[0]);
                setTimeout(() => onGetUser(), 400);
              }
            }}
          />
        </div>

        <div className="w-[60%] px-4">
          <EditInput
            title="Name"
            placeholder="Enter name"
            name={profile?.profile?.full_name || ''}
            onUpdate={(res) => update_name(dispatch, res)}
          />

          <p className="text-[17px]">{`Email: ${profile?.email || ''}`}</p>

          <EditSelect
            selected={profile?.profile?.position || null}
            items={positions}
            placeholder="Select Position"
            title="Position"
            onUpdate={(res) => {
              onGetUser();
              update_position(dispatch, res);
            }}
          />

          <EditDate
            placeholder="Select Date"
            title="Date of birth"
            onUpdate={(res) => update_dob(dispatch, res)}
            date={profile?.profile?.dob ? profile.profile.dob : new Date()}
          />

          <p className="text-[17px] capitalize">
            {`Joined: ${
              profile?.created_at
                ? get_normal_date_time(profile.created_at)
                : ''
            }`}
          </p>
        </div>

        <div className="border-[#4E4E4E] w-[20%] flex items-end h-[152px] text-center overflow-hidden">
          <ButtonComponent
            title="View Roles"
            onHandler={() => navigate('/roles-and-permission')}
            backgroundColor="#243677"
            active={true}
          />
        </div>
      </div>
      <ChangePassword visible={password} onDone={() => passwordSet(false)} />
    </div>
  );
};

export default DashboardProfile;
