import React from 'react';

// components
import { Modal } from 'antd';
import TextInput from '@/components/elements/forms/TextInput';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';

// types

// constants and icons
import { FaMailBulk } from 'react-icons/fa';

// types
import RichTextInputBox from '@/components/elements/forms/RichTextInputBox';
import { IMailerCandidate } from '@/store/types/mailer';

type IMessageBody = {
  title: string;
  visible: boolean;
  children?: React.ReactNode;
  data: IMailerCandidate;
  onMessageChange: {
    mChange: (data: string) => void;
    sChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
  };
  onDone: () => void;
  onCancel: () => void;
};

const MessageBody: React.FC<IMessageBody> = ({
  data,
  title,
  visible,
  children,
  onDone,
  onMessageChange
}) => {
  return (
    <Modal
      open={visible}
      closeIcon={false}
      footer={null}
      closable={false}
      centered={true}
      width="90%"
    >
      <div className="flex h-full">
        <div className="w-[40%] px-2">
          <p className="my-4 font-abel font-bold text-[20px]">{title}</p>

          <div className="my-4 w-full">
            <TextInput
              type="text"
              name="subject"
              onChange={onMessageChange.sChange}
              value={data.subject}
              icon={<FaMailBulk size={20} color="#567BFF" />}
              placeholder="Enter Mail Subject"
            />
          </div>

          {children}
        </div>

        <div className="w-[60%] px-2 h-full">
          <div className="my-4 flex">
            <RichTextInputBox
              container="w-full"
              placeholder="Enter Mail Body"
              value={data.message}
              onChange={onMessageChange.mChange}
            />
          </div>
        </div>
      </div>

      <div className="mb-4 mt-4 w-full flex justify-between">
        <div className="w-[40%]">
          <ButtonComponent title="Send Mail" onHandler={onDone} active={true} />
        </div>
        <div className="w-[40%]">
          <ButtonComponent
            title="Cancel"
            onHandler={onDone}
            backgroundColor="red"
            active={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MessageBody;
