import React from 'react';

// components
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// state
import { useAppSelector } from '../../../store/hooks';
import { get_normal_date_time } from '../../../service/formatter/date';

type ISelectOrder = {
	selected: string[];
	onSelect: (data: { name: string; label: string }) => void;
	onRemove: (data: { name: string; label: string }) => void;
};

const SelectOrder: React.FC<ISelectOrder> = ({
	selected,
	onSelect,
	onRemove
}) => {
	const orders = useAppSelector(({ order }) => order.all_client_orders);

	return (
		<div className="overflow-y-auto">
			{orders.map((order) => (
				<div
					key={order.slug}
					className={`flex items-center  border-b border-[#243677] py-3`}
				>
					<div className="w-[30%] text-center capitalize">
						{order?.position?.name}
					</div>

					<div className="capitalize w-[20%] text-center">
						{order?.created_at && get_normal_date_time(order?.created_at)}
					</div>

					<div className="capitalize w-[30%] text-center">
						{order?.type?.name}
					</div>

					<div className="capitalize flex justify-center items-center w-[15%]">
						<ButtonComponent
							title={selected.includes(order?.slug) ? 'Added' : 'Add'}
							onHandler={() => {
								if (selected.includes(order?.slug))
									onRemove({ label: order.slug, name: order.position?.name });
								else
									onSelect({ label: order.slug, name: order.position?.name });
							}}
							backgroundColor={selected.includes(order?.slug) ? 'red' : ''}
							active={true}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default SelectOrder;
