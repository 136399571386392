import React from 'react';

// components
import { Popconfirm } from 'antd';

// constants
import {
  MdOutlineRemoveRedEye,
  MdOutlineRestoreFromTrash,
  MdOutlineRecycling
} from 'react-icons/md';
import { FaQuestionCircle } from 'react-icons/fa';

// types and state
import { ITask } from '@/store/types/task';
import { delete_task, recycle_task } from '@/service/api/task';

type IAction = {
  item: ITask;
  navigate: Function;
  dispatch: Function;
};

const Action: React.FC<IAction> = ({ item, navigate, dispatch }) => {
  return (
    <div className="w-full flex items-center justify-evenly">
      <MdOutlineRemoveRedEye
        size={25}
        color="#243677"
        className="cursor-pointer"
        onClick={() => navigate(`/payable/${item?.slug}`)}
      />
      {!item?.deleted ? (
        <Popconfirm
          title="Delete this task"
          placement="bottomLeft"
          okText="Confirm"
          okButtonProps={{ style: { background: '#243677' } }}
          icon={<FaQuestionCircle style={{ color: 'red' }} />}
          onConfirm={() => delete_task(dispatch, item?.slug)}
        >
          <MdOutlineRestoreFromTrash
            className="cursor-pointer"
            size={25}
            color="#fa837a"
          />
        </Popconfirm>
      ) : (
        <Popconfirm
          title="Delete this task"
          placement="bottomLeft"
          okText="Confirm"
          okButtonProps={{ style: { background: '#243677' } }}
          icon={<FaQuestionCircle style={{ color: 'red' }} />}
          onConfirm={() => recycle_task(dispatch, item?.slug)}
        >
          <MdOutlineRecycling
            className="cursor-pointer"
            size={25}
            color="black"
          />
        </Popconfirm>
      )}
    </div>
  );
};

export default Action;
