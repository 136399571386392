import React from 'react';

// components
import MultiSelect from '../../../components/ui/form/MultiSelect';
import Collapse from '../../../components/ui/collapse';

// constant
import { BiPlus } from 'react-icons/bi';
// import { FaPlus } from 'react-icons/fa';

// state
import { useAppDispatch } from '../../../store/hooks';
import {
	get_report_statistics,
	update_report_candidate_characteristics,
	update_report_candidate_disqualified,
	update_report_candidate_note,
	update_report_comment,
	update_report_limitation,
	update_report_overview,
	update_report_purpose,
	update_report_results,
	update_report_sourcing,
	update_report_summary
} from '../../../service/api/report';
import UpdatePurpose from './UpdatePurpose';
import UpdateReportSummary from './UpdateReportSummary';
import UpdateReportSourcing from './UpdateReportSourcing';
import UpdateReportLimitation from './UpdateReportLimitation';
import UpdateReportComment from './UpdateReportComment';
import UpdateOverview from './UpdateOverview';
import UpdateReportCandidateNote from './UpdateReportCandidateNote';
import UpdateReportCandidateCharacteristics from './UpdateReportCandidateCharacteristics';
import UpdateReportCandidateResult from './UpdateReportCandidateResult';
import UpdateReportCandidateDisqualified from './UpdateReportCandidateDisqualified';

type IDisplayCandidate = {
	id: string;
	show: boolean;
	onCancel: () => void;
};

const ShowReportStat: React.FC<IDisplayCandidate> = ({
	id,
	show,
	onCancel
}) => {
	const dispatch = useAppDispatch();

	const onLoad = React.useCallback(() => {
		if (id) get_report_statistics(dispatch, id);
	}, [dispatch, id]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<MultiSelect
			open={show}
			search=""
			onSubmit={() => null}
			onSearch={() => null}
			onCancel={onCancel}
		>
			<div className="flex flex-1 overflow-y-scroll overflow-x-hidden flex-col">
				<Collapse
					title="Overview"
					icon={<BiPlus size={23} />}
					children={
						<UpdateOverview
							onSubmit={(data) => {
								if (data) {
									update_report_overview(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Purpose"
					icon={<BiPlus size={23} />}
					children={
						<UpdatePurpose
							onSubmit={(data) => {
								if (data) {
									update_report_purpose(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="General Summary"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportSummary
							onSubmit={(data) => {
								if (data) {
									update_report_summary(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Here Are A Few Of The Characteristics We Noticed In The Prospects"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportCandidateCharacteristics
							onSubmit={(data) => {
								if (data) {
									update_report_candidate_characteristics(dispatch, {
										id,
										name: data
									});
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Sourcing"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportSourcing
							onSubmit={(data) => {
								if (data) {
									update_report_sourcing(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Candidates Result"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportCandidateResult
							onSubmit={(data) => {
								if (data) {
									update_report_results(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Candidates were disqualified for the following reasons:"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportCandidateDisqualified
							onSubmit={(data) => {
								if (data) {
									update_report_candidate_disqualified(dispatch, {
										id,
										name: data
									});
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Candidates Note"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportCandidateNote
							onSubmit={(data) => {
								if (data) {
									update_report_candidate_note(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Limitations"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportLimitation
							onSubmit={(data) => {
								if (data) {
									update_report_limitation(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>

				<Collapse
					title="Comment"
					icon={<BiPlus size={23} />}
					children={
						<UpdateReportComment
							onSubmit={(data) => {
								if (data) {
									update_report_comment(dispatch, { id, name: data });
									setTimeout(() => onLoad(), 400);
								}
							}}
						/>
					}
				/>
			</div>
		</MultiSelect>
	);
};

export default ShowReportStat;
