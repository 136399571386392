import React from 'react';

// components
import { Drawer } from 'antd';
import Collapse from '../../../components/ui/collapse';
import UpdateNoun from './UpdateNoun';
import UpdateProfessionalSummary from './UpdateProfessionalSummary';
import UpdatePros from './UpdatePros';
import UpdateCons from './UpdateCons';
// import UpdateComment from './UpdateComment';
import MiniButton from '../../../components/elements/forms/MiniButton';
import TextInputBox from '../../../components/elements/forms/TextInputBox';
import ShortDescription from './ShortDescription';
import UpdateResult from './UpdateResult';
import avatar_user from '../../../assets/images/temporary/user.png';

// icons
import { GrFormClose, GrAdd } from 'react-icons/gr';

// state
import {
	IGetCandidateReport,
	IRoleCandidateUpdate
} from '../../../store/types/report_role';
import {
	update_expected_pay,
	update_notice_period,
	update_sumary
} from '../../../service/api/report_role';
import { useAppDispatch } from '../../../store/hooks';

type IAddCandidateDetail = {
	open: boolean;
	candidate: IGetCandidateReport | null;
	onClose: () => void;
	onRefresh: () => void;
};

const AddCandidateDetail: React.FC<IAddCandidateDetail> = ({
	open,
	candidate,
	onRefresh,
	onClose
}) => {
	const dispatch = useAppDispatch();
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<IRoleCandidateUpdate>({
		id: '',
		name: ''
	});

	const onLoad = React.useCallback(() => {
		if (candidate?.id) {
			dataSet((prev) => ({
				...prev,
				id: candidate.id.toString(),
				name: candidate?.summary
			}));
		}
	}, [candidate?.id, candidate?.summary]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<Drawer
			width={640}
			placement="right"
			maskClosable={false}
			closable={false}
			onClose={onClose}
			open={open}
		>
			<div className="pxx-3">
				<div className="flex w-full justify-between items-center">
					<div className="">About {candidate?.user?.profile?.first_name}</div>
					<div className="">
						<div className="p-1 bg-[#CDD8FE] rounded-full cursor-pointer">
							<GrFormClose size={20} color="#243677" onClick={onClose} />
						</div>
					</div>
				</div>

				<div className="border border-[#E8E7E7] rounded-md py-8 px-2 my-2">
					<div className="w-full flex items-center justify-center mb-2">
						<div className="mx-2 w-[20%]">
							<div className=" border rounded-full h-[100px] w-[100px] overflow-hidden">
								<img
									src={
										candidate?.user?.profile && candidate?.user?.profile?.avatar
											? candidate?.user?.profile?.avatar
											: avatar_user
									}
									// src={candidate?.user?.profile?.avatar}
									alt={candidate?.user?.profile?.first_name}
									className="h-[100px] w-[100px] object-cover rounded-full"
								/>
							</div>
						</div>

						<div className="mx-2 w-[75%]">
							{show ? (
								<TextInputBox
									name="name"
									placeholder="Candidate Summary"
									value={data.name}
									onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
										dataSet((prev) => ({ ...prev, name: e.target.value }))
									}
								/>
							) : (
								data?.name
							)}
						</div>
					</div>
					<div className="w-full flex justify-end items-center">
						<div className="w-[15%]">
							<MiniButton
								title={show ? 'Update' : 'Edit'}
								backgroundColor="#CDD8FE"
								textColor="#243677"
								onHandler={() => {
									if (show && candidate?.id) {
										// edit
										update_sumary(dispatch, data);
										setTimeout(() => onRefresh(), 400);
										showSet(false);
									} else {
										showSet(true);
									}
								}}
							/>
						</div>
					</div>
				</div>

				<div className="my-3 w-full flex items-center justify-between">
					<ShortDescription
						title="Expected Pay - "
						name={candidate?.expected_pay || ''}
						onSave={(res) => {
							if (res && candidate?.id)
								update_expected_pay(dispatch, {
									id: candidate?.id?.toString(),
									name: res
								});
						}}
					/>

					<ShortDescription
						title="Notice Period - "
						name={candidate?.notice_period || ''}
						onSave={(res) => {
							if (res && candidate?.id)
								update_notice_period(dispatch, {
									id: candidate?.id?.toString(),
									name: res
								});
						}}
					/>
					<div className="bg-[#F4F6FD] text-[#243677] px-2 py-3 text-[10px] rounded-sm">
						<span>Status - </span>
						<span className="font-bold">Ready to work</span>
					</div>
					<div className="bg-[#F4F6FD] text-[#243677] px-2 py-3 text-[10px] rounded-sm">
						<span>Location - </span>
						<span className="font-bold">Lekki phase 1</span>
					</div>
				</div>

				<div className="my-3">
					<Collapse
						title="Add Assessment Link"
						icon={<GrAdd color="#243677" size={20} />}
						children={
							<UpdateResult
								id={candidate?.id ? candidate?.id : ''}
								pros={candidate?.result ? candidate?.result : ''}
								onRefresh={onRefresh}
							/>
						}
					/>
				</div>

				<div className="my-3">
					<Collapse
						title="Add Alias/Noun"
						icon={<GrAdd color="#243677" size={20} />}
						children={
							<UpdateNoun
								id={candidate?.id ? candidate?.id : ''}
								noun={candidate?.noun ? candidate?.noun : ''}
								onRefresh={onRefresh}
							/>
						}
					/>
				</div>

				<div className="my-3">
					<Collapse
						title="Add Professional Summary"
						icon={<GrAdd color="#243677" size={20} />}
						children={
							<UpdateProfessionalSummary
								id={candidate?.id ? candidate?.id : ''}
								professional={
									candidate?.professional_summary
										? candidate?.professional_summary
										: ''
								}
								onRefresh={onRefresh}
							/>
						}
					/>
				</div>

				<div className="my-3">
					<Collapse
						title="Add Pros"
						icon={<GrAdd color="#243677" size={20} />}
						children={
							<UpdatePros
								id={candidate?.id ? candidate?.id : ''}
								pros={candidate?.pros ? candidate?.pros : ''}
								onRefresh={onRefresh}
							/>
						}
					/>
				</div>

				<div className="my-3">
					<Collapse
						title="Add Cons"
						icon={<GrAdd color="#243677" size={20} />}
						children={
							<UpdateCons
								id={candidate?.id ? candidate?.id : ''}
								cons={candidate?.cons ? candidate?.cons : ''}
								onRefresh={onRefresh}
							/>
						}
					/>
				</div>

				{/* <div className="my-3">
					<Collapse
						title="Add Comment"
						icon={<GrAdd color="#243677" size={20} />}
						children={
							<UpdateComment
								id={candidate?.id ? candidate?.id : ''}
								comment={candidate?.comment ? candidate?.comment : ''}
								onRefresh={onRefresh}
							/>
						}
					/>
				</div> */}
			</div>
		</Drawer>
	);
};

export default AddCandidateDetail;
