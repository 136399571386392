import React from 'react';
// import type { MenuProps } from 'antd';
import { Layout } from 'antd';
import SideBar from './sidebar';
import ContentComponent from './content';
import { getUser } from '../service/api/auth';
import { useAppDispatch } from '../store/hooks';

type ThemeProps = {
	children: React.ReactNode;
};

const Theme: React.FC<ThemeProps> = ({ children }) => {
	const dispatch = useAppDispatch();
	const onLoad = React.useCallback(() => {
		getUser(dispatch);
	}, [dispatch]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);
	return (
		<Layout hasSider className="relative h-[100vh] w-full">
			<SideBar />
			<Layout style={{ marginLeft: 0 }} className="h-[100vh]">
				<ContentComponent>{children}</ContentComponent>
			</Layout>
		</Layout>
	);
};

export default Theme;
