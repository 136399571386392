import React from 'react';

// components
import PieChart from '@/components/ui/graph/PieChart';

// hooks
import { useAppSelector } from '@/store/hooks';

const CandidateProfileStatistic = () => {
  const statistics = useAppSelector(({ candidate }) => candidate.profile_stats);

  return (
    <div className="w-[40%] h-[35vh]">
      <PieChart data={statistics} />
    </div>
  );
};

export default CandidateProfileStatistic;
