import React from 'react';

// components
import { Col, Divider, Drawer, Modal, Row } from 'antd';
import TextArea from '../../../components/elements/forms/TextArea';
import SelectInput from '../../../components/elements/forms/SelectInput';

// types
import { ICOMPLAIN } from '../../../store/types/complains';

// constants and icons
import { notification } from 'antd';
import { MdSend } from 'react-icons/md';
import { get_normal_date_time } from '../../../service/formatter/date';
import {
	BsHourglassSplit,
	BsFillCheckCircleFill,
	BsXCircleFill
} from 'react-icons/bs';

// types
import { IDATA } from '../../../store/types/misc';

// state
import {
	reply_mail,
	update_contact_us,
	get_contact_us
} from '../../../service/api/complains_and_contact';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Button from '../../../components/elements/forms/Button';

interface DescriptionItemProps {
	title: string;
	content: React.ReactNode;
	onClick?: () => void;
	styles?: string;
}

type IDescriptionProps = {
	show: boolean;
	detail: ICOMPLAIN | null;
	onClose: () => void;
};

type IDATASTATE = {
	message: string;
	status: string;
	id: string | number;
};

const status: IDATA[] = [
	{ id: 1, name: 'Success' },
	{ id: 2, name: 'Failed' }
];

const DescriptionItem = ({
	title,
	content,
	onClick,
	styles
}: DescriptionItemProps) => (
	<div
		className={`site-description-item-profile-wrapper font-abel text-[20px] ${styles}`}
		onClick={onClick}
	>
		<p className={`site-description-item-profile-p-label`}>
			{title ? title : ''}:
		</p>
		{content || ''}
	</div>
);

const DrawerOption: React.FC<IDescriptionProps> = ({
	show,
	detail,
	onClose
}) => {
	const dispatch = useAppDispatch();
	const complain = useAppSelector(({ complain }) => complain.complain);
	const [open, openSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<IDATASTATE>({
		message: '',
		status: '',
		id: ''
	});

	const onLoad = React.useCallback(() => {
		if (detail?.seen === '0') {
			update_contact_us(dispatch, detail.id.toString());
		}
		if (detail?.id) {
			get_contact_us(dispatch, detail?.id.toString());
		}
	}, [detail?.id, detail?.seen, dispatch]);

	const setState = React.useCallback(() => {
		if (detail?.id) {
			dataSet((prev) => ({ ...prev, id: detail.id.toString() }));
		}
	}, [detail?.id]);

	React.useEffect(() => {
		onLoad();
		setState();
	}, [onLoad, setState]);

	const onClear = () => {
		dataSet(() => ({ message: '', status: '', id: '' }));
	};

	return !detail ? (
		<></>
	) : (
		<Drawer
			width={640}
			placement="right"
			closable={false}
			onClose={onClose}
			open={show}
		>
			<p className="site-description-item-profile-p">Complain | Contact</p>
			<Row>
				<Col span={12}>
					<p className="site-description-item-profile-p flex capitalize">
						Prospective:{' '}
						{complain?.prospective?.name || detail?.prospective?.name || ''}
					</p>
				</Col>
				<Col span={12} className="flex justify-end">
					{Number(complain?.status || detail.status) === 0 ? (
						<BsHourglassSplit color="#f4b304" size={20} />
					) : Number(complain?.status || detail.status) === 1 ? (
						<BsFillCheckCircleFill color="#047c34" size={20} />
					) : (
						<BsXCircleFill color="#b80607" size={20} />
					)}
				</Col>
			</Row>

			<Row>
				<Col span={24} className="flex justify-end">
					<DescriptionItem
						title="Ticket ID"
						content={complain?.ticket_id || detail?.ticket_id}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<DescriptionItem
						title="Full Name"
						content={`${detail?.first_name || detail?.first_name}  ${
							complain?.last_name || detail?.last_name
						}`}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<DescriptionItem
						title="LinkedIn"
						content={complain?.linkedin || detail?.linkedin}
					/>
				</Col>
				<Col span={12}>
					<DescriptionItem
						title="Phone Number"
						content={complain?.phone || detail?.phone}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<DescriptionItem
						title="Email"
						styles="cursor-pointer"
						content={complain?.email || detail?.email}
						onClick={() => openSet(true)}
					/>
				</Col>
			</Row>

			<Divider />
			<Row>
				<Col span={12}>
					<DescriptionItem
						title="Company Name"
						content={complain?.company_name || detail?.company_name || ''}
					/>
				</Col>
				<Col span={12}>
					<DescriptionItem
						title="Company Industry"
						content={
							complain?.company_industry || detail?.company_industry || ''
						}
					/>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={24}>
					<DescriptionItem
						title="Message"
						content={complain?.message || detail?.message}
					/>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={12}>
					<DescriptionItem
						title="Logged at"
						content={get_normal_date_time(
							complain?.created_at || detail?.created_at
						)}
					/>
				</Col>
				<Col span={12}>
					<DescriptionItem
						title="Resolved at"
						content={get_normal_date_time(
							complain?.updated_at || detail?.updated_at
						)}
					/>
				</Col>
			</Row>

			<div className="my-4 flex justify-end text-right">
				<div className="w-[50%]">
					<Button title="Click to Continue" onClick={() => openSet(true)} />
				</div>
			</div>

			<Modal
				centered
				open={open}
				onCancel={() => openSet(false)}
				footer={[
					<Button
						key="1"
						title="Send Message"
						icon={<MdSend className="mx-2" />}
						onClick={async () => {
							const response = await reply_mail(data);
							onClear();
							notification.success({
								message: response.data
							});
							openSet(false);
						}}
					/>
				]}
			>
				<SelectInput
					name="status"
					items={status}
					onResponse={(name, res) =>
						dataSet((prev) => ({ ...prev, [name]: res }))
					}
					error=""
					container="mt-10 mb-5"
				/>
				<TextArea
					label="Email Message"
					text_class="h-[170px]"
					value={data.message}
					name="message"
					placeholder="Please Input Message here"
					onChange={(e) =>
						dataSet((prev) => ({ ...prev, message: e.target.value }))
					}
				/>
			</Modal>
		</Drawer>
	);
};

export default DrawerOption;
