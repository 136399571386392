import React from 'react';

// components

// constants and icons
// import { FaTrashAlt } from 'react-icons/fa';
import avatar_user from '../../../assets/images/temporary/user.png';

// types
import { IClientUser } from '../../../store/types/client';
import { useAppSelector } from '@/store/hooks';
import { Skeleton } from 'antd';

type ITableLine = {
	item: IClientUser;
	onView: (item: string) => void;
};

const TableLine: React.FC<ITableLine> = ({ item, onView }) => {
	const loading = useAppSelector(({ client }) => client.loading);

	return (
		<div className="flex justify-center items-center px-3 py-2 mb-3 border-b border-[#BCBCBC]">
			<div
				className="mx-1 border-r px-2 w-2/12 text-center cursor-pointer"
				onClick={() => onView(item.client_slug)}
			>
				<img
					src={
						item?.profile && item?.profile?.avatar
							? item?.profile?.avatar
							: avatar_user
					}
					alt="user"
					className="w-[50px] h-[50px] rounded-full object-cover border border-[#4E4E4E]  mx-auto"
				/>
			</div>
			<div
				className="mx-1 border-r px-2 w-3/12 text-center cursor-pointer"
				onClick={() => onView(item.client_slug)}
			>
				{item?.profile?.organization_name}
			</div>
			<div
				className="mx-1 border-r px-2 w-3/12 text-center cursor-pointer"
				onClick={() => onView(item.client_slug)}
			>
				{item?.profile?.full_name}
			</div>
			<div className="mx-1 border-r px-2 w-3/12 text-center">{item?.email}</div>
			<div className="mx-1 px-2 w-2/12 text-center flex items-center justify-center">
				{loading ? (
					<Skeleton.Button block={true} active={true} />
				) : (
					<span
						className="underline text-[#319759] cursor-pointer mx-3"
						onClick={() => onView(item.client_slug)}
					>
						View
					</span>
				)}

				{/* <FaTrashAlt size={17} color="#B10E0E" className="cursor-pointer" /> */}
			</div>
		</div>
	);
};

export default TableLine;
