import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  payment_types: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  payment_types: [],
  update: false,
  loading: false
};

export const payment_type = createSlice({
  name: 'payment_type',
  initialState,
  reducers: {
    setPaymentTypes: (state, action: PayloadAction<IDataType[]>) => {
      state.payment_types = action.payload;
    },
    createPaymentType: (state, action: PayloadAction<IDataType>) => {
      state.payment_types = [action.payload, ...state.payment_types];
    },
    updatePaymentType: (state, action: PayloadAction<IDataType>) => {
      state.payment_types = state.payment_types.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setPaymentTypes,
  createPaymentType,
  updatePaymentType,
  setLoading,
  setUpdate
} = payment_type.actions;

export default payment_type.reducer;
