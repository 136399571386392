import React from 'react';

// components
import ButtonComponent from '../../components/elements/forms/ButtonComponent';

// state
import { useAppSelector } from '../../store/hooks';
import { useDispatch } from 'react-redux';
import {
	setButtonAction,
	setButtonActionFalse
} from '../../store/slices/navbar';

const BreadCrumb: React.FC = () => {
	const dispatch = useDispatch();
	const title = useAppSelector(({ navbar }) => navbar.title);
	const button_name = useAppSelector(({ navbar }) => navbar.button_name);
	const button = useAppSelector(({ navbar }) => navbar.button);

	return title ? (
		<div className="flex justify-between items-center px-10 my-7">
			<div className="text-[30px] font-abel capitalize">{title || ''}</div>

			<div className="flex">
				<div className="w-[130px]">
					{button && (
						<ButtonComponent
							title={button_name}
							onHandler={() => {
								dispatch(setButtonAction());
								setTimeout(() => dispatch(setButtonActionFalse()), 300);
							}}
							active={true}
						/>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export default BreadCrumb;
