import React from 'react';

// components
import AddCandidateDetail from './AddCandidateDetail';

// constants and icons
import {
	MdOutlineZoomOutMap,
	MdOutlineClose,
	MdOutlineDelete
} from 'react-icons/md';
import { BsArrowUpCircleFill } from 'react-icons/bs';
import avatar_user from '../../../assets/images/temporary/user.png';

// state and types
import { IOrderType } from '../../../store/types/order';
import { IGetCandidateReport } from '../../../store/types/report_role';
import { useAppDispatch } from '../../../store/hooks';
import {
	remove_candidate_report,
	update_role_candidate_heirarchy
} from '../../../service/api/report';
import { get_role_candidate } from '../../../service/api/report_role_candidate';

type IAddCandidateReport = {
	report_role_id: string;
	order: IOrderType;
	onReload: boolean;
	onDelete: (data: string) => void;
	onAddCandidate: (data: string) => void;
};

const AddCandidateReport: React.FC<IAddCandidateReport> = ({
	order,
	report_role_id,
	onReload,
	onDelete,
	onAddCandidate
}) => {
	const dispatch = useAppDispatch();
	const [show, showSet] = React.useState<boolean>(false);
	const [user, userSet] = React.useState<IGetCandidateReport | null>(null);
	const [candidates, candidateSet] = React.useState<IGetCandidateReport[]>([]);

	const onLoad = React.useCallback(async () => {
		const get_candidates = await get_role_candidate(report_role_id);
		if (get_candidates.length) {
			candidateSet(get_candidates);
		}
	}, [report_role_id]);

	const onReloadCandidate = React.useCallback(() => {
		if (onReload) onLoad();
	}, [onLoad, onReload]);

	React.useEffect(() => {
		onLoad();
		onReloadCandidate();
	}, [onLoad, onReloadCandidate]);

	return (
		<div className="w-[33%] flex items-center justify-center flex-col rounded-md overflow-hidden my-4">
			<div className="bg-[#243677] font-bold text-[16px] relative text-white w-full capitalize text-center py-2">
				{order?.position?.name}
				<div className="flex absolute top-0 right-2 py-2">
					<MdOutlineZoomOutMap size={20} className="mx-1 cursor-pointer" />
					<MdOutlineClose
						size={20}
						className="mx-1 cursor-pointer"
						onClick={() => onDelete(order?.slug)}
					/>
				</div>
			</div>
			<div className="w-full bg-white min-h-[200px] max-h-[200px] overflow-hidden overflow-y-auto">
				{candidates.map((candidate, index) => (
					<div
						key={candidate.id}
						className="flex items-center justify-evenly my-2 mx-3"
					>
						<div className="mr-3 w-[15%]">
							<img
								src={
									candidate?.user?.profile && candidate?.user?.profile?.avatar
										? candidate?.user?.profile?.avatar
										: avatar_user
								}
								alt={candidate?.user?.profile?.last_name}
								className="w-[40px] h-[40px] rounded-full"
							/>
						</div>
						<div className="capitalize w-[45%]">
							{`${candidate?.user?.profile?.first_name || ''} ${
								candidate?.user?.profile?.last_name || ''
							}`}
						</div>
						<div className="text-center capitalize w-[40%] flex items-center justify-evenly">
							{index !== 0 && (
								<BsArrowUpCircleFill
									className="cursor-pointer"
									onClick={() => {
										if (Number(candidate.level) - 1 > 0) {
											update_role_candidate_heirarchy(dispatch, {
												report_role_candidate_id: candidate?.id?.toString(),
												level: (Number(candidate.level) - 1).toString()
											});
										}
										setTimeout(() => onLoad(), 400);
									}}
								/>
							)}

							<div
								onClick={() => {
									userSet(candidate);
									showSet(true);
								}}
								className="bg-[#243677] text-white px-1 py-1 cursor-pointer rounded-sm"
							>
								Add Details
							</div>
							<MdOutlineDelete
								size={20}
								color="red"
								onClick={() =>
									remove_candidate_report(dispatch, {
										report_role_id,
										email: candidate?.user?.email
									})
								}
								className="cursor-pointer"
							/>
						</div>
					</div>
				))}
			</div>

			<div
				onClick={() => onAddCandidate(report_role_id)}
				className="bg-[#243677] text-white capitalize w-full text-center py-2 cursor-pointer text-[16px]"
			>
				Add Candidate
			</div>

			{show && user && (
				<AddCandidateDetail
					open={show}
					candidate={user}
					onRefresh={onLoad}
					onClose={() => {
						userSet(null);
						showSet(false);
					}}
				/>
			)}
		</div>
	);
};

export default AddCandidateReport;
