import React from 'react';

// components
import MultiSelect from '../../../components/ui/form/MultiSelect';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// types and state
import { save_report_role } from '../../../service/api/report';
import { get_client_order } from '../../../service/api/order';
import { get_normal_date_time } from '../../../service/formatter/date';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

type IDisplayCandidate = {
	id: string;
	mail: string;
	show: boolean;
	selected: string[];
	onClose: () => void;
	onSubmit: () => void;
};

const AddOrder: React.FC<IDisplayCandidate> = ({
	id,
	show,
	mail,
	selected,
	onClose,
	onSubmit
}) => {
	const dispatch = useAppDispatch();
	const orders = useAppSelector(({ order }) => order.all_client_orders);

	const [search, searchSet] = React.useState<string>('');

	const onLoad = React.useCallback(() => {
		if (mail) get_client_order(dispatch, mail);
	}, [dispatch, mail]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<MultiSelect
			open={show}
			search={search}
			onSubmit={onSubmit}
			onSearch={(e) => searchSet(e)}
			onCancel={onClose}
		>
			<div className="flex flex-1 overflow-y-scroll overflow-x-hidden flex-col">
				{orders.map((order) => (
					<div
						key={order.slug}
						className={`flex items-center  border-b border-[#243677] py-3 ${
							selected.includes(order?.slug) ? 'bg-[#CDD8FE]' : 'bg-[white]'
						}`}
					>
						<div className="w-[30%] text-center capitalize">
							{order?.position?.name}
						</div>

						<div className="capitalize w-[20%] text-center">
							{order?.created_at && get_normal_date_time(order?.created_at)}
						</div>

						<div className="capitalize w-[30%] text-center">
							{order?.type?.name}
						</div>

						<div className="capitalize flex justify-center items-center w-[15%]">
							{selected.includes(order?.slug) ? (
								<ButtonComponent
									title="Added"
									onHandler={() => null}
									backgroundColor="red"
									active={true}
								/>
							) : (
								<ButtonComponent
									title="Add"
									onHandler={() => {
										save_report_role(dispatch, {
											report_id: id,
											order_slug: order?.slug
										});
										setTimeout(() => onSubmit(), 400);
									}}
									backgroundColor="#243677"
									active={true}
								/>
							)}
						</div>
					</div>
				))}
			</div>
		</MultiSelect>
	);
};

export default AddOrder;
