import React from 'react';

// components
import CustomSelect from '../../../components/elements/forms/CustomSelect';
import {
	IDataType,
	ISavingSingleEmail,
	ISavingSingleEmailError
} from '../../../store/types/misc';

type IInputUpdate = {
	label: string;
	name: string;
	items: IDataType[];
	email: string;
	onSelect: (data: ISavingSingleEmail) => void;
};

const SelectUpdate: React.FC<IInputUpdate> = ({
	label,
	email,
	name,
	items,
	onSelect
}) => {
	const [data, dataSet] = React.useState<ISavingSingleEmail>({
		client_slug: '',
		name: ''
	});
	const [err, errSet] = React.useState<ISavingSingleEmailError>({
		client_slug: '',
		name: ''
	});

	const onLoad = React.useCallback(() => {
		dataSet((prev) => ({
			...prev,
			client_slug: email,
			name: name
		}));
		errSet({ client_slug: '', name: '' });
	}, [email, name]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<>
			<label className="text-[#333333] text-sm pb-4">{label}</label>

			<CustomSelect
				items={items}
				name="name"
				placeholder={label}
				status={err.name}
				selected={data.name?.toString()}
				onSelect={(_name, value) =>
					onSelect({ ...data, name: value?.toString() })
				}
			/>
		</>
	);
};

export default SelectUpdate;
