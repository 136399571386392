import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  companies: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  companies: [],
  update: false,
  loading: false
};

export const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<[]>) => {
      state.companies = action.payload;
    },
    updateCompany: (state, action: PayloadAction<IDataType>) => {
      state.companies = [...state.companies, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const { setCompany, updateCompany, setLoading, setUpdate } =
  company.actions;

export default company.reducer;
