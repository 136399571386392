import React from 'react';

// components
import { Modal } from 'antd';
import TextInput from '@/components/elements/forms/TextInput';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';

// types

// constants and icons
import { MdOutlineTask } from 'react-icons/md';

// types
import { useAppDispatch } from '@/store/hooks';
import { ICreateTask, ICreateTaskError } from '@/store/types/task';
import { create_task } from '@/service/api/task';

type IChangePassword = {
  visible: boolean;
  onDone: () => void;
};

const Createtask: React.FC<IChangePassword> = ({ visible, onDone }) => {
  const dispatch = useAppDispatch();
  const [data, dataSet] = React.useState<ICreateTask>({
    name: ''
  });

  const [err, errSet] = React.useState<ICreateTaskError>({
    name: ''
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: '' }));
  };

  const onClear = () => dataSet((prev) => ({ name: '' }));

  return (
    <Modal
      open={visible}
      closeIcon={false}
      footer={null}
      closable={false}
      centered={true}
    >
      <p className="my-4 font-abel font-bold text-[20px]">Create Tasks</p>

      <div className="my-4 flex">
        <div className="w-full">
          <TextInput
            type="text"
            name="name"
            onChange={onChange}
            status={err.name}
            value={data.name}
            icon={<MdOutlineTask size={20} color="#567BFF" />}
            placeholder="Enter Task Scheduler Name"
          />
        </div>
      </div>

      <div className="mb-4 mt-8 w-full flex justify-between">
        <div className="w-[45%]">
          <ButtonComponent
            title="Create Task"
            onHandler={() => {
              if (data.name.length) {
                create_task(dispatch, data);
                onClear();
                onDone();
              }
            }}
            active={true}
          />
        </div>
        <div className="w-[45%]">
          <ButtonComponent
            title="Cancel"
            onHandler={() => {
              onClear();
              onDone();
            }}
            backgroundColor="red"
            active={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Createtask;
