import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ICOMPLAINSTATUS, ICOMPLAINRESPONSE, ICOMPLAIN } from '../types/complains';

// Define the initial state using that type
const initialState: ICOMPLAINSTATUS = {
	complains: [],
	complain: null,
	current_page: 1,
	last_page: 1
};

export const complain = createSlice({
	name: 'complain',
	initialState,
	reducers: {
		setComplains: (state, action: PayloadAction<ICOMPLAINRESPONSE>) => {
			state.complains = action.payload.data;
			state.current_page = action.payload?.current_page;
			state.last_page = action.payload?.last_page;
		},
		setComplain: (state, action: PayloadAction<ICOMPLAIN>) => {
			state.complain = action.payload;
		}
	}
});

export const { setComplains, setComplain } = complain.actions;

export default complain.reducer;
