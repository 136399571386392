import React from 'react';

// types
import { IPriceOrder } from '../../../store/types/order';

type ICurrencyData = {
	item: IPriceOrder;
};

const Currency: React.FC<ICurrencyData> = ({ item }) => {
	return (
		<div className="w-full my-2 flex justify-between bg-[#243677] px-6 py-3 rounded-lg">
			<div className="uppercase text-[white]">{item.name}</div>
			<div className="uppercase text-[white]">{item?.currency?.name}</div>
		</div>
	);
};

export default Currency;
