import { ITask } from '@/store/types/task';
import React from 'react';

type IStatus = {
  item: ITask;
};

const Status: React.FC<IStatus> = ({ item }) => {
  return (
    <div
      className={`rounded-md block font-bold py-1 text-white ${
        item.deleted
          ? 'bg-[red]'
          : item.deactivated?.toString() === '2'
          ? 'bg-[#9c8c8a]'
          : 'bg-[green]'
      }`}
    >
      {item.deleted
        ? 'deleted'
        : item?.deactivated?.toString() === '2'
        ? 'deactivated'
        : 'runing'}
    </div>
  );
};

export default Status;
