import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setPaymentTypes,
  createPaymentType,
  updatePaymentType,
  setLoading,
  setUpdate
} from '@/store/slices/payment_type';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_payment_types = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-payment-types');

    dispatch(setPaymentTypes(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setPaymentTypes([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_payment_type = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-payment-type', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createPaymentType(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_payment_type = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-payment-type', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updatePaymentType(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
