// components
import { Skeleton } from 'antd';

const tables: string[] = ['fi', 'se', 't', 'fo', 'f', 'si', 's', 'e', 'n', 't'];

const TableLoader = () => {
	return (
		<div className="w-full my-3">
			{tables.map((item, _) => (
				<Skeleton.Button
					size="large"
					active={true}
					block={true}
					style={{ height: 60, marginBottom: 8 }}
					key={_ + 10}
				/>
			))}
		</div>
	);
};

export default TableLoader;
