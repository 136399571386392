import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// types
import { IStateMailer } from '../types/mailer';

// Define the initial state using that type
const initialState: IStateMailer = {
  message: [],
  bc_loader: false,
  send_user: false
};

export const mailer = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string[]>) => {
      state.message = action.payload;
    },
    clearMessage: (state) => {
      state.message = [];
    },
    setBcLoader: (state) => {
      state.bc_loader = !state.bc_loader;
    },
    setSendUser: (state) => {
      state.send_user = !state.send_user;
    }
  }
});

export const { setMessage, clearMessage, setBcLoader, setSendUser } =
  mailer.actions;

export default mailer.reducer;
