import React from 'react';

// components
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import TextInput from '../../../components/elements/forms/TextInput';
import { useAppSelector } from '../../../store/hooks';

// state
import {
	IClientPostData,
	IClientPostDataError
} from '../../../store/types/client';

type IOrderDataType = {
	onPress: (data: IClientPostData) => void;
};

const OrganizationData: React.FC<IOrderDataType> = ({ onPress }) => {
	const loading = useAppSelector(({ loading }) => loading.loading);
	const [data, dataSet] = React.useState<IClientPostData>({
		organization_name: '',
		contact_email: '',
		phone: '',
		full_name: ''
	});

	const [err, errSet] = React.useState<IClientPostDataError>({
		organization_name: '',
		contact_email: '',
		phone: '',
		full_name: ''
	});

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		dataSet((prev) => ({ ...prev, [name]: value }));
		errSet((prev) => ({ ...prev, [name]: '' }));
	};

	const onSubmit = () => {
		let validate: boolean = false;
		if (!data.contact_email) {
			validate = true;
			errSet((prev) => ({ ...prev, contact_email: 'warning' }));
		}
		if (!data.organization_name) {
			validate = true;
			errSet((prev) => ({ ...prev, organization_name: 'warning' }));
		}
		if (!data.phone) {
			validate = true;
			errSet((prev) => ({ ...prev, phone: 'warning' }));
		}
		if (!data.full_name) {
			validate = true;
			errSet((prev) => ({ ...prev, full_name: 'warning' }));
		}
		if (validate) return;
		onPress(data);
	};

	return (
		<div className="md:flex flex-col justify-center mb-5 text-left">
			<div className="md:w-full px-2">
				<div className="w-full mb-5">
					<TextInput
						type="text"
						label="Full Name"
						status={err.full_name}
						placeholder="Full Name"
						name="full_name"
						value={data.full_name}
						onChange={onChangeHandler}
					/>
				</div>

				<div className="w-full mb-5">
					<TextInput
						type="text"
						label="Organization Name"
						status={err.organization_name}
						placeholder="Organization Name"
						name="organization_name"
						value={data.organization_name}
						onChange={onChangeHandler}
					/>
				</div>

				<div className="w-full mb-5">
					<TextInput
						type="text"
						label="Contact Email"
						status={err.contact_email}
						placeholder="Contact Email"
						name="contact_email"
						value={data.contact_email}
						onChange={onChangeHandler}
					/>
				</div>
				<div className="w-full mb-5">
					<TextInput
						type="text"
						label="Contact Phone"
						status={err.phone}
						placeholder="Contact Phone"
						name="phone"
						value={data.phone}
						onChange={onChangeHandler}
					/>
				</div>
			</div>

			<div className="flex items-center justify-end my-10">
				<div className="w-[30%]">
					<ButtonComponent
						title="Submit"
						textColor="white"
						backgroundColor="#243677"
						loading={loading}
						onHandler={onSubmit}
						active={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default OrganizationData;
