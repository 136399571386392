import React from 'react';

// constants
import { RxCaretDown, RxCaretUp } from 'react-icons/rx';

// types and state
import { ErrorWarning, CustomSelectItem } from '../../../store/types/misc';

type ICustomInputSearch = {
  icon?: React.ReactNode;
  status?: ErrorWarning;
  container?: string;
  placeholder: string;
  items: CustomSelectItem[];
  value: string;
  onSelect: (res: CustomSelectItem) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomInputSearch: React.FC<ICustomInputSearch> = ({
  icon,
  container,
  value,
  status,
  placeholder,
  items,
  onSelect,
  onChange
}) => {
  const [show, showSet] = React.useState<boolean>(false);

  return (
    <div className={`w-full relative text-[15px] font-abel ${container}`}>
      <div
        onClick={() => showSet(!show)}
        className={`cursor-pointer flex justify-between bg-[white] py-3 px-4 border hover:border-[#243677] rounded-lg items-center  h-[60px] ${
          !status ? 'border-[#82828250]' : 'border-[red]'
        } `}
      >
        <div className="flex items-center w-full h-full">
          {icon}
          <input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className="w-full border-none h-full outline-none placeholder:font-abel ml-2"
          />
        </div>
        {show ? (
          <RxCaretDown size={20} color="#243677" />
        ) : (
          <RxCaretUp size={20} color="#243677" />
        )}
      </div>
      {show && items.length > 0 && (
        <div
          className={`absolute max-h-[200px] py-3 px-4 top-[60px] text-[15px] z-50 overflow-hidden overflow-y-auto overflow-x-hidden rounded-lg left-0 w-full bg-[white] border hover:border-[#243677] border-[#243677]`}
        >
          {items?.map((item, index) => (
            <div
              key={index + 1}
              onClick={() => {
                onSelect(item);
                showSet(false);
              }}
              className={`cursor-pointer my-2 border-b border-[#243677] py-[3px] ${
                items.length === index + 1 && 'border-none'
              }`}
            >
              <div>{item?.name}</div>
              {item?.description && <div>{item?.description}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomInputSearch;
