import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { setLoading, setCollege, updateCollege } from '@/store/slices/college';

// notification
import { openNotification } from '@/components/alerts/notify';

export const get_college = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-universities');

    dispatch(setCollege(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_college = async (dispatch: Function, data: ICreateData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/create-university', data);

    dispatch(updateCollege(response.data?.data));
    openNotification({ status: 'success', message: response.data?.message });
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type IUpdateData = { name: string; id: string };
export const update_college = async (dispatch: Function, data: IUpdateData) => {
  dispatch(setLoading());
  try {
    // const response = await axiosInstance.patch('/update-university', data);
    // dispatch(setRoles(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};
