import React from 'react';

// components
import { DatePicker, DatePickerProps } from 'antd';

// icons
import { FaUserEdit } from 'react-icons/fa';
import dayjs from 'dayjs';
import { get_date } from '@/service/formatter/date';

type IEditDate = {
	title: string;
	date: Date;
	placeholder: string;
	onUpdate: (data: string) => void;
};
const EditDate: React.FC<IEditDate> = ({
	date,
	title,
	placeholder,
	onUpdate
}) => {
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<Date>(new Date());

	const onLoad = React.useCallback(() => {
		dataSet(date);
	}, [date]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	const onChange: DatePickerProps['onChange'] = (_date, dateString) => {
		showSet(false);
		onUpdate(dateString);
	};

	return (
		<div className="flex items-center">
			{show ? (
				<div className="flex text-lg items-center">
					{title}:
					<DatePicker
						onChange={onChange}
						onBlur={() => showSet(false)}
						placeholder={placeholder}
						defaultValue={dayjs(date, 'YYYY-MM-DD')}
						className="mx-3 placeholder:text-[18px] text-[16px] outline-none"
					/>
				</div>
			) : (
				<div className="flex items-center">
					<p className="text-lg mb-1 capitalize">
						{title}:{get_date(data)}
					</p>
					<div className="mx-3">
						<FaUserEdit
							size={25}
							color="#567BFF"
							onClick={() => showSet(true)}
							className="cursor-pointer"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default EditDate;
