import React from 'react';

// components
import TextInput from '../../../components/elements/forms/TextInput';
import CustomSelect from '../../../components/elements/forms/CustomSelect';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// state
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import {
	IPaymentAndLocation,
	IPaymentAndLocationError
} from '../../../store/types/order';
import { get_currencies } from '../../../service/api/misc';

type IOrderDataType = {
	onPress: (data: any) => void;
};

const EditPaymentAndLocationData: React.FC<IOrderDataType> = ({ onPress }) => {
	const dispatch = useAppDispatch();
	const order = useAppSelector(({ order }) => order.order);
	const currencies = useAppSelector(({ misc }) => misc.currencies);
	const loading = useAppSelector(({ order }) => order.update);
	const [data, dataSet] = React.useState<IPaymentAndLocation>({
		price_to: '',
		price_from: '',
		currency_id: ''
	});
	const [err, errSet] = React.useState<IPaymentAndLocationError>({
		price_from: '',
		price_to: '',
		currency_id: ''
	});

	const onSubmit = () => {
		let validate: boolean = false;
		if (!data.price_to) {
			validate = true;
			errSet((prev: any) => ({ ...prev, price_to: 'warning' }));
		}
		if (!data.currency_id) {
			validate = true;
			errSet((prev: any) => ({ ...prev, currency_id: 'warning' }));
		}
		if (validate) return;
		onPress?.({ ...data, currency_id: data.currency_id?.toString() });
	};

	const onLoad = React.useCallback(() => {
		get_currencies(dispatch);
	}, [dispatch]);

	const onSetData = React.useCallback(() => {
		if (order) {
			dataSet((prev) => ({
				...prev,
				price_from: order?.price_from || '',
				price_to: order?.price_to || '',
				currency_id: order?.currency?.id || ''
			}));
		}
	}, [order]);

	React.useEffect(() => {
		onLoad();
		onSetData();
	}, [onLoad, onSetData]);

	return (
		<div className="md:flex flex-col flex justify-center">
			<div className="md:w-full">
				<div className="mt-3 mb-5">
					<CustomSelect
						items={currencies}
						name="currency_id"
						placeholder="Currency"
						loading={false}
						status={err.currency_id}
						selected={data.currency_id}
						onSelect={(name, value) => {
							errSet((prev) => ({ ...prev, currency_id: '' }));
							dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
						}}
					/>
				</div>

				<div className="w-full flex">
					<div className="mr-2 w-[50%]">
						<TextInput
							label="Min Salary Range"
							status={err.price_from}
							placeholder="Min Salary"
							name="price_from"
							value={data.price_from}
							onChange={(e) => {
								errSet((prev) => ({
									...prev,
									price_from: ''
								}));
								dataSet((prev: any) => ({
									...prev,
									[e.target.name]: e.target.value
								}));
							}}
						/>
					</div>

					<div className="w-[50%]">
						<TextInput
							label="Max Salary Range"
							status={err.price_to}
							placeholder="Max Salary"
							name="price_to"
							value={data.price_to}
							onChange={(e) => {
								errSet((prev) => ({
									...prev,
									price_to: ''
								}));
								dataSet((prev: any) => ({
									...prev,
									[e.target.name]: e.target.value
								}));
							}}
						/>
					</div>
				</div>
			</div>

			<div className="flex items-center justify-end my-10">
				<div className="w-[30%]">
					<ButtonComponent
						loading={loading}
						title="Submit"
						textColor="white"
						backgroundColor="#243677"
						onHandler={onSubmit}
						active={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default EditPaymentAndLocationData;
