import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// candidate
import { IGetCandidateReport } from '../../store/types/report_role';

type IGetRoleCandidate = (id: string) => Promise<IGetCandidateReport[]>;
export const get_role_candidate: IGetRoleCandidate = async (id) => {
	let candidates = [];
	try {
		const response = await axiosInstance.get(
			`/get-report-role-candidate/${id}`
		);

		candidates = response.data;
	} catch (err) {
		if (isAxiosError(err)) {
		}
	}
	return candidates;
};
