import React from 'react';

// components
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import RichTextInputBox from '../../../components/elements/forms/RichTextInputBox';

// types
import { ErrorWarning } from '../../../store/types/misc';

type IOrderDataType = {
	onPress: (res: string) => void;
};

const Description: React.FC<IOrderDataType> = ({ onPress }) => {
	const [data, dataSet] = React.useState<string>('');
	const [err, errSet] = React.useState<ErrorWarning>('');

	const onSubmitEvent = () => {
		if (data.length < 3) {
			errSet('warning');
			return;
		}
		onPress(data);
	};

	return (
		<div className="md:flex flex-col flex justify-center text-left">
			<div className="w-full">
				<RichTextInputBox
					value={data}
					status={err}
					label="Job Description"
					placeholder="Job Description"
					required={true}
					onChange={(res: string) => {
						dataSet(res);
						errSet('');
					}}
				/>
			</div>

			<div className="flex items-center justify-end my-10">
				<div className="w-[30%]">
					<ButtonComponent
						title="Next"
						textColor="white"
						backgroundColor="#243677"
						onHandler={onSubmitEvent}
						active={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default Description;
