import React from 'react';

// constants
import { MdOutlineCancel } from 'react-icons/md';

type IListMail = {
  data: string[];
  onDelete: (data: string) => void;
};

const ListMail: React.FC<IListMail> = ({ data, onDelete }) => {
  return (
    <div className=" my-6 font-abel flex flex-wrap overflow-y-auto overflow-x-hidden">
      {data.map((i, index) => (
        <div
          key={index + 1}
          className="bg-[#CDD8FE] py-[2px] px-2 text-[11px] rounded-sm mr-[4px] my-[2px] flex items-center justify-center"
        >
          {i}
          <MdOutlineCancel
            className="ml-2  cursor-pointer"
            onClick={() => onDelete(i)}
          />
        </div>
      ))}
    </div>
  );
};

export default ListMail;
