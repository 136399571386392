import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  currencies: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  currencies: [],
  update: false,
  loading: false
};

export const currency = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrencies: (state, action: PayloadAction<IDataType[]>) => {
      state.currencies = action.payload;
    },
    createCurrency: (state, action: PayloadAction<IDataType>) => {
      state.currencies = [action.payload, ...state.currencies];
    },
    updateCurrency: (state, action: PayloadAction<IDataType>) => {
      state.currencies = state.currencies.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setCurrencies,
  createCurrency,
  updateCurrency,
  setLoading,
  setUpdate
} = currency.actions;

export default currency.reducer;
