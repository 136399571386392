import React from 'react';

// components
import Paginate from '@/components/ui/paginate';

// types
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import { useNavigate } from 'react-router-dom';
import { get_blue_orders } from '@/service/api/blue_order';
import { setPaginate } from '@/store/slices/blue_order';
import { get_normal_date_time } from '@/service/formatter/date';

// constants
import { CiEdit } from 'react-icons/ci';
import { FaTrashAlt } from 'react-icons/fa';
import { FaRegEye } from 'react-icons/fa';

// components

const BlueOrders = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orders = useAppSelector(({ blue_order }) => blue_order.orders);
  const curr_page = useAppSelector(({ blue_order }) => blue_order.current_page);
  const last_page = useAppSelector(({ blue_order }) => blue_order.last_page);
  const action = useAppSelector(({ navbar }) => navbar.action);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Blue Order'));
    dispatch(
      setNavButton({
        button_name: 'View Candidates',
        button: true
      })
    );
  }, [dispatch]);

  const onAction = React.useCallback(() => {
    if (action) navigate('/blue-candidate');
  }, [action, navigate]);

  const onGetOrders = React.useCallback(() => {
    get_blue_orders(dispatch, curr_page);
  }, [curr_page, dispatch]);

  React.useEffect(() => {
    onLoad();
    onAction();
    onGetOrders();
  }, [onAction, onLoad, onGetOrders]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-4">
      <div className="bg-[white] py-4 w-full  justify-between">
        <div className="flex justify-center items-center px-3 py-3 text-[#567BFF] border-b">
          <div className="mx-1 border-r px-2 w-[30%] text-center">Name</div>
          <div className="mx-1 border-r px-2 w-[30%] text-center">Position</div>
          <div className="px-2 border-r w-[30%] text-center">Date</div>
          <div className="mx-1 px-2 w-[10%] text-center">Action</div>
        </div>

        {orders.map((item) => (
          <div
            key={item.id}
            className="flex justify-center items-center px-3 py-3 text-[#567BFF] border-b"
          >
            <div className="mx-1 border-r px-2 w-[30%] text-center">
              {item?.client?.profile?.full_name ||
                item?.client?.profile?.organization_name}
            </div>
            <div className="mx-1 border-r px-2 w-[30%] text-center">
              {item?.role?.name}
            </div>
            <div className="px-2 border-r w-[30%] text-center">
              {item?.created_at ? get_normal_date_time(item.created_at) : ''}
            </div>
            <div className="mx-1 px-2 w-[10%] text-center flex justify-between">
              <FaRegEye
                className="cursor-pointer"
                color="#272727"
                size={15}
                onClick={() => navigate(`order/${item?.id}`)}
              />
              <CiEdit
                className="cursor-pointer"
                size={15}
                onClick={() => navigate(`/edit-order/${item?.id}`)}
              />
              <FaTrashAlt
                className="cursor-pointer"
                color="red"
                size={15}
                onClick={() => console.log('Delete', item.id)}
              />
            </div>
          </div>
        ))}
      </div>
      <Paginate
        current_page={curr_page?.toString()}
        last_page={last_page?.toString()}
        onPressNext={() => dispatch(setPaginate('increase'))}
        onPressPrev={() => dispatch(setPaginate('decrease'))}
      />
    </div>
  );
};

export default BlueOrders;
