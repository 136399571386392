import React from 'react';

// constants
import { RxCaretDown } from 'react-icons/rx';

// types and state
import { ErrorWarning, IDataType } from '../../../store/types/misc';

type IDesription = IDataType & { description?: string };

type IUpdatePosition = {
	items: IDesription[];
	selected?: string;
	placeholder: string;
	name: string;
	onSelect: (name: string, value: string) => void;
	loading?: boolean;
	status?: ErrorWarning;
};

const CustomSelect: React.FC<IUpdatePosition> = ({
	items,
	name,
	status,
	loading,
	placeholder,
	selected,
	onSelect
}) => {
	const [show, showSet] = React.useState<boolean>(false);
	const onSelected =
		selected &&
		items.length > 0 &&
		items.find((item) => item.id?.toString() === selected);

	return (
		<div
			onClick={() => showSet(!show)}
			className={`w-full font-abel text-[15px] h-[60px] rounded-lg border ${
				status ? 'border-[red]' : 'border-[#E8E8E8]'
			}  hover:border-[#243677] flex flex-cols items-center relative ${
				loading ? 'bg-[#E8E8E8]' : 'bg-[#ffffff]'
			}`}
		>
			<div
				className={`w-full py-3 px-4 flex capitalize justify-between items-center cursor-pointer `}
			>
				<div>{onSelected ? onSelected?.name : placeholder}</div>
				<RxCaretDown size={20} />
			</div>
			<div
				className={`w-full py-3 px-4 max-h-[200px] overflow-y-auto overscroll-x-none capitalize rounded-lg absolute top-[60px] left-0 bg-[white] border border-[#E8E8E8] z-50 ${
					show ? 'flex flex-col' : 'hidden'
				}`}
			>
				{items.map((item, index) => (
					<div
						key={item.id}
						onBlur={() => showSet(false)}
						onClick={() => {
							onSelect(name, item.id);
							showSet(false);
						}}
						className={`cursor-pointer my-1 text-[#243677] ${
							items.length !== index + 1 ? 'border-[#E8E8E8] border-b' : ''
						}`}
					>
						<div className="text-[#4E4E4E] capitalize">{item.name}</div>
						{item?.description && (
							<div className="text-[#4E4E4E]">{item.description}</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomSelect;
