import React from 'react';

// components
import { ResponsiveLine, Serie } from '@nivo/line';
import { IGraphState } from '@/store/types/misc';

type IGraph = { data: IGraphState; id: string }[];
type ILineGraphSeries = IGraph | IGraph[];

type ILineGraph = {
  data: ILineGraphSeries;
  y_name?: string;
  x_name?: string;
};

const LineGraph: React.FC<ILineGraph> = ({ data, y_name, x_name }) => {
  const [graph, graphSet] = React.useState<Serie[]>([]);

  const onLoad = React.useCallback(() => {
    if (data.length) graphSet(data);
  }, [data]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <ResponsiveLine
      data={graph}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: x_name,
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: y_name,
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 60,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  );
};

export default LineGraph;
