import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IAuthState, IProfile, IUserType } from '../types/auth';

// Define the initial state using that type
const initialState: IAuthState = {
  user: null,
  loading: false,
  profile: null
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserType>) => {
      state.user = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setProfile: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      if (state.profile && state.profile?.profile) {
        state.profile = {
          ...state.profile,
          profile: { ...state.profile?.profile, full_name: action.payload }
        };
      }
    }
  }
});

export const { setUser, setLoading, setProfile, setName } = auth.actions;

export default auth.reducer;
