import { Modal } from 'antd';
import React from 'react';
import MiniButton from '../../elements/forms/MiniButton';

type IConfirm = {
	open: boolean;
	onCancel: () => void;
	onAccept: () => void;
};

const Confirm: React.FC<IConfirm> = ({ open, onCancel, onAccept }) => {
	return (
		<Modal
			open={open}
			footer={[]}
			closable={false}
			closeIcon={false}
			maskClosable={true}
			mask={true}
			centered
		>
			<div className="flex justify-end">
				<div className="w-[20%] mr-2">
					<MiniButton
						title="Cancel"
						onHandler={onCancel}
						backgroundColor="red"
						textColor="white"
					/>
				</div>
				<div className="w-[20%]">
					<MiniButton
						title="Accept"
						onHandler={onAccept}
						backgroundColor="#243677"
						textColor="white"
					/>
				</div>
			</div>
		</Modal>
	);
};

export default Confirm;
