import React from 'react';

// react router
import { useParams } from 'react-router-dom';

// type and state
import { get_corporate_service } from '@/service/api/order';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setNavButton, setTitle } from '@/store/slices/navbar';
import { get_normal_date_time } from '@/service/formatter/date';

const CorporateService = () => {
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const order = useAppSelector(({ order }) => order.corporate_service);

	const onLoad = React.useCallback(() => {
		dispatch(setTitle('Corporate Service'));
		dispatch(setNavButton({ button_name: '', button: false }));
	}, [dispatch]);

	const onFetch = React.useCallback(() => {
		if (id) get_corporate_service(dispatch, id);
	}, [dispatch, id]);

	React.useEffect(() => {
		onLoad();
		onFetch();
	}, [onLoad, onFetch]);

	return (
		<div className="flex flex-col">
			<header className="px-4 w-full py-[4rem] text-left flex justify-between rounded-xl text-[white] bg-[#243677]">
				<div>
					<p className="capitalize text-left text-xl">
						Company Name: {order?.company?.profile?.organization_name}
					</p>
					<p className="capitalize text-left text-xl">
						Contact Name: {order?.company?.profile?.full_name}
					</p>
					<p className="capitalize text-left text-xl">
						Email: <span className="lowercase">{order?.company?.email}</span>
					</p>
					<p className="capitalize text-left text-xl">
						Address: {order?.company?.profile?.address}
					</p>
				</div>
				<div>
					<p className="capitalize text-left text-xl">
						Phone Number: {order?.company?.profile?.phone}
					</p>
					<p className="capitalize text-left text-xl">
						Staff Size: {order?.company?.profile?.staff_size}
					</p>
					<p className="capitalize text-left text-xl">
						Date:{' '}
						{order?.created_at ? get_normal_date_time(order.created_at) : ''}
					</p>
				</div>
				{/* <h3 className="capitalize text-2xl" /> */}
			</header>
			<section className="relative w-[95%] mx-auto top-[-25px] px-4 py-[4rem] rounded-xl bg-[#F5F5F5] shadow-md">
				<div className="text-left w-full  ">{order?.message}</div>
			</section>
		</div>
	);
};

export default CorporateService;
