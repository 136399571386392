import React from 'react';
import {
	IEditOrderTitle,
	IEditOrderTitleError
} from '../../../store/types/order';

const useOrderData = () => {
	const [data, dataSet] = React.useState<IEditOrderTitle>({
		order_type_id: '',
		position: '',
		expertise_level_id: '',
		job_type_id: '',
		state_id: '',
		country_id: ''
	});
	const [err, errSet] = React.useState<IEditOrderTitleError>({
		order_type_id: '',
		position: '',
		expertise_level_id: '',
		job_type_id: '',
		state_id: '',
		country_id: ''
	});
	const [fill, fillSet] = React.useState<IEditOrderTitle>({
		order_type_id: '',
		position: '',
		expertise_level_id: '',
		job_type_id: '',
		state_id: '',
		country_id: ''
	});

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		dataSet((prev: any) => ({ ...prev, [name]: value }));
	};

	type IOnsubmitEvent = () => boolean;
	const onSubmitEvent: IOnsubmitEvent = () => {
		let validate: boolean = false;
		if (!data.position) {
			validate = true;
			errSet((prev: any) => ({ ...prev, position: 'warning' }));
		}
		if (!data.order_type_id) {
			validate = true;
			errSet((prev: any) => ({ ...prev, order_type: 'warning' }));
		}
		if (!data.job_type_id) {
			validate = true;
			errSet((prev: any) => ({ ...prev, job_type_id: 'warning' }));
		}
		if (!data.expertise_level_id) {
			validate = true;
			errSet((prev: any) => ({ ...prev, expertise_level_id: 'warning' }));
		}
		if (!data.country_id) {
			validate = true;
			errSet((prev: any) => ({ ...prev, country_id: 'warning' }));
		}
		if (!data.state_id) {
			validate = true;
			errSet((prev: any) => ({ ...prev, state_id: 'warning' }));
		}
		if (validate) {
			return false;
		}
		return true;
	};

	return {
		data,
		err,
		fill,
		fillSet,
		dataSet,
		errSet,
		onChangeHandler,
		onSubmitEvent
	};
};

export default useOrderData;
