import React from 'react';

// navigation and router
import { Link } from 'react-router-dom';

// components
import { Switch, Popconfirm, Skeleton } from 'antd';

// constants and icons
import { get_normal_date_time } from '../../../service/formatter/date';
import { FaTrashAlt } from 'react-icons/fa';

// types
import { IOrderShorttype } from '../../../store/types/order';

// state
import { switch_availability, delete_order } from '../../../service/api/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

type ITableLine = {
  key?: number | string;
  item: IOrderShorttype;
  onRefresh?: () => void;
};

const TableLine: React.FC<ITableLine> = ({ item, onRefresh }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ order }) => order.loading);
  const destroy = useAppSelector(({ order }) => order.delete);
  const update = useAppSelector(({ order }) => order.update);

  return (
    <div
      className={`flex justify-center w-full items-center px-3 py-2 mb-3 border-b relative border-[#BCBCBC] ${
        item.seen?.toLowerCase() === '1' ? 'bg-[#F7F8FE]' : 'bg-white'
      }`}
    >
      <div className="mx-1 border-r px-2 w-[25%] text-center capitalize ">
        {item?.user?.profile?.organization_name}
        {item.order && (
          <span className="absolute -top-[.5px] left-0 lowercase px-2 py-[.3px] bg-[#FF9696] text-white mx-2 rounded-md">
            order
          </span>
        )}
      </div>
      <div className="mx-1 border-r px-2 w-[25%] text-center capitalize">
        {item?.position?.name}
      </div>
      <div className="mx-1 border-r px-2 w-[25%] text-center">
        {item.created_at && get_normal_date_time(item.created_at)}
      </div>
      <div className="mx-1 px-2 w-[25%] text-center flex items-center justify-center">
        {loading ? (
          <Skeleton.Button
            size="small"
            active={true}
            style={{ width: '100%' }}
            block={true}
          />
        ) : (
          <>
            {' '}
            <Link to={`/order/${item.slug}`}>
              <span className="underline text-[#319759] cursor-pointer">
                View
              </span>
            </Link>
            <Link to={`/edit-order/${item.slug}`}>
              <span className="underline text-[#243677] cursor-pointer mx-3">
                Edit
              </span>
            </Link>
            <div className="mr-3">
              <Switch
                loading={update}
                disabled={update ? true : false}
                defaultChecked={item.availability === '1' ? true : false}
                onChange={() => switch_availability(item.slug, dispatch)}
              />
            </div>
            <Popconfirm
              title="Are you sure to delete this order?"
              onConfirm={() => {
                delete_order(item.slug, dispatch);
                setTimeout(() => onRefresh?.(), 400);
              }}
              onCancel={() => null}
              okButtonProps={{
                loading: destroy,
                className: 'text-[#fff] bg-[green]'
              }}
              okText="Yes"
              cancelText="No"
            >
              <span>
                <FaTrashAlt
                  size={17}
                  color="#B10E0E"
                  className="cursor-pointer"
                />
              </span>
            </Popconfirm>
          </>
        )}
      </div>
    </div>
  );
};

export default TableLine;
