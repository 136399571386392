import React from 'react';
import { FaArrowUp } from 'react-icons/fa';

// services and state
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	get_total_candidate_gender,
	get_total_candidate
} from '../../../service/api/candidate';

const StatsCandidates: React.FC = () => {
	const dispatch = useAppDispatch();
	const gender_aggregate = useAppSelector(
		({ candidate }) => candidate.gender_aggregate
	);
	const total_candidate = useAppSelector(
		({ candidate }) => candidate.total_candidates
	);
	const onLoad = React.useCallback(() => {
		get_total_candidate(dispatch);
		get_total_candidate_gender(dispatch);
	}, [dispatch]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="w-full flex flex-wrap mt-2 mb-5">
			<div className="text-left bg-white px-6 pt-4 pb-4 md:w-[30%] lg:w-[31%] w-[98%]">
				<p className="md:text-[22px] text-[16px] mb-3">
					Total number of candidates
				</p>
				<p className="md:text-[60px] text-[30px] md:leading-10 leading-6 mb-0">
					{total_candidate}
				</p>
				<div className="text-[green] flex items-center justify-end">
					<FaArrowUp size={15} color="green" />
					<p className="text-[20px]">+20%</p>
				</div>
			</div>

			{gender_aggregate.map((aggregate) => (
				<div
					key={aggregate.name}
					className="text-left bg-white px-6 pt-4 pb-4 mx-1 md:w-[22%] w-[98%]"
				>
					<p className="md:text-[22px] text-[16px] mb-3">
						Total number of {aggregate.name} candidates
					</p>
					<p className="md:text-[60px] text-[30px] md:leading-10 leading-6 mb-0">
						{aggregate.total}
					</p>
					{/* <div className="text-[green] flex items-center justify-end">
						<FaArrowUp size={15} color="green" />
						<p className="text-[20px]">+20%</p>
					</div> */}
				</div>
			))}
		</div>
	);
};

export default StatsCandidates;
