import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setEducation,
  updateEducation,
  createEducation,
  setLoading
} from '@/store/slices/education';
import { IEducationData } from '@/store/types/candidate';

// notification
import { openNotification } from '@/components/alerts/notify';

export const get_education = async (dispatch: Function, slug: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-education/${slug}`);

    dispatch(setEducation(response.data));
  } catch (err) {
    dispatch(setEducation([]));
  }
  dispatch(setLoading());
};

export const save_education = async (
  dispatch: Function,
  formData: IEducationData
) => {
  let result = false;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/create-education', formData);

    dispatch(createEducation(response.data?.data));
    openNotification({ status: 'success', message: response?.data?.message });
    result = true;
  } catch (err) {
    result = false;
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(setLoading());
  return result;
};

export const update_education = async (
  dispatch: Function,
  formData: IEducationData
) => {
  let result = false;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch('/update-education', formData);

    dispatch(updateEducation(response.data));
    openNotification({ status: 'success', message: response?.data?.message });
    result = true;
  } catch (err) {
    result = false;
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(setLoading());
  return result;
};
