import React from 'react';

// components
import { Modal } from 'antd';
import SelectInput from '@/components/elements/forms/SelectInput';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';

// types

// constants and icons
import { get_date } from '@/service/formatter/date';

// types
import { useAppSelector } from '@/store/hooks';
import { setSchedule } from '@/store/slices/schedule';
import { ErrorWarning } from '@/store/types/misc';
import { activate_task_schedulers } from '@/service/api/task';

type IChangePassword = {
  visible: boolean;
  schedule_id: string;
  dispatch: Function;
  onDone: () => void;
};

const ShowSchedule: React.FC<IChangePassword> = ({
  visible,
  dispatch,
  schedule_id,
  onDone
}) => {
  const schedule = useAppSelector(({ schedule }) => schedule.schedule);
  const [data, dataSet] = React.useState<{ status: string }>({
    status: ''
  });

  const [err, errSet] = React.useState<{ status: ErrorWarning }>({
    status: ''
  });

  const onClear = () => {
    dataSet({ status: '' });
    onDone();
  };

  const onLoad = React.useCallback(() => {
    if (schedule_id) dispatch(setSchedule(schedule_id));
  }, [dispatch, schedule_id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      open={visible}
      closeIcon={false}
      footer={null}
      closable={false}
      centered={true}
    >
      <p className="my-4 font-abel font-bold text-[20px]">
        Schedule Message: {schedule?.message}
      </p>

      <div className="my-4">
        <p>Created By: {schedule?.user?.profile?.full_name}</p>
        <p>Contact: {schedule?.user?.email}</p>
        <p>
          Permission:{' '}
          {schedule?.user?.role_id ? 'super user' : 'not super user'}
        </p>
        <p>
          Account Created At:
          {schedule?.user?.created_at
            ? get_date(schedule?.user?.created_at)
            : ''}
        </p>
      </div>

      <div className="my-4">
        <p>Deactivated: {schedule?.deactivated_by?.email}</p>
        <p>Status: {schedule?.status?.name}</p>
        <p>
          Permission:{' '}
          {schedule?.user?.role_id ? 'super user' : 'not super user'}
        </p>
        <p>
          Account Created At:{' '}
          {schedule?.deactivated_at ? get_date(schedule?.deactivated_at) : ''}
        </p>
      </div>

      <div className="my-4">
        <p>
          Expiry Date:{' '}
          {schedule?.expiry_date ? get_date(schedule?.expiry_date) : ''}
        </p>
        <p>Status: {schedule?.status?.name}</p>
      </div>

      <div className="my-4 flex">
        <div className="w-full">
          <SelectInput
            name="name"
            onResponse={(res) => {
              errSet({ status: '' });
              dataSet({ status: res });
            }}
            status={err.status}
            required={true}
            selectedValue={data.status}
            items={[]}
          />
        </div>
      </div>

      <div className="mb-4 mt-8 w-full flex justify-between">
        <div className="w-[45%]">
          <ButtonComponent
            title="Update Status"
            onHandler={() => {
              if (data.status === '') return errSet({ status: 'warning' });
              if (data.status.length && schedule_id) {
                activate_task_schedulers(dispatch, schedule_id, data);
                onClear();
              }
            }}
            active={true}
          />
        </div>
        <div className="w-[45%]">
          <ButtonComponent
            title="Cancel"
            onHandler={() => onClear()}
            backgroundColor="red"
            active={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ShowSchedule;
