import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '../../elements/forms/ButtonComponent';

type ISingleModalSelect = {
	open: boolean;
	children: React.ReactNode;
	onClose: () => void;
};

const SingleModalSelect: React.FC<ISingleModalSelect> = ({
	open,
	children,
	onClose
}) => {
	return (
		<Modal
			open={open}
			footer={[]}
			closable={false}
			closeIcon={false}
			maskClosable={true}
			mask={true}
			width="90%"
			bodyStyle={{ height: '90vh' }}
			centered
		>
			<div className="w-full h-[90%] flex flex-col justify-between">
				<div className="flex justify-end">
					<div className="w-[10%]">
						<ButtonComponent
							title="close"
							onHandler={onClose}
							backgroundColor="red"
							active={true}
						/>
					</div>
				</div>
				{children}
			</div>
		</Modal>
	);
};

export default SingleModalSelect;
