import React from 'react';

// components
import pay from '../../../assets/images/icons/pay.svg';
import calendar from '../../../assets/images/icons/calendar.svg';
import star from '../../../assets/images/icons/star.svg';
import location from '../../../assets/images/icons/location.svg';
import UpdateInput from '../misc/UpdateInput';
import Education from '../misc/Education';
import { Skeleton } from 'antd';
import UpdateSelect from '../misc/UpdateSelect';

// router
import { useParams } from 'react-router-dom';

// state and api
import {
  get_countries,
  get_education_levels,
  get_states
} from '../../../service/api/misc';
import {
  get_candidate,
  update_highesteduation_level
} from '../../../service/api/candidate';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import Experience from '../misc/Experience';
import Certification from '../misc/Certification';
import { get_work_experience } from '@/service/api/work_experience';
import { get_education } from '@/service/api/education';

const ViewCandidate = () => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const candidate = useAppSelector(({ candidate }) => candidate.candidate);
  const loading = useAppSelector(({ candidate }) => candidate.show);
  const countries = useAppSelector(({ misc }) => misc.countries);
  const states = useAppSelector(({ misc }) => misc.states);
  const edu_level = useAppSelector(({ misc }) => misc.education_level);

  const onLoad = React.useCallback(() => {
    if (slug) {
      get_countries(dispatch);
      get_education_levels(dispatch);
      get_candidate(dispatch, slug);
      get_work_experience(dispatch, slug);
      get_education(dispatch, slug);
    }
  }, [dispatch, slug]);

  const onSetHeader = React.useCallback(() => {
    dispatch(setTitle('Candidate'));
    dispatch(setNavButton({ button_name: '', button: false }));
  }, [dispatch]);

  React.useEffect(() => {
    onSetHeader();
    onLoad();
  }, [onLoad, onSetHeader]);

  return (
    <>
      <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-6">
        <div className="w-full bg-[#e0e7ff] md:h-[358px] h-full my-6 p-6">
          <div className=" flex flex-wrap justify-center items-center w-full h-full">
            <div className="rounded-full border-[#E9EAEF] border-[6px] w-[152px] h-[152px] text-center object-cover overflow-hidden my-3 md:mr-5">
              <img
                src={candidate?.profile?.avatar}
                alt={candidate?.profile?.first_name}
                className="h-[100%] w-full rounded-full object-cover"
              />
            </div>

            <div className="text-[#243677] max-w-[1100px] ">
              <h1 className="text-3xl mb-2 font-bold">
                {loading ? (
                  <Skeleton.Button style={{ width: '70px' }} />
                ) : (
                  `About ${
                    candidate?.profile?.first_name
                      ? candidate?.profile?.first_name
                      : ''
                  }`
                )}
              </h1>
              <p className="text-lg mb-4 max-w-[570px]">
                {candidate?.profile?.address?.about?.about_me}
              </p>
              <p className="">
                {candidate?.profile?.address?.about?.profession?.name}
              </p>
              <div className="flex justify-between">
                <div className="bg-white h-[54px] w-[33%] px-2 mr-2 flex items-center">
                  <img src={pay} alt="icon" className="mr-1" />
                  <p className="text-sm capitalize">Expected Pay - 800,000</p>
                </div>
                <div className="bg-white h-[54px] w-[33%] px-2 mr-2 flex items-center">
                  <img src={calendar} alt="icon" className="mr-1" />
                  <p className="text-sm capitalize">Notice Period - 1 month</p>
                </div>
                <div className="bg-white h-[54px] w-[33%] px-2 mr-2 flex items-center">
                  <img src={star} alt="icon" className="mr-1" />
                  <p className="text-sm capitalize">
                    Status - <span className="text-[green]">Ready to work</span>
                  </p>
                </div>
                <div className="bg-white h-[54px] w-[33%] px-2 flex items-center">
                  <img src={location} alt="icon" className="mr-1" />
                  <p className="text-sm capitalize">
                    Location -{' '}
                    {candidate?.profile?.address?.residential_state?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Candidate Profile */}
        <div className="max-w-[1150px] m-auto mt-5 pb-14">
          <div className="flex justify-between flex-wrap w-full h-full text-base">
            {candidate?.email && (
              <>
                <UpdateInput
                  title="First Name"
                  name={candidate?.profile?.first_name || ''}
                  onUpdate={(res) => console.log(res)}
                />
                <UpdateInput
                  title="Last Name"
                  name={candidate?.profile?.last_name || ''}
                  onUpdate={(res) => console.log(res)}
                />
                <UpdateInput
                  title="Other Name"
                  name={candidate?.profile?.other_name || ''}
                  onUpdate={(res) => console.log(res)}
                />
                <UpdateInput
                  title="Email Address"
                  name={candidate?.email || ''}
                  onUpdate={(res) => console.log(res)}
                />
                <UpdateInput
                  title="Profession"
                  name={
                    candidate?.profile?.address?.about?.profession?.name || ''
                  }
                  onUpdate={(res) => console.log(res)}
                />
                <UpdateInput
                  title="Phone Number"
                  name={candidate?.profile?.phone || ''}
                  onUpdate={(res) => console.log(res)}
                />

                <UpdateInput
                  title="Phone Number"
                  name={candidate?.profile?.dob || ''}
                  onUpdate={(res) => console.log(res)}
                />

                <UpdateInput
                  title="Residential"
                  name={candidate?.profile?.address?.address || ''}
                  onUpdate={(res) => console.log(res)}
                />

                <UpdateSelect
                  email={candidate.email}
                  items={countries}
                  title="Residential Country"
                  placeholder="Select Country"
                  name={candidate?.profile?.address?.residential_state?.country}
                  onUpdate={(res) => get_states(dispatch, res.name)}
                />
                <UpdateSelect
                  email={candidate.email}
                  items={states}
                  title="Residential State"
                  placeholder="Select State"
                  name={candidate?.profile?.address?.residential_state}
                  onUpdate={(res) => console.log(res)}
                />

                <UpdateSelect
                  email={candidate.email}
                  items={edu_level}
                  title="Highest Education Level"
                  placeholder="Select Education Level"
                  name={candidate?.profile?.address?.about?.education_level}
                  onUpdate={(res) =>
                    update_highesteduation_level(dispatch, res)
                  }
                />

                <UpdateSelect
                  email={candidate.email}
                  items={edu_level}
                  title="Religion"
                  placeholder="Select Religion"
                  name={candidate?.profile?.address?.origin_state}
                  onUpdate={(res) => console.log(res)}
                />

                <UpdateSelect
                  email={candidate.email}
                  items={countries}
                  title="Country of Origin"
                  placeholder="Select State of Origin"
                  name={candidate?.profile?.address?.origin_state?.country}
                  onUpdate={(res) => console.log(res)}
                />

                <UpdateSelect
                  email={candidate.email}
                  items={edu_level}
                  title="State of Origin"
                  placeholder="Select State of Origin"
                  name={candidate?.profile?.address?.origin_state}
                  onUpdate={(res) => console.log(res)}
                />
              </>
            )}

            <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
              <label className="text-[#A4A4A4]">Marital Status</label>
              <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
                <p className="text-[#707070] px-4">
                  {candidate?.profile?.address?.about?.marital_status?.name}
                </p>
              </div>
            </div>

            <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
              <label className="text-[#A4A4A4]">Languages</label>
              <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
                <p className="text-[#707070] px-4">
                  {candidate?.profile?.address?.about?.languages?.toString()}
                </p>
              </div>
            </div>

            <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
              <label className="text-[#A4A4A4]">Facebook</label>
              <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
                {candidate?.profile?.address?.about?.social?.facebook && (
                  <a
                    href={candidate?.profile?.address?.about?.social?.facebook}
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#707070] px-4 hover:text-[#000000]"
                  >
                    {candidate?.profile?.address?.about?.social?.facebook}
                  </a>
                )}
              </div>
            </div>

            <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
              <label className="text-[#A4A4A4]">Twitter</label>
              <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
                {candidate?.profile?.address?.about?.social?.twitter && (
                  <a
                    href={candidate?.profile?.address?.about?.social?.twitter}
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#707070] px-4  hover:text-[#000000]"
                  >
                    {candidate?.profile?.address?.about?.social?.twitter}
                  </a>
                )}
              </div>
            </div>

            <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
              <label className="text-[#A4A4A4]">LinkedIn</label>
              <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
                {candidate?.profile?.address?.about?.social?.linked_in && (
                  <a
                    href={candidate?.profile?.address?.about?.social?.linked_in}
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#707070] px-4 hover:text-[#000000]"
                  >
                    {candidate?.profile?.address?.about?.social?.linked_in}
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="w-full pt-2 pb-5 px-2 flex border">
            <Education />
            <Experience />
            <Certification />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCandidate;
