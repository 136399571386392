import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '../../elements/forms/ButtonComponent';
import { BiSearchAlt } from 'react-icons/bi';
import SearchInput from '../../elements/forms/SearchInput';

type ISelectMulti = {
	open: boolean;
	children: React.ReactNode;
	placeholder: string;
	search?: string;
	value: { name: string; label: string }[];
	onShow: () => void;
	onSearch?: (search: string) => void;
};

const SelectMulti: React.FC<ISelectMulti> = ({
	open,
	search,
	placeholder,
	value,
	children,
	onShow,
	onSearch
}) => {
	return (
		<>
			<div
				onClick={onShow}
				className="h-[60px] flex items-center border border-[#82828250] w-full hover:border-[#243677] rounded-lg bg-[white] cursor-pointer px-2 overflow-y-hidden overflow-x-scroll"
			>
				{value.length ? (
					value.map((item) => (
						<span
							key={item.label}
							className="bg-[#C3D0FF] px-2 py-1 rounded-sm mr-3"
						>
							{item.name}
						</span>
					))
				) : (
					<span className="text-[#797979D1]">{placeholder}</span>
				)}
			</div>

			<Modal
				open={open}
				footer={[]}
				closable={false}
				closeIcon={false}
				width="90%"
				bodyStyle={{ height: '90vh' }}
				centered
			>
				<div className="w-full flex justify-between mb-2 h-[10%]">
					<div className="w-[40%]">
						{search && (
							<SearchInput
								name="search"
								value={search}
								placeholder="Search..."
								icon={<BiSearchAlt size={18} color="#243677" />}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									onSearch?.(e.target.value)
								}
							/>
						)}
					</div>
					<div className="w-[30%] flex justify-end">
						<div className="w-[49%]">
							<ButtonComponent
								title="Proceed"
								onHandler={onShow}
								active={true}
							/>
						</div>
					</div>
				</div>
				<div className="w-full h-[90%] flex flex-col justify-between">
					{children}
				</div>
			</Modal>
		</>
	);
};

export default SelectMulti;
