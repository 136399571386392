import React from 'react';

// components
import { Modal } from 'antd';
import SelectInput from '../../../components/elements/forms/SelectInput';
import TextInput from '../../../components/elements/forms/TextInput';

// type
import { ErrorWarning } from '../../../store/types/misc';

// state
import { create_price_range } from '../../../service/api/misc';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

type ICreateSoftSkillType = {
	visible: boolean;
	handleOk: () => void;
};

type IDataSet = {
	currency_id: string;
	name: string;
};

type IDataSetError = {
	currency_id: ErrorWarning;
	name: ErrorWarning;
};

const AddPriceRange: React.FC<ICreateSoftSkillType> = ({ visible, handleOk }) => {
	const dispatch = useAppDispatch();
	const loading = useAppSelector(({ loading }) => loading.update);
	const currencies = useAppSelector(({ misc }) => misc.currencies);
	const [data, dataSet] = React.useState<IDataSet>({
		currency_id: '',
		name: ''
	});
	const [err, errSet] = React.useState<IDataSetError>({
		currency_id: '',
		name: ''
	});

	const onSubmit = () => {
		let validate: boolean = false;
		if (!data.currency_id) {
			validate = true;
			errSet((prev) => ({ ...prev, currency_id: 'warning' }));
		}
		if (!data.name) {
			validate = true;
			errSet((prev) => ({ ...prev, name: 'warning' }));
		}
		if (validate) {
			return;
		}

		create_price_range(dispatch, data);
		handleOk?.();
		return;
	};

	return (
		<Modal title="Create Currency" confirmLoading={loading} open={visible} onOk={onSubmit} onCancel={handleOk}>
			<SelectInput name="currency_id" status={err.currency_id} container="my-3" items={currencies} onResponse={(name, value) => dataSet((prev) => ({ ...prev, [name]: value }))} />
			<TextInput
				name="name"
				status={err.name}
				value={data.name}
				className="my-3"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const { name, value } = e.target;
					dataSet((prev) => ({ ...prev, [name]: value }));
				}}
				placeholder="Price Range"
			/>
		</Modal>
	);
};

export default AddPriceRange;
