import CustomSelect from '@/components/elements/forms/CustomSelect';
import { IDataType } from '@/store/types/misc';
import React from 'react';

// icons
import { FaUserEdit } from 'react-icons/fa';

type IEditSelect = {
	title: string;
	selected: IDataType | null;
	items: IDataType[];
	placeholder: string;
	onUpdate: (data: string) => void;
};
const EditSelect: React.FC<IEditSelect> = ({
	selected,
	title,
	items,
	placeholder,
	onUpdate
}) => {
	const [show, showSet] = React.useState<boolean>(false);
	const [data, dataSet] = React.useState<string>('');

	const onLoad = React.useCallback(() => {
		if (selected) dataSet(selected.id);
	}, [selected]);

	React.useEffect(() => {
		onLoad();
	}, [onLoad]);

	return (
		<div className="flex items-center">
			{show ? (
				<div className="flex items-center">
					{title}:
					<div className="mx-3">
						<CustomSelect
							items={items}
							name={placeholder}
							placeholder={placeholder}
							selected={data}
							onSelect={(_, value) => {
								onUpdate(value?.toString());
								showSet(false);
							}}
						/>
					</div>
				</div>
			) : (
				<div className="flex items-center">
					<p className="text-lg mb-1 capitalize">
						{title}: {selected?.name}
					</p>
					<div className="mx-3">
						<FaUserEdit
							size={25}
							color="#567BFF"
							onClick={() => showSet(true)}
							className="cursor-pointer"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default EditSelect;
