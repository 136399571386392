import React from 'react';

// components
import ReportOrder from '../misc/ReportOrder';

// constants and state
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import { get_reports, search_reports } from '../../../service/api/report';
import { useNavigate } from 'react-router-dom';

const ReportDashboard = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const search = useAppSelector(({ navbar }) => navbar.search);
	const action = useAppSelector(({ navbar }) => navbar.action);
	const current_page = useAppSelector(({ report }) => report.current_page);

	const onSearch = React.useCallback(() => {
		if (search.length > 2) {
			search_reports(dispatch, search, current_page);
		} else {
			get_reports(dispatch, current_page);
		}
	}, [current_page, dispatch, search]);

	const onSetHeader = React.useCallback(() => {
		dispatch(setTitle('Report'));
		dispatch(setNavButton({ button_name: 'Create Report', button: true }));
	}, [dispatch]);

	const onAction = React.useCallback(() => {
		if (action) {
			navigate('/create-report');
		}
	}, [action, navigate]);

	React.useEffect(() => {
		onSearch();
		onSetHeader();
		onAction();
	}, [onAction, onSearch, onSetHeader]);

	return (
		<div className="w-full font-abel md:px-10 px-4">
			<ReportOrder />
		</div>
	);
};

export default ReportDashboard;
