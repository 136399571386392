import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// notification
import { notification } from 'antd';

// types
import { IMessage, IMessageState, IToastMessage } from '../types/message';

// Define the initial state using that type
const initialState: IMessageState = {
	message: '',
	type: '',
	title: ''
};

export const message = createSlice({
	name: 'message',
	initialState,
	reducers: {
		setMessage: (state, action: PayloadAction<IMessage>) => {
			state.message = action.payload.message;
			state.type = action.payload.type;
			state.title = action.payload?.title;
		},
		clearMessage: (state) => {
			state.message = '';
			state.type = '';
			state.title = '';
		},
		setToast: (_state, action: PayloadAction<IToastMessage>) => {
			notification.open({
				type: action.payload.type,
				message: action.payload.message,
				description: action.payload.description
				// icon: <SmileOutlined style={{ color: '#108ee9' }} />
			});
		}
	}
});

export const { setMessage, clearMessage, setToast } = message.actions;

export default message.reducer;
