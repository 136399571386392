import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '../../elements/forms/ButtonComponent';
import { BiSearchAlt } from 'react-icons/bi';
import SearchInput from '../../elements/forms/SearchInput';

type IMultiSelect = {
	open: boolean;
	search: string;
	children: React.ReactNode;
	onSearch: (search: string) => void;
	onSubmit: () => void;
	onCancel: () => void;
};

const MultiSelect: React.FC<IMultiSelect> = ({
	open,
	search,
	children,
	onSubmit,
	onSearch,
	onCancel
}) => {
	return (
		<Modal
			open={open}
			footer={[]}
			closable={false}
			closeIcon={false}
			width="90%"
			bodyStyle={{ height: '90vh' }}
			centered
		>
			<div className="w-full flex justify-between mb-2 h-[10%]">
				<div className="w-[40%]">
					<SearchInput
						name="search"
						value={search}
						placeholder="Search..."
						icon={<BiSearchAlt size={18} color="#243677" />}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							onSearch(e.target.value)
						}
					/>
				</div>
				<div className="w-[30%] flex justify-evenly">
					<div className="w-[49%]">
						<ButtonComponent
							title="Continue"
							onHandler={onCancel}
							backgroundColor="#243677"
							active={true}
						/>
					</div>
					<div className="w-[49%]">
						<ButtonComponent
							title="Cancel"
							onHandler={onCancel}
							backgroundColor="#DCE4FF"
							active={true}
						/>
					</div>
				</div>
			</div>
			<div className="w-full h-[90%] flex flex-col justify-between">
				{children}
			</div>
		</Modal>
	);
};

export default MultiSelect;
