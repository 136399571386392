import React from 'react';

// components
import TableLoader from '../../../components/loader/TableLoader';
import TableLine from '../misc/TableLine';
import DrawerOption from '../misc/DrawerOption';

// constants
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

// types
import { ICOMPLAIN } from '../../../store/types/complains';

// state
import { get_contact_complains } from '../../../service/api/complains_and_contact';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '@/store/slices/navbar';

const ComplainDashboard = () => {
	const dispatch = useAppDispatch();
	const complains = useAppSelector(({ complain }) => complain.complains);
	const current_page = useAppSelector(({ complain }) => complain.current_page);
	const last_page = useAppSelector(({ complain }) => complain.last_page);
	const loading = useAppSelector(({ loading }) => loading.loading);
	const [show, showSet] = React.useState<boolean>(false);
	const [complain, complainSet] = React.useState<ICOMPLAIN | null>(null);

	const onLoad = React.useCallback(() => {
		get_contact_complains(dispatch, 1);
	}, [dispatch]);

	const onTitle = React.useCallback(() => {
		dispatch(setTitle('Complains & Contact'));
		dispatch(setNavButton({ button_name: '', button: false }));
	}, [dispatch]);

	React.useEffect(() => {
		onLoad();
		onTitle();
	}, [onLoad, onTitle]);

	const onPressNext = () => {
		if (current_page && Number(current_page) < Number(last_page)) {
			get_contact_complains(dispatch, current_page + 1);
		}
	};

	const onPressPrev = () => {
		if (Number(current_page) && !(Number(current_page) <= 1)) {
			get_contact_complains(dispatch, Number(current_page) - 1);
		}
	};

	const onView = (res: ICOMPLAIN) => {
		showSet(true);
		complainSet(res);
	};

	const onClose = () => {
		showSet(false);
		complainSet(null);
	};

	return (
		<div className="font-[abel] font-[400]">
			<div className="flex justify-center items-center px-3 py-3 mb-2 text-[#567BFF] border-b">
				<div className="border-r px-1 w-1/12 text-center"></div>
				<div className="mx-1 border-r px-2 w-4/12 text-center">Name</div>
				<div className="mx-1 border-r px-2 w-4/12 text-center">Email</div>
				<div className="px-2 border-r w-2/12 text-center">Date</div>
				<div className="mx-1 px-2 w-1/12 text-center">Action</div>
			</div>

			<div className="w-full my-5">
				{loading ? (
					<TableLoader />
				) : (
					complains.map((item) => (
						<TableLine key={item?.id} item={item} onView={onView} />
					))
				)}
			</div>

			<div className="flex items-center justify-end my-5">
				<FaAngleDoubleLeft
					size={20}
					onClick={onPressPrev}
					className="cursor-pointer"
					color={
						Number(current_page) && Number(current_page) === 1
							? '#D3D3D3'
							: 'black'
					}
				/>

				<span className="text-[20px] mx-4">
					{current_page} | {last_page}
				</span>

				<FaAngleDoubleRight
					size={20}
					onClick={onPressNext}
					className="cursor-pointer"
					color={
						Number(current_page) && Number(current_page) === Number(last_page)
							? '#D3D3D3'
							: 'black'
					}
				/>
			</div>
			<DrawerOption show={show} detail={complain} onClose={onClose} />
		</div>
	);
};

export default ComplainDashboard;
