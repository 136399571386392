import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  roles: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  roles: [],
  update: false,
  loading: false
};

export const role = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<IDataType[]>) => {
      state.roles = action.payload;
    },
    createRole: (state, action: PayloadAction<IDataType>) => {
      state.roles = [...state.roles, action.payload];
    },
    updateRoles: (state, action: PayloadAction<IDataType>) => {
      console.log(action.payload);
      state.roles = state.roles.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const { setRoles, createRole, updateRoles, setLoading, setUpdate } =
  role.actions;

export default role.reducer;
