import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  qualifications: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  qualifications: [],
  update: false,
  loading: false
};

export const qualification = createSlice({
  name: 'qualification',
  initialState,
  reducers: {
    setQualifications: (state, action: PayloadAction<IDataType[]>) => {
      state.qualifications = action.payload;
    },
    createQualification: (state, action: PayloadAction<IDataType>) => {
      state.qualifications = [action.payload, ...state.qualifications];
    },
    updateQualification: (state, action: PayloadAction<IDataType>) => {
      state.qualifications = state.qualifications.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setQualifications,
  createQualification,
  updateQualification,
  setLoading,
  setUpdate
} = qualification.actions;

export default qualification.reducer;
