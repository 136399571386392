import React from 'react';

// components
import { Spin } from 'antd';

type IButtonComponent = {
	title: string;
	loading?: boolean;
	disabled?: boolean;
	onHandler: () => void;
	backgroundColor?: string;
	textColor?: string;
};

const ButtonComponent: React.FC<IButtonComponent> = ({
	title,
	loading,
	backgroundColor,
	textColor,
	onHandler
}) => {
	return (
		<button
			style={{
				backgroundColor: backgroundColor,
				color: textColor
			}}
			className={`w-full font-[500] font-abel py-3 rounded-md text-[15px]`}
			onClick={onHandler}
		>
			{loading && <Spin className="mr-4" />}
			{title}
		</button>
	);
};

export default ButtonComponent;
