import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  cultures: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  cultures: [],
  update: false,
  loading: false
};

export const culture = createSlice({
  name: 'culture',
  initialState,
  reducers: {
    setCultures: (state, action: PayloadAction<IDataType[]>) => {
      state.cultures = action.payload;
    },
    createCulture: (state, action: PayloadAction<IDataType>) => {
      state.cultures = [action.payload, ...state.cultures];
    },
    updateCulture: (state, action: PayloadAction<IDataType>) => {
      state.cultures = state.cultures.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setCultures,
  createCulture,
  updateCulture,
  setLoading,
  setUpdate
} = culture.actions;

export default culture.reducer;
