import React from 'react';

// react native
// import { useNavigate } from 'react-router-dom';

// constants and icons
// import { FaTrashAlt } from 'react-icons/fa';
import avatar_user from '../../../assets/images/temporary/user.png';

// types
import { IUserData } from '../../../store/types/candidate';
import { useAppSelector } from '@/store/hooks';
import { Skeleton } from 'antd';

type ITableLine = {
	key?: string;
	item: IUserData;
	onView: (item: string) => void;
};

const TableLine: React.FC<ITableLine> = ({ item, onView }) => {
	const loading = useAppSelector(({ candidate }) => candidate.loading);

	return (
		<div className="flex justify-center items-center px-3 py-2 mb-3 border-b border-[#BCBCBC] capitalize bg-white">
			<div className="w-[15%] text-center flex justify-center items-center">
				<img
					src={
						item?.profile && item?.profile?.avatar
							? item?.profile?.avatar
							: avatar_user
					}
					alt="user"
					className="w-[50px] h-[50px] rounded-full border border-[#4E4E4E]"
				/>
			</div>
			<div className="border-x w-[20%] text-center break-words">
				{item?.profile?.first_name} {item?.profile?.last_name}
			</div>
			<div className="border-x w-[20%] text-center lowercase break-words px-1">
				{item?.email}
			</div>
			<div className="border-x w-[30%] text-center break-words">
				{item?.profile?.address?.about?.profession
					? `${item?.profile?.address?.about?.profession?.name}
					   ${
								item?.profile?.address?.about?.years_of_experience
									? '[ ' +
									  item?.profile?.address?.about?.years_of_experience +
									  'year(s) ]'
									: null
							}
					  `
					: ''}
			</div>
			{/* <div className="mx-1 border-r px-2 w-2/12 text-center"></div> */}
			<div className="mx-1 px-2 w-[15%] text-center flex items-center justify-center">
				{loading ? (
					<Skeleton.Button active={true} block={true} />
				) : (
					<span
						onClick={() => onView(item.candidate_number)}
						className="underline text-[#243677] cursor-pointer mx-2"
					>
						View
					</span>
				)}
				{/* <FaTrashAlt size={17} color="#B10E0E" className="cursor-pointer" /> */}
			</div>
		</div>
	);
};

export default TableLine;
